import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';

// * IMPORTS HELPERS
import { Global } from '../../../helpers/Global';
import { useAlert } from '../../../hooks/useAlert';

// * IMPORT MATERIAL UI - COMPONENTS
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

// * IMPORTS MUI FILE INPUT
import { MuiFileInput } from 'mui-file-input';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';

// * IMPORTS MATERIAL UI - ICONS
import CachedIcon from '@mui/icons-material/Cached';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';


// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';


// * MATERIAL UI - TABLES
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


// * MATERIAL UI - IMAGE LIST
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';


// * IMPORTS MATERIAl UI - DIALOG
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


// * IMPORTS REACT FILEPOND
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

// * CUSTOM BUTTON
const CustomButton = styled(Button)(() => ({
    color: 'white',
    backgroundColor: '#3340E3',
    '&:hover': {
        backgroundColor: '#4d56cd',
    },
}));

export const Expedient = (props) => {

    const { dataClient } = props;

    // * HOOKS
    // User Auth
    const { auth } = useAuth();
    // MUI file input
    const [fileOne, setFileOne] = useState(null);
    const [document, setDocument] = useState({
        documentOne: {
            base64: null,
            file: null
        }
    });
    // Input Select
    const [selectDocument, setSelectDocument] = useState('');
    // Modal - Dialog
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogFile, setOpenDialogFile] = useState(false);
    // Alerts Message
    const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();
    // Conseguir los documentos del expediente del cliente
    const [getDocuments, setGetDocuments] = useState([]);
    const [galleryFiles, setGalleryFiles] = useState([]);
    const [nameDocument, setNameDocument] = useState('');
    const [namePathDocument, setNamePathDocument] = useState('');
    const [nameTypeDocument, setNameTypeDocument] = useState('');
    // Filepond
    const [filesHomeVisit, setFilesHomeVisit] = useState([]);
    const [filesMechanicalRevision, setFilesMechanicalRevision] = useState([]);
    const [filesContractSigning, setFilesContractSigning] = useState([]);
    const [img, setImg] = useState();
    // Buttons
    const [disabledButton, setDisabledButton] = useState(false);


    useEffect(() => {
        const getDocumentsExpedient = async () => {

            // Preparar peticion a API
            const token = localStorage.getItem("token");

            const dataDocument = new FormData();
            dataDocument.append('idClient', dataClient.id);

            let requestOptions = {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer  ${token.replace(/['"]+/g, '')}`
                },
                body: dataDocument,
                redirect: 'follow'
            };

            let request = await fetch(`${Global.url_api}api/auth/get-expedient-client`, requestOptions);
            let response = await request.json();
            return response;
        }

        getDocumentsExpedient().then(result => {
            console.log("@EXPEDIENT", result);
            setGetDocuments(result.documents);
            setGalleryFiles(result.filesImg);
        });

    }, [dataClient]);

    // * FUNCTIONS
    const uploadDocuments = async (event) => {
        event.preventDefault();

        console.log(selectDocument);
        console.log(document.documentOne.file);

        // Validaciones de inputs
        if (selectDocument === '') {
            showStatusMessage('No ha seleccionado un tipo de documento', 'warning');
            return false;
        }

        if (document.documentOne.file === null) {
            showStatusMessage('No ha agregado un documento', 'warning');
            return false;
        }

        // Desactivar boton de "Actualizar"
        setDisabledButton(true);

        // Conseguir el token de usuario
        const token = localStorage.getItem("token");

        let data = new FormData();
        data.append('idUser', dataClient.id);
        data.append('editor', `${auth.name} ${auth.middle_name} ${auth.last_name}`);
        data.append('idCategory', selectDocument);
        data.append('file0', document.documentOne.file, document.documentOne.file.name.replace(/\s+/g, ''));
        data.append('type', document.documentOne.file.type);

        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer  ${token}`
            },
            body: data,
            redirect: 'follow'
        };

        try {
            let request = await fetch(`${Global.url_api}api/auth/upload-documents-expedient`, requestOptions);
            let response = await request.json();

            console.log(response);

            if (response.code === '201') {
                showStatusMessage('¡Documento guardado correctamente!', 'success');

                // Preparar peticion a API para recargar los documentos del expediente
                const token = localStorage.getItem("token");

                let dataDocument = new FormData();
                dataDocument.append('idClient', dataClient.id);

                let request = await fetch(`${Global.url_api}api/auth/get-expedient-client`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer  ${token.replace(/['"]+/g, '')}`
                    },
                    body: dataDocument,
                    redirect: 'follow'
                });

                let response = await request.json();
                setGetDocuments(response.documents);
            }
            else {
                showStatusMessage(response.message.file0[0], 'error');
                return false;
            }

            // Resetear campo de documento
            setFileOne(null);
            setDocument({
                documentOne: {
                    base64: null,
                    file: null
                }
            });

        } catch (error) {
            console.log(error);
            showStatusMessage(`Error Server ${error}`, 'error');
        }
        // Activar boton de "Actualizar"
        setDisabledButton(false);
    }

    const loadFiles = (file, type) => {

        if (type === 'documentOne') {
            // Asignar valores al input del documentOne
            setFileOne(file);

            // Guardar objeto "file" en estado document
            setDocument({
                ...document,
                documentOne: {
                    ...document.documentOne,
                    file: file
                }
            });


        }
    }

    const selectNameDocument = (id_category) => {
        switch (id_category) {
            case 1:
                return 'Carta de Bienvenida';
            case 2:
                return 'Aviso de Privacidad';
            case 3:
                return 'Calendario de Pagos';
            case 4:
                return 'Caratula';
            case 5:
                return 'Pagare';
            case 6:
                return 'Cotizacion';
            case 7:
                return 'Carta de Entrega';
            case 8:
                return 'Contrato Mutuo';
            default:
                return 'ERROR';
        }
    }

    const viewDocument = (event, id_category, name, type) => {
        setNameDocument(selectNameDocument(id_category));
        setNamePathDocument(name);
        setNameTypeDocument(type);
        clickOpenDialog();
    }

    const uploadFiles = async (event, categoryFileUpload) => {

        let data = new FormData();

        if (categoryFileUpload === 'homevisit' && filesHomeVisit.length !== 0) {
            filesHomeVisit.map((file, index) => data.append(`file${index}`, file.file, file.file.name.replace(/\s+/g, '')));
        } else if (categoryFileUpload === 'mechanicalrevision' && filesMechanicalRevision.length !== 0) {
            filesMechanicalRevision.map((file, index) => data.append(`file${index}`, file.file, file.file.name.replace(/\s+/g, '')));
        } else if (categoryFileUpload === 'contractsigning' && filesContractSigning.length !== 0) {
            filesContractSigning.map((file, index) => data.append(`file${index}`, file.file, file.file.name.replace(/\s+/g, '')));
        } else {
            showStatusMessage('Aún no ha cargado ningún archivo.', 'warning');
            return false;
        }

        // Desactivar boton de "Subir Archivos"
        setDisabledButton(true);

        data.append('idUser', dataClient.id);
        data.append('categoryFileUpload', categoryFileUpload);

        try {
            // Conseguir el token de usuario
            const token = localStorage.getItem("token");

            let request = await fetch(`${Global.url_api}api/auth/upload-files-expedient`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer  ${token}`
                },
                body: data,
                redirect: 'follow'
            });

            let response = await request.json();

            if (response.code === '201') {

                // Vetificar si un archivo se subio mal
                if (response.data_error.length >= 1) {
                    showStatusMessage(`Se produjo un error al subir ${response.data_error.length} archivos`, 'error');
                }

                showStatusMessage(`Éxito  al subir archivos`, 'success');

                // Preparar peticion a API para recargar los documentos del expediente
                const token = localStorage.getItem("token");

                let dataDocument = new FormData();
                dataDocument.append('idClient', dataClient.id);

                let request = await fetch(`${Global.url_api}api/auth/get-expedient-client`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer  ${token.replace(/['"]+/g, '')}`
                    },
                    body: dataDocument,
                    redirect: 'follow'
                });

                let { filesImg } = await request.json();
                setGalleryFiles(filesImg);
            }
            else {
                showStatusMessage(response.message.file0[0], 'error');
                return false;
            }

            setFilesHomeVisit([]);
            setFilesMechanicalRevision([]);
            setFilesContractSigning([]);
        } catch (error) {
            console.log(error);
            showStatusMessage(`Error Server ${error}`, 'error');
        }
        // Activar boton de "Subir Archivos"
        setDisabledButton(false);
    }

    const showFile = (event, path, categoryFile) => {

        if (categoryFile === 'homevisit') {
            path = `home-visit/${path}`;
        } else if (categoryFile === 'mechanicalrevision') {
            path = `mechanical-revision/${path}`;
        } else if (categoryFile === 'contractsigning') {
            path = `contract-signing/${path}`;
        }
        setImg(path);
        setOpenDialogFile(true);
    }

    // * DIALOG
    const clickOpenDialog = () => {
        setOpenDialog(true);
    };

    const closeDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>


            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Typography variant="h5" sx={{ mt: 5, mb: 5 }} gutterBottom>
                        Expediente Digital
                    </Typography>
                </Grid>

                <Grid maxWidth="xl" container component="form" onSubmit={uploadDocuments} spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{ textAlign: 'center', mb: 5 }}>
                    <Grid item xs={12} md={5}>
                        <FormControl fullWidth>
                            <InputLabel id="select_document">Documento</InputLabel>
                            <Select
                                labelId="select_document"
                                id="demo-simple-select"
                                value={selectDocument}
                                label="Age"
                                onChange={event => (setSelectDocument(event.target.value))}
                            >
                                <MenuItem value={1}>Carta de Bienvenida</MenuItem>
                                <MenuItem value={2}>Aviso de Privacidad</MenuItem>
                                <MenuItem value={3}>Calendario de Pagos</MenuItem>
                                <MenuItem value={4}>Caratula</MenuItem>
                                <MenuItem value={5}>Pagare</MenuItem>
                                <MenuItem value={6}>Cotizacion</MenuItem>
                                <MenuItem value={7}>Carta de Entrega</MenuItem>
                                <MenuItem value={8}>Contrato Mutuo</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <MuiFileInput value={fileOne} onChange={event => loadFiles(event, 'documentOne')} placeholder='Cargar Documento' />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <CustomButton type="submit" disabled={disabledButton} variant="contained" endIcon={<CachedIcon />} sx={{ mt: 3, mb: 2 }}>Actualizar</CustomButton>
                    </Grid>
                </Grid>


                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Documento</TableCell>
                                <TableCell align="center">Fecha de Alta</TableCell>
                                <TableCell align="center">Ultima Actualización</TableCell>
                                <TableCell align="center">Ultima Actualización Por</TableCell>
                                <TableCell align="center">Ver</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getDocuments.map((row) => (
                                <TableRow
                                    key={row.created_at}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {selectNameDocument(row.id_category)}
                                    </TableCell>
                                    <TableCell align="center">{row.created_at}</TableCell>
                                    <TableCell align="center">{row.update_at}</TableCell>
                                    <TableCell align="center">{row.editor}</TableCell>
                                    <TableCell align="center" onClick={event => viewDocument(event, row.id_category, row.name, row.type)}><RemoveRedEyeOutlinedIcon sx={{ color: '#00c3b3' }} className='cursor-pointer' /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* INICIO VISITA DOMICILARIA */}
                <Grid container spacing={2}>
                    <Typography variant="h5" mt={8} mb={5} gutterBottom>
                        Visita Domiciliaria
                    </Typography>
                </Grid>

                <FilePond
                    id="homevisit"
                    files={filesHomeVisit}
                    allowReorder={true}
                    allowMultiple={true}
                    onupdatefiles={setFilesHomeVisit}
                    labelIdle='Arrastra y suelta, o <span class="filepond--label-action">abrir archivos</span>'
                />

                <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                    <CustomButton onClick={event => uploadFiles(event, 'homevisit')} disabled={disabledButton} variant="contained" endIcon={<UploadFileIcon />} sx={{ mt: 3, mb: 5 }}>Subir archivos</CustomButton>
                </Stack>

                <Box sx={{}}>
                    <ImageList variant="masonry" cols={6} gap={8}>
                        {galleryFiles.map((item) => (
                            item.category === 'homevisit' &&
                            <ImageListItem key={item.id}>
                                <img
                                    className='cursor-pointer'
                                    onClick={event => (showFile(event, item.name, item.category))}
                                    src={`${Global.url_api_root}storage/app/documents/backoffice/home-visit/${item.name}?w=248&fit=crop&auto=format`}
                                    srcSet={`${Global.url_api_root}storage/app/documents/backoffice/home-visit/${item.name}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    subtitle={item.created_at}
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${item.title}`}
                                            onClick={event => (showStatusMessage('Eliminando foto...', 'info'))}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }

                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
                {/* FIN VISITA DOMICILARIA */}

                {/* INICIO REVISION MECANICA */}
                <Grid container spacing={2}>
                    <Typography variant="h5" mt={8} mb={5} gutterBottom>
                        Revisión Mecánica
                    </Typography>
                </Grid>

                <FilePond
                    id="mechanicalrevision"
                    files={filesMechanicalRevision}
                    allowReorder={true}
                    allowMultiple={true}
                    onupdatefiles={setFilesMechanicalRevision}
                    labelIdle='Arrastra y suelta, o <span class="filepond--label-action">abrir archivo</span>'
                />

                <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                    <CustomButton onClick={event => uploadFiles(event, 'mechanicalrevision')} disabled={disabledButton} variant="contained" endIcon={<UploadFileIcon />} sx={{ mt: 3, mb: 5 }}>Subir archivos</CustomButton>
                </Stack>

                <Box sx={{}}>
                    <ImageList variant="masonry" cols={6} gap={8}>
                        {
                            galleryFiles.map((item) => (
                                item.category === 'mechanicalrevision' &&
                                <ImageListItem key={item.id}>
                                    <img
                                        className='cursor-pointer'
                                        onClick={event => (showFile(event, item.name, item.category))}
                                        src={`${Global.url_api_root}storage/app/documents/backoffice/mechanical-revision/${item.name}?w=248&fit=crop&auto=format`}
                                        srcSet={`${Global.url_api_root}storage/app/documents/backoffice/mechanical-revision/${item.name}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        alt={item.title}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        subtitle={item.created_at}
                                        actionIcon={
                                            <IconButton
                                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                aria-label={`info about ${item.title}`}
                                                onClick={event => (showStatusMessage('Eliminando foto...', 'info'))}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }

                                    />
                                </ImageListItem>
                            ))
                        }
                    </ImageList>
                </Box>
                {/* FIN REVISION MECANICA */}

                {/* INICIO FIRMA DE CONTRATOS */}
                <Grid container spacing={2}>
                    <Typography variant="h5" mt={8} mb={5} gutterBottom>
                        Firma de Contratos
                    </Typography>
                </Grid>

                <FilePond
                    id="contractsigning"
                    files={filesContractSigning}
                    allowReorder={true}
                    allowMultiple={true}
                    onupdatefiles={setFilesContractSigning}
                    labelIdle='Arrastra y suelta, o <span class="filepond--label-action">abrir archivos.</span>'
                />

                <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                    <CustomButton onClick={event => uploadFiles(event, 'contractsigning')} disabled={disabledButton} variant="contained" endIcon={<UploadFileIcon />} sx={{ mt: 3, mb: 5 }}>Subir archivos</CustomButton>
                </Stack>

                <Box sx={{}}>
                    <ImageList variant="masonry" cols={6} gap={8}>
                        {galleryFiles.map((item) => (
                            item.category === 'contractsigning' &&
                            <ImageListItem key={item.id}>
                                <img
                                    className='cursor-pointer'
                                    onClick={event => (showFile(event, item.name, item.category))}
                                    src={`${Global.url_api_root}storage/app/documents/backoffice/contract-signing/${item.name}?w=248&fit=crop&auto=format`}
                                    srcSet={`${Global.url_api_root}storage/app/documents/backoffice/contract-signing/${item.name}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    subtitle={item.created_at}
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${item.title}`}
                                            onClick={event => (showStatusMessage('Eliminando foto...', 'info'))}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }

                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
                {/* FIN FIRMA DE CONTRATOS */}

                {/* Ver documentos de expediente */}
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={openDialog}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {nameDocument}
                    </DialogTitle>
                    <DialogContent className='expedient-view-pdf'>

                        {
                            nameTypeDocument === 'application/pdf' ?
                                <embed src={`${Global.url_api_root}storage/app/documents/backoffice/expedient/${namePathDocument}`} type="application/pdf" className='viewer-pdf' />
                                :
                                <>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        component="img"
                                        sx={{
                                            maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
                                        }}
                                        alt={namePathDocument}
                                        src={`${Global.url_api_root}storage/app/documents/backoffice/expedient/${namePathDocument}`}
                                    />
                                </>
                        }
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={closeDialog}>Cerrar</Button>
                    </DialogActions>
                </Dialog>

                {/* Ver archivos */}
                <Dialog
                    fullWidth={true}
                    maxWidth={'lg'}
                    open={openDialogFile}
                    onClose={() => { setOpenDialogFile(false) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    </DialogTitle>
                    <DialogContent>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            component="img"
                            sx={{
                                maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
                            }}
                            alt={namePathDocument}
                            src={`${Global.url_api_root}storage/app/documents/backoffice/${img}`}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => { setOpenDialogFile(false) }}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </div>
    )
}
