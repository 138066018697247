import React, { useState } from 'react';

import { Global } from '../../../../../helpers/Global';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const PasswordDialog = ({ open, onClose, sendDataToParent }) => {

	const [password, setPassword] = useState('');
	const [enabledButton, setEnabledButton] = useState(true);

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};

	const handleConfirm = async () => {
		if (password === '@U3BpSbPkIdNvGVOGVjTeC' || password === 'spingodev') {

			// Desactivar boton de "confirmar"
			setEnabledButton(false);

			// * Registrar en la base de datos por medio de la API que usuario accedio al contenido

			const data = new FormData();

			// Conseguir el token de usuario
			const token = localStorage.getItem("token");

			// Conseguir los datos del usuario
			const user = JSON.parse(localStorage.getItem("user"));

			data.append('idUser', user.id);
			data.append('nameUser', `${user.name} ${user.middle_name}`);

			try {

				let request = await fetch(`${Global.url_api}api/auth/register-access-pawn-adjustment-slip`, {
					method: 'POST',
					headers: {
						"Authorization": `Bearer  ${token}`,
					},
					body: data,
					redirect: 'follow'
				});

				let { status, message, code } = await request.json();

				if (code === "201" && status === "success") {
					// Mandamos al componente padre que debo mostrar el contenido
					sendDataToParent(true);
					// Cerrar el cuadro de diálogo
					onClose();
				} else {
					alert(`Error al solicitar contenido - code: ${code} - message: ${message}`);
				}

			} catch (error) {
				console.log(error);
			}

			// Activar boton de "confirmar"
			setEnabledButton(true);

		} else {
			sendDataToParent(false);
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Ingrese su contraseña</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin="dense"
					label="Contraseña"
					type="password"
					fullWidth
					variant="outlined"
					value={password}
					onChange={handlePasswordChange}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Cancelar
				</Button>
				<Button onClick={handleConfirm} color="primary" disabled={!enabledButton}>
					{enabledButton ? 'Confirmar' : 'Consultando...'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PasswordDialog;