import React from 'react';

import { Information } from './Information';
import { Expedient } from './Expedient';
import { Documents } from './Documents';
import { NewUser } from './NewUser';
import { UpdateDocuments } from './UpdateDocuments';
import { Pawn } from './processes/Pawn';
import { PawnAdjustmentSlip } from './pawn-adjustment-slip/PawnAdjustmentSlip';

export const SelectDialog = (props) => {

    const { loadComponent, users, dataClient, dataClientIdentification, dataClientProofAddress } = props;

    // console.log(loadComponent);

    if (loadComponent === 'information') {
        return (
            <Information dataClient={dataClient} dataClientIdentification={dataClientIdentification} dataClientProofAddress={dataClientProofAddress} />
        )
    }
    else if (loadComponent === 'documents') {
        return (
            <Documents dataClient={dataClient} dataClientIdentification={dataClientIdentification} />
        )
    }
    else if (loadComponent === 'expedient') {
        return (
            <Expedient dataClient={dataClient} dataClientIdentification={dataClientIdentification} />
        )
    }
    else if (loadComponent === 'add-new-user') {
        return (
            <NewUser />
        )
    }
    else if (loadComponent === 'new-pawn-adjustment-slip') {
        return (
            <PawnAdjustmentSlip />
        )
    }
    else if (loadComponent === 'update-documents-user') {
        return (
            <UpdateDocuments users={users} />
        )
    }
    else if (loadComponent === 'progress-endeavor') {
        return (
            <Pawn dataClient={dataClient} />
        )
    }
    else if (loadComponent === 'pawn-adjustment-slip') {
        return (
            <PawnAdjustmentSlip dataClient={dataClient} />
        )
    }
}
