import /*React,*/ { createContext, useEffect, useState } from 'react';

// * IMPORTS HELPERS
import { Global } from '../helpers/Global';

const AuthContext = createContext();

export const AuthProvider = ({children}) => {

    const [auth, setAuth] = useState({});
    const [onboarding, setOnboarding] = useState({});
    const [loading, setLoading] = useState(true);

    // Cada que se utilice este componente se comprueba el token
    useEffect(() => {
        authUser();
    }, []);

    const authUser = async () => {

        // Sacar datos usaurio identificado del localstorage
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");

        // Comprobar si tengo el token y el user
        if (!token || !user) {
            setLoading(false);
            return false;
        }
        
        // Transformar los datos a un objeto de javascript
        const userObj = JSON.parse(user);
        const idUser = userObj.id;

        // Asignar id del usaurio a un FormData para consultar el onboarding
        const dataUser = new FormData();
        dataUser.append('idUser', idUser);

        // Peticion ajax al backend que compruebe el token y que me devuelva todos los datos del usuario
        try {
            let requestOptions = {
                method: 'POST',
                headers: {
                    "Content-Type" : "application/json",
                    "Authorization" : `Bearer  ${token.replace(/['"]+/g, '')}`
                },
                body: {},
                redirect: 'follow'
            };

            const request = await fetch(`${Global.url_api}api/auth/me`, requestOptions);
            const data = await request.json();

            // Peticion para obtener el onboarding del usuario
            let requestOptionsOnboarding = {
                method: 'POST',
                headers: {
                    "Authorization" : `Bearer  ${token.replace(/['"]+/g, '')}`
                },
                body: dataUser,
                redirect: 'follow'
            };

            const requestOnboarding = await fetch(`${Global.url_api}api/onboarding/get-onboarding`, requestOptionsOnboarding);
            const dataOnboarding = await requestOnboarding.json();

            // Setear el estado de auth
            setAuth(data);
            // Setear el estado de Onboarding
            setOnboarding(dataOnboarding);
            // Setear el estao de Loading
            setLoading(false);


        } catch (error) {

            const data = {
                message : "error token",
                code : "501"
            }
            // Setear el estado auth
            setAuth(data);

        }

    }

    return (
        <AuthContext.Provider
            value={{
                auth,
                setAuth,
                setOnboarding,
                onboarding,
                loading
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;