import React from 'react';
import { Routes, Route, BrowserRouter, Link, /*Navigate*/ } from 'react-router-dom';

import { PublicLayout } from '../components/layout/public/PublicLayout';
import { PrivateLayout } from '../components/layout/private/PrivateLayout';

import { Login } from '../components/user/Login';
import { Register } from '../components/user/Register';
import { Logout } from '../components/user/Logout';

import { Onboarding } from '../components/onboarding/Onboarding';
import { OnboardingOp2 } from '../components/onboarding/OnboardingOp2';

import { SelectDocument } from '../components/onboarding/SelectDocument';

import { Dashboard } from '../components/backoffice/Dashboard';
import { DashboardLeads } from '../components/hubspot/DashboardLeads';
import { DashboardLeadsSimulator } from '../components/hubspot/DashboardLeadsSimulator';

import { ValidateCfdi } from '../components/sat/ValidateCfdi';

import { AuthProvider } from '../context/AuthProvider';

import Test from '../components/Test';



export const Routing = () => {
    return (
        <BrowserRouter>

            {/* Componente que sera heredado a los componentes hijos apra tener acceso a la informacion del usuario */}
            <AuthProvider>

                <Routes>

                    {/* Primer grupo de rutas */}
                    <Route path="/" element={<PublicLayout />} >
                        <Route index element={<Login />} />
                        <Route path="login" element={<Login />} />
                        <Route path="register" element={<Register />} />
                        {/* Si el cliente viene de HubSpot capturamos los datos que se envian*/}
                        <Route path="register/:name_hubspot/:email_hubspot/:telephone_hubspot" element={<Register />} />
                    </Route>

                    {/* Segundo grupo de rutas */}
                    <Route path="/onboarding" element={<PrivateLayout />} >
                        <Route index element={<Onboarding />} />
                        <Route path="onboarding1" element={<Onboarding />} />
                        <Route path="onboarding2" element={<OnboardingOp2 />} />

                        {/* Documentos de onboarding */}
                        <Route path='documents/:document' element={<SelectDocument />} />

                        <Route path="logout" element={<Logout />} />
                    </Route>

                    {/* Tercer grupo de rutas - BackOffice */}
                    <Route path="/backoffice" element={<PrivateLayout />} >
                        <Route index element={<Dashboard />} />
                        <Route path="dashboard" element={<Dashboard />} />
                    </Route>

                    {/* Cuarto grupo de rutas - Hubspot */}
                    <Route path="/hubspot" element={<PrivateLayout />} >
                        <Route index element={<DashboardLeads />} />
                        <Route path="list-leads" element={<DashboardLeads />} />
                        <Route path="simulator" element={<DashboardLeadsSimulator />} />
                    </Route>

                    {/* Quinto grupo de rutas - SAT */}
                    <Route path="/sat" element={<PrivateLayout />} >
                        <Route index element={<ValidateCfdi />} />
                        <Route path="validate-cfdi" element={<ValidateCfdi />} />
                    </Route>

                    {/* Sexto grupo de rutas */}
                    <Route path="/test" element={<PrivateLayout />} >
                        <Route index element={<Test />} />
                        <Route path="test1" element={<Test />} />
                    </Route>

                    {/* Ruta 404 */}
                    <Route path="*" element={
                        <>
                            <h1>ERROR 404</h1>
                            <Link to="/">Volver al inicio</Link>
                        </>
                    } />

                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}
