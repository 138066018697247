import React, { useEffect, useState } from 'react';

import useAuth from '../../hooks/useAuth';

import { Styles } from '../../helpers/Styles';

// * REACT ROUTER DOM
import { useNavigate } from "react-router-dom";

// * IMPORT DATATABLES
import MUIDataTable from "mui-datatables";

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';
import { useAlert } from '../../hooks/useAlert';
import { SelectDialog } from './submodules/SelectDialog';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

// * IMPORT MATERIAL UI - BACKDROP
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';

// * IMPORT MATERIAL UI - ICONS
import IconButton from '@mui/material/IconButton';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloudCircleOutlinedIcon from '@mui/icons-material/CloudCircleOutlined';

import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

// * IMPORT MATERIAL UI - DIALOG
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';


// * IMPORT MATERIAL UI - SPEED DIAL
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});


export const Dashboard = () => {
	// * HOOKS
	// Conseguir los datos de usuario y onboarding
	const { auth } = useAuth();
	const navigate = useNavigate();

	// Conseguir todos los clientes del backoffice
	const [dataClients, setDataClients] = useState([]);
	// Conseguir los datos de un cliente
	const [dataClient, setDataClient] = useState({});
	// Conseguir los datos de un cliente
	const [dataClientIdentification, setDataClientIdentification] = useState({});
	// Comprobante de domicilio
	const [dataClientProofAddress, setDataClientProofAddress] = useState({});
	// Select component to dialog
	const [selectComponent, setSelectComponent] = useState('');
	// Controlar dialog
	const [openDialogClient, setOpenDialogClient] = useState(false);
	// Backdrop
	const [openBackdrop, setOpenBackdrop] = useState(false);
	// Alerts Message
	const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

	// Conseguir a todos los clientes con sus documentos
	useEffect(() => {
		// Verificamos que solo los usuarios con role "admin" puedan acceder
		if (auth.role !== 'admin') {
			navigate("/onboarding");
		}
		getClients().then(result => {
			setDataClients(result);
		});
	}, [auth, navigate]);

	const getClients = async () => {
		// Preparar peticion a API

		// Conseguir el token de usuario
		const token = localStorage.getItem("token");

		let requestOptions = {
			method: 'GET',
			headers: {
				"Authorization": `Bearer  ${token}`
			},
			redirect: 'follow'
		};

		let request = await fetch(`${Global.url_api}api/auth/clients`, requestOptions);
		let response = await request.json();
		return response;
	}

	// Conseguir la informacion de un cliente y su data del INE/IFE
	useEffect(() => {
		const setDataClient = async () => {
			if (dataClient.id) {
				// Preparar peticion a API para pbtener los datos de la identificacion (IFE/INE) que se pasara como argumento a los componentes que hereden la propiedad

				const token = localStorage.getItem("token");

				let raw = JSON.stringify({
					"idUser": dataClient.id
				});

				let requestOptions = {
					method: 'POST',
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer  ${token}`
					},
					body: raw,
					redirect: 'follow'
				};

				let request = await fetch(`${Global.url_api}api/auth/data-identification`, requestOptions);
				let response = await request.json();

				// Asignar datos de la identificacion al estado
				setDataClientIdentification(response.data_identification);

				// Asignar datos del comprobante de domicilio al estado
				setDataClientProofAddress(response.data_proof_address);

				// Mostrar dialog para renderizar componente
				setOpenDialogClient(true);

				// Desactivamos loader
				setOpenBackdrop(false);
			}
		}
		setDataClient();
	}, [dataClient]);

	const getDataClient = async (event, value, tableMeta, updateValue, componentRender) => {

		event.stopPropagation();

		// Activamos loader
		setOpenBackdrop(true);

		// Seleccionar que componente se va a renderizar en el dialog
		setSelectComponent(componentRender);

		// Obtener el id del cliente
		let idUser = tableMeta.rowData[0];

		// Solicitar al API la informacion del usuario por medio de su ID
		try {
			const token = localStorage.getItem("token");

			let raw = JSON.stringify({
				"idUser": idUser
			});

			let requestOptions = {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer  ${token}`
				},
				body: raw,
				redirect: 'follow'
			};
			let request = await fetch(`${Global.url_api}api/auth/user-information`, requestOptions);
			let response = await request.json();

			console.log('@INFORMACION_USUARIO', response.data);

			// Seteamos la informacion de user al estado
			// Como se modifico el estado de dataClient se ejecuta el hook useEffect
			setDataClient(response.data);

		} catch (error) {
			console.log(error);
			showStatusMessage(`Error Server ${error}`, 'error');
		}

	}

	const actionsBackoffice = (event, component) => {

		// Seleccionar que componente se va a renderizar en el dialog
		setSelectComponent(component);

		// Mostrar dialog para renderizar componente
		setOpenDialogClient(true);
	}

	const selectValueIcon = (value) => {
		if (value === 'Validado') {
			return <div style={{
				textAlign: 'center'
			}}>
				<Chip icon={<CheckCircleOutlineOutlinedIcon />} label={value} color="success" size="small" variant="outlined" />
			</div>;
		}
		else if (value === 'Pendiente de validar') {
			return <div style={{
				textAlign: 'center'
			}}>
				<Chip icon={<AccessTimeOutlinedIcon />} label={value} color="info" size="small" variant="outlined" />
			</div>;
		}
		else if (value === 'Rechazado') {
			return <div style={{
				textAlign: 'center'
			}}>
				<Chip icon={<ErrorOutlineOutlinedIcon />} label={value} color="error" size="small" variant="outlined" />
			</div>;
		}
		else if (value === 'Pendiente de subir') {
			return <div style={{
				textAlign: 'center'
			}}>
				<Chip icon={<CloudCircleOutlinedIcon />} label={value} sx={{ color: '#9E9E9E' }} size="small" variant="outlined" />
			</div>;
		}
	}

	const columns = [
		{
			name: "id",
			label: "ID",
		},
		{
			name: "name",
			label: "Nombre"
		},
		{
			name: "middle_name",
			label: "Apellido Paterno"
		},
		{
			name: "last_name",
			label: "Apellido Materno"
		},
		{
			name: "celular",
			label: "Celular"
		},
		{
			name: "validacion_ine",
			label: "INE",
			options: {
				customBodyRender: (value) => {
					return selectValueIcon(value);
				}
			}
		},
		{
			name: "validacion_comprobante",
			label: "Comprobante de Domicilio",
			options: {
				customBodyRender: (value) => {
					return selectValueIcon(value);
				},
			}
		},
		{
			name: "validacion_tarjeta_circulacion",
			label: "Tarjeta de Circulacion",
			options: {
				customBodyRender: (value) => {
					return selectValueIcon(value);
				},
			}
		},
		{
			name: "validacion_factura",
			label: "Factura",
			options: {
				customBodyRender: (value) => {
					return selectValueIcon(value);
				},
			}
		},
		{
			name: "Acciones",
			options: {
				filter: true,
				sort: false,
				empty: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<>
							<Grid container spacing={2}>
								<Grid item xs={2}>
									<Tooltip title="Información">
										<IconButton sx={{ color: Styles.color_two }} aria-label="information" size="small" onClick={event => getDataClient(event, value, tableMeta, updateValue, 'information')}>
											<PermContactCalendarOutlinedIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item xs={2}>
									<Tooltip title="Documentos">
										<IconButton sx={{ color: Styles.color_two }} aria-label="documents" size="small" onClick={event => getDataClient(event, value, tableMeta, updateValue, 'documents')}>
											<ContentCopyOutlinedIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item xs={2}>
									<Tooltip title="Boleta de Ajuste de Empeño">
										<IconButton sx={{ color: Styles.color_two }} aria-label="pawn-adjustment-slip" size="small" onClick={event => getDataClient(event, value, tableMeta, updateValue, 'pawn-adjustment-slip')}>
											<MonetizationOnOutlinedIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item xs={2}>
									<Tooltip title="Proceso de Empeño">
										<IconButton sx={{ color: Styles.color_two }} aria-label="progress-endeavor" size="small" onClick={event => getDataClient(event, value, tableMeta, updateValue, 'progress-endeavor')}>
											<PublishedWithChangesOutlinedIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item xs={2}>
									<Tooltip title="Expediente">
										<IconButton sx={{ color: Styles.color_two }} aria-label="expedient" size="small" onClick={event => getDataClient(event, value, tableMeta, updateValue, 'expedient')}>
											<CreateNewFolderOutlinedIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</>
					);
				}
			}
		}
	];

	const options = {
		selectableRowsHideCheckboxes: true,
		textLabels: {
			body: {
				noMatch: "Lo sentimos, no se encontraron registros coincidentes.",
				toolTip: "Sort",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			pagination: {
				next: "Siguiente pagina",
				previous: "Pagina anterior",
				rowsPerPage: "Filas por pagina:",
				displayRows: "de",
			},
			toolbar: {
				search: "Buscar",
				downloadCsv: "Descargar CSV",
				print: "Imprimir",
				viewColumns: "Ver columnas",
				filterTable: "Tabla de filtros",
			},
		}
	};

	return (
		<div>
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={openBackdrop}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Container component="main" maxWidth="xxl">
				<Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={2}>
					<Box mt={0}>
						<SpeedDial
							ariaLabel="Actions from table control"
							FabProps={{
								sx: {
									width: 40,
									height: 40,
									bgcolor: '#3ad69b',
									// bgcolor: '#3340E3',
									'&:hover': {
										bgcolor: '#37b988',
									},
								}
							}}
							icon={<SpeedDialIcon />}
							direction="left"
						>
							<SpeedDialAction
								FabProps={{
									sx: {
										width: 35,
										height: 35
									}
								}}
								key="new-pawn-adjustment-slip"
								icon={<AttachMoneyIcon />}
								tooltipTitle="Boleta de Ajuste de Empeño"
								onClick={event => actionsBackoffice(event, 'new-pawn-adjustment-slip')}
							/>

							<SpeedDialAction
								FabProps={{
									sx: {
										width: 35,
										height: 35
									}
								}}
								key="add-new-user"
								icon={<PersonAddAltOutlinedIcon />}
								tooltipTitle="Nuevo prospecto"
								onClick={event => actionsBackoffice(event, 'add-new-user')}
							/>
						</SpeedDial>
					</Box>
				</Stack>

				<Box sx={{ width: '100%' }} mt={0}>
					<Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={12} sm={12} md={12} >
							<MUIDataTable
								title={"Prospectos"}
								data={dataClients}
								columns={columns}
								options={options}
							/>
						</Grid>
						<Dialog
							fullScreen
							open={openDialogClient}
							onClose={event => { setOpenDialogClient(false) }}
							TransitionComponent={Transition}
						>
							<AppBar sx={{ position: 'relative', bgcolor: '#3340e3' }}>
								<Toolbar>
									<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
										Backoffice  SpinGo: {dataClient.name} {dataClient.middle_name} {dataClient.last_name}
									</Typography>
									<IconButton
										edge="start"
										color="inherit"
										onClick={(event) => {
											setDataClient({});
											setOpenDialogClient(false);
										}}
										aria-label="close"
									>
										<CloseIcon />
									</IconButton>
								</Toolbar>
							</AppBar>

							<Divider />

							<Container maxWidth="xl" sx={{ p: 3 }}>
								<SelectDialog loadComponent={selectComponent} users={dataClients} dataClient={dataClient} dataClientIdentification={dataClientIdentification} dataClientProofAddress={dataClientProofAddress} />
							</Container>
						</Dialog>
					</Grid>
				</Box>
			</Container>
			{/* <Sidebar /> */}

		</div >
	)
}