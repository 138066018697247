import React, { useEffect, useState } from 'react';

import { Global } from '../../../../helpers/Global';
import { useAlert } from '../../../../hooks/useAlert';
import { getDateNow } from '../../../utils/Time';
import PasswordDialog from './helpers/PasswordDialog';
import ListPawnAdjustmentSlips from './ListPawnAdjustmentSlips';

import { Container, Typography, TextField, Button, Grid, Card, CardHeader, CardContent, Avatar, MenuItem, Snackbar, Box, SpeedDial, SpeedDialIcon, SpeedDialAction, FormHelperText } from '@mui/material';
import { Table, TableHead, TableBody, TableRow, TableCell, Paper, TableContainer } from '@mui/material';

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PercentIcon from '@mui/icons-material/Percent';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import FormatListNumberedRtlOutlinedIcon from '@mui/icons-material/FormatListNumberedRtlOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

import { styled } from '@mui/material/styles';
import RegisterAccess from './helpers/RegisterAccess';

export const PawnAdjustmentSlip = ({ pawnAdjustmentSlip, dataClient }) => {

	// Hook para verificar si se esta cargando el contenido de una boleta de empeño
	useEffect(() => {
		if (pawnAdjustmentSlip !== undefined && pawnAdjustmentSlip !== null) {

			setIdPawnAdjustmentSlip(pawnAdjustmentSlip.id_pawn_adjustment_slip);
			setIdUserPawnAdjustmentSlip(pawnAdjustmentSlip.id_user); // ! TEMPORAL POR NUEVA MIGRACION

			const content = JSON.parse(pawnAdjustmentSlip.content);
			console.log(content);

			setDeduccionKilometraje(content.deduccion_deduccion_kilometraje);
			setDeduccionTipoVehiculo(content.deduccion_tipo_vehiculo);
			setTotalDescuentos(content.deduccion_totalDescuentos);

			setValorAvaluo(content.ajuste_valor_avaluo);
			setAvaluoKilometraje(content.ajuste_avaluo_km);
			setAvaluoTipoVehiculo(content.ajuste_avaluo_tipo_vehiculo);
			setAvaluoAjustado(content.ajuste_avaluo_ajustado);
			setDescuentoMultas(content.ajuste_multas);
			setDescuentoTramites(content.ajuste_tramites);
			setDescuentoAverias(content.ajuste_averia_estetica_mecanica);
			setMontoPrestar(content.ajuste_monto_prestar);
			setMargenFavorCliente(content.ajuste_margen_favor);
			setFormData(content);
			setVerificarDeduccionKM(true);
		}
	}, [pawnAdjustmentSlip])

	// Hook para verificar si se esta cargando el contenido de un cliente del datatable de prospectos en backoffice
	useEffect(() => {
		if (dataClient !== undefined && dataClient !== null && Object.keys(dataClient).length !== 0) {
			setFormData(prevData => ({
				...prevData,
				user_nombre: dataClient.name && (dataClient.name).toString().toUpperCase(),
				user_apellido_paterno: dataClient.middle_name && (dataClient.middle_name).toString().toUpperCase(),
				user_apellido_materno: dataClient.last_name && (dataClient.last_name).toString().toUpperCase(),
				user_email: dataClient.email && dataClient.email,
				user_telefono: dataClient.celular && dataClient.celular,
			}));
			// Asignar informacion del simulador de hubspot
			if (dataClient.hubspot_simulator) {
				const dataSimulator = JSON.parse(dataClient.hubspot_simulator);
				const carYear = (dataSimulator.car_year || new Date().getFullYear()).toString();
				const carModel = dataSimulator.car_brand || 'MODELO';
				const carVersion = dataSimulator.car_version || 'VERSION';
				setFormData(prevData => ({
					...prevData,
					car_modelo: carYear,
					car_tipo: `${carModel} - ${carVersion}`,
				}));
			}
		}
	}, [dataClient])

	const [formData, setFormData] = useState({
		user_nombre: '',
		user_apellido_paterno: '',
		user_apellido_materno: '',
		user_edad: '',
		user_fecha: getDateNow(),
		user_direccion: '',
		user_numero: '',
		user_colonia: '',
		user_cp: '',
		user_municipio: '',
		user_estado: 'CMX',
		user_nacionalidad: '',
		user_curp: '',
		user_rfc: '',
		user_email: '',
		user_telefono: '',

		car_marca: '',
		car_vin: '',
		car_modelo: '',
		car_motor: '',
		car_tipo: '',
		car_kilometraje: '',
		car_placa: '',
		car_color: '',
		car_transmision: '',
		car_plataforma: '',
		car_facturas: '0',

		deduccion_deduccion_kilometraje: '',
		deduccion_tipo_vehiculo: '',
		deduccion_mecanica: '',
		deduccion_carroceria: '',
		deduccion_suspension: '',
		deduccion_neumaticos: '',
		deduccion_cristales: '',
		deduccion_tenencias: '',
		deduccion_infracciones: '',
		deduccion_propietario: '',
		deduccion_extemporanea: '',
		deduccion_totalDescuentos: '',

		ajuste_monto_solicitado_cliente: '',
		ajuste_precio_guia_autometrica: '',
		ajuste_valor_avaluo: '',
		ajuste_tipo_vehiculo: '',
		ajuste_avaluo_km: '',
		ajuste_avaluo_ajustado: '',
		ajuste_avaluo_tipo_vehiculo: '',
		ajuste_multas: '',
		ajuste_tramites: '',
		ajuste_averia_estetica_mecanica: '',
		ajuste_facturas: 0,
		ajuste_descuento_spingo: 0,
		ajuste_monto_prestar: '',
		ajuste_margen_favor: '',
	});

	// ID Pawn Adjustment Slip
	const [idPawnAdjustmentSlip, setIdPawnAdjustmentSlip] = useState(0);
	const [idUserPawnAdjustmentSlip, setIdUserPawnAdjustmentSlip] = useState(0); // ! TEMPORAL POR NUEVA MIGRACION

	// Password
	const [dialogOpen, setDialogOpen] = useState(false);
	// Render componenets child
	const [renderComponentChild, setRenderComponentChild] = useState('');
	const [openComponentChild, setOpenComponentChild] = useState(false);

	// Deducciones
	const [deduccionKilometraje, setDeduccionKilometraje] = useState(0);
	const [deduccionTipoVehiculo, setDeduccionTipoVehiculo] = useState(0);
	const [totalDescuentos, setTotalDescuentos] = useState(0);
	const [verificarDeduccionKM, setVerificarDeduccionKM] = useState(false);

	// Ajuste Prestamo
	const [valorAvaluo, setValorAvaluo] = useState(0);
	const [avaluoKilometraje, setAvaluoKilometraje] = useState(0);
	const [avaluoTipoVehiculo, setAvaluoTipoVehiculo] = useState(0);
	const [avaluoAjustado, setAvaluoAjustado] = useState(0);
	const [descuentoMultas, setDescuentoMultas] = useState(0);
	const [descuentoTramites, setDescuentoTramites] = useState(0);
	const [descuentoAverias, setDescuentoAverias] = useState(0);
	const [montoPrestar, setMontoPrestar] = useState(0);
	const [margenFavorCliente, setMargenFavorCliente] = useState(0);

	const [tablaVisible, setTablaVisible] = useState(false);

	const [enabledButton, setEnabledButton] = useState(true);

	// Alerts Message
	const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

	// Hook que se encarga de actualuzar inmediatamente el valor de las deducciones correspondientes
	useEffect(() => {
		// console.log('Descuento Multas:', descuentoMultas);
		// console.log('Descuento Tramites:', descuentoTramites);
		// console.log('Descuento Averias:', descuentoAverias);
		setTotalDescuentos(descuentoMultas + descuentoTramites + descuentoAverias);
		// console.log('Total Descuentos:', totalDescuentos);
	}, [totalDescuentos, descuentoMultas, descuentoTramites, descuentoAverias]);

	const years = [
		'2012',
		'2013',
		'2014',
		'2015',
		'2016',
		'2017',
		'2018',
		'2019',
		'2020',
		'2021',
		'2022',
		'2023',
		'2024'
	];

	const cars = [
		'Toyota',
		'Honda',
		'Ford',
		'Chevrolet',
		'Volkswagen',
		'Nissan',
		'Mazda',
		'Mini Cooper',
		'BMW',
		'Mercedes-Benz',
		'Audi',
		'Tesla',
		'Hyundai',
		'Kia',
		'Subaru',
		'Chrysler',
		'Jeep',
		'Dodge',
		'Renault',
		'Baic',
		'Volvo',
		'Jac',
		'Peugeot',
		'Suzuki',
		'Lincoln',
		'MG',
		'SEAT',
		'FIAT',
		'HINO',
		'BUICK',
		'Acura',
		'Infiniti',
		'Mitsubishi',
		'Cadillac',
		'GMC',
		'SUBARU',
	];

	const dataMileage = [
		{ year: 2024, rango: { start: 1, end: 14999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 15000, end: 24999 }, ajuste2: { start: 1, end: 10 }, rango3: { start: 25000, end: 40000 }, ajuste3: { start: 10, end: 21 }, rango4: { start: 40001, end: 52000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2023, rango: { start: 1, end: 24999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 25000, end: 39999 }, ajuste2: { start: 1, end: 10 }, rango3: { start: 40000, end: 70000 }, ajuste3: { start: 10, end: 21 }, rango4: { start: 70001, end: 90000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2022, rango: { start: 1, end: 39999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 40000, end: 69999 }, ajuste2: { start: 1, end: 10 }, rango3: { start: 70000, end: 100000 }, ajuste3: { start: 10, end: 21 }, rango4: { start: 100001, end: 130000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2021, rango: { start: 1, end: 59999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 60000, end: 79999 }, ajuste2: { start: 1, end: 15 }, rango3: { start: 80000, end: 110000 }, ajuste3: { start: 15, end: 21 }, rango4: { start: 110001, end: 140000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2020, rango: { start: 1, end: 79999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 80000, end: 104999 }, ajuste2: { start: 1, end: 15 }, rango3: { start: 105000, end: 125000 }, ajuste3: { start: 15, end: 21 }, rango4: { start: 125001, end: 160000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2019, rango: { start: 1, end: 89999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 90000, end: 129999 }, ajuste2: { start: 1, end: 15 }, rango3: { start: 130000, end: 145000 }, ajuste3: { start: 15, end: 21 }, rango4: { start: 145001, end: 190000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2018, rango: { start: 1, end: 99999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 100000, end: 139999 }, ajuste2: { start: 1, end: 15 }, rango3: { start: 140000, end: 165000 }, ajuste3: { start: 15, end: 21 }, rango4: { start: 165001, end: 210000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2017, rango: { start: 1, end: 129999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 130000, end: 155449 }, ajuste2: { start: 1, end: 15 }, rango3: { start: 155500, end: 180000 }, ajuste3: { start: 15, end: 21 }, rango4: { start: 180001, end: 230000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2016, rango: { start: 1, end: 139999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 140000, end: 169999 }, ajuste2: { start: 1, end: 18 }, rango3: { start: 170000, end: 200000 }, ajuste3: { start: 18, end: 21 }, rango4: { start: 200001, end: 250000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2015, rango: { start: 1, end: 149999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 150000, end: 179999 }, ajuste2: { start: 1, end: 18 }, rango3: { start: 180000, end: 230000 }, ajuste3: { start: 18, end: 21 }, rango4: { start: 230001, end: 280000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2014, rango: { start: 1, end: 159999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 160000, end: 189999 }, ajuste2: { start: 1, end: 18 }, rango3: { start: 190000, end: 240000 }, ajuste3: { start: 18, end: 21 }, rango4: { start: 240001, end: 280000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2013, rango: { start: 1, end: 169999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 170000, end: 219999 }, ajuste2: { start: 1, end: 21 }, rango3: { start: 220000, end: 240000 }, ajuste3: { start: 22, end: 0 }, rango4: { start: 240001, end: 280000 }, ajuste4: { start: 30, end: 0 } },
		{ year: 2012, rango: { start: 1, end: 169999 }, ajuste: { start: 0, end: 0 }, rango2: { start: 170000, end: 219999 }, ajuste2: { start: 1, end: 21 }, rango3: { start: 220000, end: 240000 }, ajuste3: { start: 22, end: 0 }, rango4: { start: 240001, end: 280000 }, ajuste4: { start: 30, end: 0 } },
	];

	const lowDemandVehicles = [
		{ brand: "Dodge Jeep", model: "Vision, Attitude, Dart, l10, Avenger, Compas, Patriot", loan_percentage_factor: 70, vehicles_ta: 70, vehicles_tm: 80 },
		{ brand: "Jeep", model: "Compas, Patriot", loan_percentage_factor: 70, vehicles_ta: 80, vehicles_tm: 80 },
		{ brand: "Ford", model: "Figo, Focus, Fiesta, Fusion y Eco Sport", loan_percentage_factor: 70, vehicles_ta: 70, vehicles_tm: 80 },
		{ brand: "Chrysler", model: "200 y 300", loan_percentage_factor: 70, vehicles_ta: 70, vehicles_tm: 80 },
		{ brand: "Baic", model: "Todos los modelos", loan_percentage_factor: 70, vehicles_ta: 60, vehicles_tm: 70 },
		{ brand: "Jac", model: "Todos los modelos", loan_percentage_factor: 70, vehicles_ta: 60, vehicles_tm: 70 },
		{ brand: "Peugeot", model: "Todos los modelos 2013-2018", loan_percentage_factor: 70, vehicles_ta: 60, vehicles_tm: 80 },
		{ brand: "Peugeot_", model: "Todos los modelos 2019-2024", loan_percentage_factor: 70, vehicles_ta: 80, vehicles_tm: 100 },
		{ brand: "Renault", model: "Todos los modelos 2013-2018", loan_percentage_factor: 70, vehicles_ta: 60, vehicles_tm: 80 },
		{ brand: "Renault_", model: "Todos los modelos 2019-2024", loan_percentage_factor: 70, vehicles_ta: 80, vehicles_tm: 100 },
		{ brand: "Subaru", model: "Todos los modelos", loan_percentage_factor: 70, vehicles_ta: 70, vehicles_tm: 80 },
		{ brand: "Volvo", model: "Todos los modelos 2013-2020", loan_percentage_factor: 70, vehicles_ta: 40, vehicles_tm: 50 },
		{ brand: "Volvo_", model: "Todos los modelos 2020-2024", loan_percentage_factor: 70, vehicles_ta: 70, vehicles_tm: 80 },
		{ brand: "Aseguradora Daños", model: "Todos los modelos", loan_percentage_factor: 70, vehicles_ta: 50, vehicles_tm: 50 },
		{ brand: "Aseguradora Robo", model: "Todos los modelos", loan_percentage_factor: 70, vehicles_ta: 70, vehicles_tm: 70 },
		{ brand: "Plataforma", model: "Todos los modelos", loan_percentage_factor: 70, vehicles_ta: 70, vehicles_tm: 70 },
		{ brand: "Vehículos S/Público", model: "No Procede", loan_percentage_factor: 0, vehicles_ta: 0, vehicles_tm: 0 },
		{ brand: "Land Rover", model: "No Procede", loan_percentage_factor: 0, vehicles_ta: 0, vehicles_tm: 0 },
		{ brand: "Faw-Giant", model: "No Procede", loan_percentage_factor: 0, vehicles_ta: 0, vehicles_tm: 0 },
		{ brand: "Vehículos Eléctricos", model: "Si Procede", loan_percentage_factor: 0, vehicles_ta: 70, vehicles_tm: 70 },
		{ brand: "Vehículos Chinos", model: "Si Procede", loan_percentage_factor: 0, vehicles_ta: 70, vehicles_tm: 80 }
	];

	const estados = [
		{ value: '', label: '', code: '' },
		{ value: 'Aguascalientes', label: 'Aguascalientes', code: 'AGU' },
		{ value: 'Baja California', label: 'Baja California', code: 'BCN' },
		{ value: 'Baja California Sur', label: 'Baja California Sur', code: 'BCS' },
		{ value: 'Campeche', label: 'Campeche', code: 'CAM' },
		{ value: 'Chiapas', label: 'Chiapas', code: 'CHP' },
		{ value: 'Chihuahua', label: 'Chihuahua', code: 'CHH' },
		{ value: 'Coahuila', label: 'Coahuila', code: 'COA' },
		{ value: 'Colima', label: 'Colima', code: 'COL' },
		{ value: 'Ciudad de México', label: 'Ciudad de México', code: 'CMX' },
		{ value: 'Durango', label: 'Durango', code: 'DGO' },
		{ value: 'Estado de México', label: 'Estado de México', code: 'MEX' },
		{ value: 'Guanajuato', label: 'Guanajuato', code: 'GTO' },
		{ value: 'Guerrero', label: 'Guerrero', code: 'GRO' },
		{ value: 'Hidalgo', label: 'Hidalgo', code: 'HGO' },
		{ value: 'Jalisco', label: 'Jalisco', code: 'JAL' },
		{ value: 'Michoacán', label: 'Michoacán', code: 'MICH' },
		{ value: 'Morelos', label: 'Morelos', code: 'MOR' },
		{ value: 'Nayarit', label: 'Nayarit', code: 'NAY' },
		{ value: 'Nuevo León', label: 'Nuevo León', code: 'NL' },
		{ value: 'Oaxaca', label: 'Oaxaca', code: 'OAX' },
		{ value: 'Puebla', label: 'Puebla', code: 'PUE' },
		{ value: 'Querétaro', label: 'Querétaro', code: 'QUE' },
		{ value: 'Quintana Roo', label: 'Quintana Roo', code: 'QROO' },
		{ value: 'San Luis Potosí', label: 'San Luis Potosí', code: 'SLP' },
		{ value: 'Sinaloa', label: 'Sinaloa', code: 'SIN' },
		{ value: 'Sonora', label: 'Sonora', code: 'SON' },
		{ value: 'Tabasco', label: 'Tabasco', code: 'TAB' },
		{ value: 'Tamaulipas', label: 'Tamaulipas', code: 'TAMPS' },
		{ value: 'Tlaxcala', label: 'Tlaxcala', code: 'TLAX' },
		{ value: 'Veracruz', label: 'Veracruz', code: 'VER' },
		{ value: 'Yucatán', label: 'Yucatán', code: 'YUC' },
		{ value: 'Zacatecas', label: 'Zacatecas', code: 'ZAC' },
	];

	const propsCard = {
		marginBottom: '2rem'
	}

	const actions = [
		{ icon: <FileCopyIcon />, name: 'Abrir Boletas de Ajuste de Empeño', action: () => handleRenderComponentChild('list') },
		{ icon: <LockOpenOutlinedIcon />, name: 'Abrir Registros de Acceso', action: () => handleRenderComponentChild('register-access') },
	];

	const CustomButton = styled(Button)(() => ({
		color: 'white',
		backgroundColor: '#3ad69b',
		'&:hover': {
			backgroundColor: '#37b988',
		},
	}));

	const handleRenderComponentChild = (render) => {
		if (render === 'list') {
			setRenderComponentChild('list');

		} else if (render === 'register-access') {
			setRenderComponentChild('register-access');

		}
		setOpenComponentChild(true);
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// * Validar Datos del Vehiculo
		if (!validarDatosVehiculo()) {
			showStatusMessage('Faltan agregar datos obligatorios del vehículo.', 'info');
			return false;
		}

		// * Verificamos si ya se calculo la deduccion de KM
		if (!verificarDeduccionKM) {
			showStatusMessage('Calcula la deducción de kilometraje para poder guardar', 'info');
			return false;
		}

		console.log('Deduccion de KM:', deduccionKilometraje);
		console.log('Deduccion Tipo de Vehiculo:', deduccionTipoVehiculo);
		console.log('Total Descuentos:', totalDescuentos);
		console.log('__________________________________________');
		console.log('Valor Avaluo:', valorAvaluo);
		console.log('Avaluo KM:', avaluoKilometraje);
		console.log('Avaluo Tipo Vehiculo:', avaluoTipoVehiculo);
		console.log('Avaluo Ajustado:', avaluoAjustado);
		console.log('Descuento Multas:', descuentoMultas);
		console.log('Descuento Tramites:', descuentoTramites);
		console.log('Descuento Averias:', descuentoAverias);
		console.log('Monto a Prestar:', montoPrestar);
		console.log('Margen a Favor del Cliente:', margenFavorCliente);

		// El valor de los estados los asignamos el formData
		formData.deduccion_deduccion_kilometraje = deduccionKilometraje;
		formData.deduccion_tipo_vehiculo = deduccionTipoVehiculo;
		formData.deduccion_totalDescuentos = totalDescuentos;
		formData.ajuste_valor_avaluo = valorAvaluo;
		formData.ajuste_avaluo_km = avaluoKilometraje;
		formData.ajuste_avaluo_tipo_vehiculo = avaluoTipoVehiculo;
		formData.ajuste_avaluo_ajustado = avaluoAjustado;
		formData.ajuste_multas = descuentoMultas;
		formData.ajuste_tramites = descuentoTramites;
		formData.ajuste_averia_estetica_mecanica = descuentoAverias;
		formData.ajuste_monto_prestar = montoPrestar;
		formData.ajuste_margen_favor = margenFavorCliente;

		// Desactivar boton de "Guardar"
		setEnabledButton(false);

		// Activar loader
		// setIsLoading(true);

		// Conseguir el token de usuario
		const token = localStorage.getItem("token");

		// Conseguir el nombre del editor
		const editor = JSON.parse(localStorage.getItem("user"));
		formData.user_last_editor = `${editor.name} ${editor.middle_name || ''}`;

		if (dataClient) {
			if (dataClient.id) {
				formData.id_prospect = dataClient.id;
				console.log(dataClient.id);
			}
		}

		// Asignar un identificador unico a la boleta por si es la primera vez que se guarda
		if (idPawnAdjustmentSlip === 0) {
			formData.id_pawn_adjustment_slip = new Date().getTime();
		} else {
			formData.id_pawn_adjustment_slip = idPawnAdjustmentSlip;
			formData.id_prospect = 0;
			if (idUserPawnAdjustmentSlip !== 0) {
				formData.id_prospect = idUserPawnAdjustmentSlip;
			}
		}

		console.log(formData);

		try {

			let request = await fetch(`${Global.url_api}api/auth/upsert-pawn-adjustment-slip`, {
				method: 'POST',
				headers: {
					"Authorization": `Bearer  ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(formData),
				redirect: 'follow'
			});

			let { status, message, code } = await request.json();

			if (code === 201 && status === "success") {
				showStatusMessage('¡Boleta de Ajuste de Empeño guardada correctamente.!', 'success');
			} else {
				showStatusMessage(`Error inesperado`, 'warning');
				console.log(JSON.stringify(message));
			}

		} catch (error) {
			console.log(error);
			showStatusMessage(`error client: ${error}`, 'error');
		}

		// Desactivar loader
		// setIsLoading(false);

		// Activar boton de "Guardar"
		setEnabledButton(true);
	};

	const toggleTabla = (value) => {
		setTablaVisible(value);
	};

	const handleOpenDialog = () => {
		setDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
	};

	const resetComponentChild = () => {
		setOpenComponentChild(false);
		setRenderComponentChild('');
	}

	const asignarDeduccionKilometraje = () => {
		dataMileage.forEach((fila) => {
			let ajuste;
			let rango;

			if (fila.year === parseInt(formData.car_modelo)) {
				if ((parseInt(formData.car_kilometraje) >= fila.rango.start && parseInt(formData.car_kilometraje) <= fila.rango.end) || (parseInt(formData.car_kilometraje) >= fila.rango2.start && parseInt(formData.car_kilometraje) <= fila.rango2.end) || (parseInt(formData.car_kilometraje) >= fila.rango3.start && parseInt(formData.car_kilometraje) <= fila.rango3.end) || (parseInt(formData.car_kilometraje) >= fila.rango4.start && parseInt(formData.car_kilometraje) <= fila.rango4.end)) {
					if (parseInt(formData.car_kilometraje) >= fila.rango.start && parseInt(formData.car_kilometraje) <= fila.rango.end) {
						console.log('Ajuste y Rango tipo 1');
						ajuste = fila.ajuste;
						rango = fila.rango;
					} else if (parseInt(formData.car_kilometraje) >= fila.rango2.start && parseInt(formData.car_kilometraje) <= fila.rango2.end) {
						console.log('Ajuste y Rango tipo 2');
						ajuste = fila.ajuste2;
						rango = fila.rango2;
					} else if (parseInt(formData.car_kilometraje) >= fila.rango3.start && parseInt(formData.car_kilometraje) <= fila.rango3.end) {
						console.log('Ajuste y Rango tipo 3');
						ajuste = fila.ajuste3;
						rango = fila.rango3;
					} else if (parseInt(formData.car_kilometraje) >= fila.rango4.start && parseInt(formData.car_kilometraje) <= fila.rango4.end) {
						console.log('Ajuste y Rango tipo 4');
						ajuste = fila.ajuste4;
						rango = fila.rango4;
					} else {
						console.log('none');
						ajuste = 'none';
						rango = 'none';
					}

					localStorage.setItem(
						'ajuste',
						JSON.stringify({
							kilometraje: formData.car_kilometraje,
							rango: rango,
							ajuste: ajuste,
						})
					);
				}
			}
		});
	}

	const calcularDeduccionKilometraje = () => {

		// Reiniciamos el localStorage
		localStorage.removeItem('ajuste');

		// Calculamos la deduccion de kilometraje en base a la tabla "Tabla de Kilometraje"
		asignarDeduccionKilometraje();

		// Verificar que ya exista el contenido de 'ajuste' para hacer el calculo
		if (JSON.parse(localStorage.getItem('ajuste')) === null) {
			showStatusMessage('No se ha asignado un valor valido en base a la tabla de kilometraje.', 'warning');
			// Si hay un valor previo en el hook
			setDeduccionKilometraje(0);
			return false;
		}

		console.log('@Datos-Ajuste', JSON.parse(localStorage.getItem('ajuste')));

		let { kilometraje, ajuste, rango } = JSON.parse(localStorage.getItem('ajuste'));

		// PARA AJUSTES DE TIPO 1 - Sin deduccion por tipo de vehiculo
		if (ajuste.start === 0 && ajuste.end === 0) {
			setDeduccionKilometraje(0);
		}
		else if (ajuste.start === 21 && ajuste.end === 0) {
			setDeduccionKilometraje(21);
		} else if (ajuste.start === 22 && ajuste.end === 0) {
			setDeduccionKilometraje(22);
		} else if (ajuste.start === 30 && ajuste.end === 0) {
			setDeduccionKilometraje(30);
		}
		else {
			// PARA AJUSTES DE TIPO 2- Con deduccion por tipo de vehiculo
			// * Metodo Inicial
			// rest - rango = (rango2 - rango1)
			// kilometraje = kilometraje - rango1
			// porcentaje = (kilometraje * 100) / rest - rango
			// ajuste = porcentaje * ajuste2 / 100 - redondear

			// * Metodo Beta
			// let restoRango = (rango.end - rango.start);
			// let kilometrajeTemp = (kilometraje - rango.start);
			// let porcentaje = (kilometrajeTemp * 100) / restoRango;
			// let result = (parseInt(kilometraje) === rango.start) ? parseFloat(1).toFixed(1) : parseFloat((porcentaje * ajuste.end) / 100).toFixed(1);
			// console.log(result);

			// * Metodo Chat GPT
			let porcentajeCalculado = ajuste.start + ((kilometraje - rango.start) / (rango.end - rango.start)) * (ajuste.end - ajuste.start);

			setDeduccionKilometraje(porcentajeCalculado.toFixed(1));
		}
		showStatusMessage('Deducción de KM calculada correctamente', 'success');

		// Indicamos al estado que se calculo la deduccion de KM y ya puede calcular ajustes y guardar la boleta
		setVerificarDeduccionKM(true);
	}

	const calcularDeducciones = (event) => {
		event.preventDefault();

		// * Validar Datos del Vehiculo
		if (!validarDatosVehiculo()) {
			showStatusMessage('Faltan agregar datos obligatorios del vehículo.', 'info');
			return false;
		}

		// * Calcular Descuento por Multas
		// Multas = Tenencias + Infracciones + Extemporánea
		let multasTemp = parseInt(formData.deduccion_tenencias) + parseInt(formData.deduccion_infracciones) + parseInt(formData.deduccion_extemporanea);
		setDescuentoMultas(multasTemp);

		// * Calcular Descuento por Tramites
		// Tramites = Cambio de propietario
		setDescuentoTramites(parseInt(formData.deduccion_propietario));

		// * Calcular Daños Esteticos
		// Daños Estéticos = Mecanica + Carroceria + Suspension + Neumaticos + Cristales
		let averiasTemp = parseInt(formData.deduccion_mecanica) + parseInt(formData.deduccion_carroceria) + parseInt(formData.deduccion_suspension) + parseInt(formData.deduccion_neumaticos) + parseInt(formData.deduccion_cristales);
		setDescuentoAverias(averiasTemp);

		// * Calcular Total de Descuentos
		// ! Total Descuentos  =  Multas + Tramites + Daños Esteticos + Tipo Vehiculo => REVISAR LO DE TIPO DE VEHICULO
		// ! setTotalDescuentos(descuentoMultas + descuentoTramites + descuentoAverias); => NO ACTUALIZA EL VALOR DE INMEDIATO
		// setTotalDescuentos(multasTemp + parseInt(formData.deduccion_propietario) + averiasTemp);
	}

	const calcularAjustePrestamo = (event) => {
		event.preventDefault();

		// * Validar Datos del Vehiculo
		if (!validarDatosVehiculo()) {
			showStatusMessage('Faltan agregar datos obligatorios del vehículo.', 'info');
			return false;
		}

		// * Verificamos si ya se calculo la deduccion de KM
		if (!verificarDeduccionKM) {
			showStatusMessage('Calcula la deducción de kilometraje para continuar', 'info');
			return false;
		}

		// * Validar si las facturas aplicaran algun ajuste
		const tempAjusteFacturas = AjusteFacturas();

		if (tempAjusteFacturas === 'none') {
			showStatusMessage('¡Solicitud de Crédito Rechazada!', 'error');
			return false;
		}

		// console.log('Descuento Multas:', descuentoMultas);
		// console.log('Descuento Tramites:', descuentoTramites);
		// console.log('Descuento Averias:', descuentoAverias);
		// console.log('Kilometraje %', deduccionKilometraje);

		if (formData.ajuste_tipo_vehiculo === 'none') {
			console.log('Calculo Normal');

			// Si previamente se hizo un calculo con tipo de vehiculo
			setDeduccionTipoVehiculo(0);
			setAvaluoTipoVehiculo(0);

			// * Calcular Ajuste de Avaluo por KM
			// Ajuste de Avaluo por KM = Precio Guia Autometrica * Kilometraje % 
			let ajusteAvaluoKM = parseInt((formData.ajuste_precio_guia_autometrica) * (parseFloat(deduccionKilometraje) / 100));
			setAvaluoKilometraje(ajusteAvaluoKM);
			console.log('Deduccion KM %:', deduccionKilometraje);

			// * Calcular Valor de Avaluo
			// Valor Avaluo = (Precio Guia Autometrica - Ajuste de Avaluo por KM) * 70%
			let valorAvaluoTemp = parseInt((parseInt(formData.ajuste_precio_guia_autometrica) - ajusteAvaluoKM) * 0.80);
			setValorAvaluo(valorAvaluoTemp);

			// * Calcular Valor de Avaluo Ajustado
			// Valor de Avaluo Ajustado = Valor Avaluo
			let valorAvaluoAjustadoTemp = parseInt(valorAvaluoTemp);
			setAvaluoAjustado(valorAvaluoAjustadoTemp);

			// * Calcular Monto a Prestar
			// Monto a Prestar = Valor de Avaluo Ajustado - (Descuento Multas + Descuento Tramites + Descuento Averias) - Ajuste Facturas
			// Si se aplica un descuento especial spingo
			let montoPrestarTemp;
			if (parseInt(formData.ajuste_descuento_spingo) > 0) {
				console.log('Descuento spingo');
				montoPrestarTemp = valorAvaluoAjustadoTemp - (descuentoMultas + descuentoTramites + descuentoAverias) - parseInt(formData.ajuste_descuento_spingo) - parseInt(tempAjusteFacturas);
				setMontoPrestar(montoPrestarTemp);
			} else {
				montoPrestarTemp = valorAvaluoAjustadoTemp - (descuentoMultas + descuentoTramites + descuentoAverias) - parseInt(tempAjusteFacturas);
				setMontoPrestar(montoPrestarTemp);
			}

			// * Calcular Margen a Favor de Cliente
			//  Margen a Favor de Cliente = Monto a Prestar - Monto Solicitado por el Cliente
			let margenFavorClienteTemp = montoPrestarTemp - parseInt(formData.ajuste_monto_solicitado_cliente);
			setMargenFavorCliente(margenFavorClienteTemp);
		}
		else {
			console.log('Calculo con Tipo de Auto');

			let tipoVehiculoTemp = lowDemandVehicles.find(tipo => tipo.brand === formData.ajuste_tipo_vehiculo);
			// console.log('Tipo Vehiculo: ', tipoVehiculoTemp);

			// Validar que se haya seleccionado un tipo de transmision
			if (formData.car_transmision === '') {
				showStatusMessage('Aún no ha seleccionado un tipo de transmisión', 'warning');
				return false;
			}

			// Seleccionar porcentaje en base a la transmision del auto
			let porcentajeTransmision;
			if (formData.car_transmision === 'automatica') porcentajeTransmision = tipoVehiculoTemp.vehicles_ta;
			if (formData.car_transmision === 'manual') porcentajeTransmision = tipoVehiculoTemp.vehicles_tm;
			console.log(`Tranmision: ${formData.car_transmision} - ${porcentajeTransmision}% del ${tipoVehiculoTemp.loan_percentage_factor}%`);

			// Calcular y Asignar el valor de la Deduccion en base al Tipo de Vehiculo
			setDeduccionTipoVehiculo((100 - porcentajeTransmision));

			// * Calcular Ajuste de Avaluo por KM
			// Ajuste de Avaluo por KM = Precio Guia Autometrica * Deduccion Kilometraje % 
			let ajusteAvaluoKM = parseInt((formData.ajuste_precio_guia_autometrica) * (parseFloat(deduccionKilometraje) / 100));
			setAvaluoKilometraje(ajusteAvaluoKM);
			console.log('Deduccion KM %:', deduccionKilometraje);

			// * Calcular Valor de Avaluo
			// Valor Avaluo = (Precio Guia Autometrica - Ajuste de Avaluo por KM) * 70%
			let valorAvaluoTemp = parseInt((parseInt((formData.ajuste_precio_guia_autometrica)) - ajusteAvaluoKM) * 0.80);
			setValorAvaluo(valorAvaluoTemp);

			// * Calcular Ajuste por Tipo de Vehiculo
			// Ajuste por tipo de vehiculo = Valor de Avaluo * Porcentaje de tipo de transmision
			// Aqui se guarda el monto a prestar
			let vehiculoTemp = parseInt(valorAvaluoTemp * (parseFloat(porcentajeTransmision) / 100));

			// Aqui se guarda con lo que se va a castigar
			let nuevoVehiculoTemp = valorAvaluoTemp - vehiculoTemp;
			setAvaluoTipoVehiculo(nuevoVehiculoTemp);

			// * Calcular Valor de Avaluo Ajustado
			// Valor de Avaluo Ajustado = Valor Avaluo - Ajuste de Avaluo por KM
			let valorAvaluoAjustadoTemp = Math.abs(vehiculoTemp);
			setAvaluoAjustado(valorAvaluoAjustadoTemp);

			// * Calcular Monto a Prestar
			// Monto a Prestar = Valor de Avaluo Ajustado - (Descuento Multas + Descuento Tramites + Descuento Averias) - Ajuste Facturas
			// Si se aplica un descuento especial spingo
			let montoPrestarTemp;
			if (parseInt(formData.ajuste_descuento_spingo) > 0) {
				montoPrestarTemp = valorAvaluoAjustadoTemp - (descuentoMultas + descuentoTramites + descuentoAverias) - parseInt(formData.ajuste_descuento_spingo) - parseInt(tempAjusteFacturas);
				setMontoPrestar(montoPrestarTemp);
			} else {
				montoPrestarTemp = valorAvaluoAjustadoTemp - (descuentoMultas + descuentoTramites + descuentoAverias) - parseInt(tempAjusteFacturas);
				setMontoPrestar(montoPrestarTemp);
			}

			// * Calcular Margen a Favor de Cliente
			//  Margen a Favor de Cliente = Monto a Prestar - Monto Solicitado por el Cliente
			let margenFavorClienteTemp = montoPrestarTemp - parseInt(formData.ajuste_monto_solicitado_cliente);
			setMargenFavorCliente(margenFavorClienteTemp);
		}

		showStatusMessage('Ajuste de prestamo actualizado', 'success');
	}

	const validarDatosVehiculo = () => {
		const { car_marca, car_modelo, car_tipo, car_kilometraje, car_transmision } = formData;

		return (
			car_marca !== null &&
			car_modelo !== null &&
			car_tipo !== null &&
			car_kilometraje !== null &&
			car_transmision !== null &&
			car_marca !== '' &&
			car_modelo !== '' &&
			car_tipo !== '' &&
			car_kilometraje !== '' &&
			car_transmision !== ''
		);
	}

	const verificarDatosCompletos = () => {
		for (let clave in formData) {
			if (formData.hasOwnProperty(clave)) {
				if (formData[clave] === null || formData[clave] === "") {
					return true; // El objeto tiene al menos un campo vacío o nulo
				}
			}
		}
		return false; // No se encontraron campos vacíos o nulos
	}

	const AjusteFacturas = () => {

		// Reiniciar estado para borrar estado anterior
		setFormData(prevState => ({
			...prevState,
			ajuste_facturas: 0,
		}));

		if (formData.car_facturas === '0') {
			console.log('No se aplica castigo por descuento de factura');
			return 0;
		} else if (formData.car_facturas === 'none') {
			console.log('No se da crédito');
			return 'none';
		} else if (formData.car_facturas === '' || formData.car_facturas === null || formData.car_facturas === undefined) {
			console.log('No se asigno descuento por facturas');
			return 0;
		}
		else {
			console.log(`${formData.car_facturas}% de ${formData.ajuste_precio_guia_autometrica}`);
			let descuentoAjusteFacturas = parseInt(formData.car_facturas) * (parseInt(formData.ajuste_precio_guia_autometrica) / 100)
			setFormData(prevState => ({
				...prevState,
				ajuste_facturas: descuentoAjusteFacturas,
			}));
			return descuentoAjusteFacturas;
		}
	}

	return (
		<div>
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Container spacing={2} sx={{ padding: '16px' }}>

				{/* SPEED DIAL PARA LAS ACCIONES DEL CONTENIDO */}
				{
					// Solo se mostrara si es la primera vez que se agrega el contenido de la boleta de ajuste
					pawnAdjustmentSlip === undefined &&
					<Box sx={{ height: 70, transform: 'translateZ(0px)', flexGrow: 1 }}>
						<SpeedDial
							ariaLabel="SpeedDial basic example"
							sx={{ position: 'fixed', bottom: 10, right: 0, zIndex: 1000 }}
							icon={<SpeedDialIcon />}
							direction="left"
							FabProps={{
								sx: {
									width: 40,
									height: 40,
									bgcolor: '#3ad69b',
									// bgcolor: '#3340E3',
									'&:hover': {
										bgcolor: '#37b988',
									},
								}
							}}
						>
							{actions.map((action) => (
								<SpeedDialAction
									key={action.name}
									icon={action.icon}
									tooltipTitle={action.name}
									onClick={action.action}
								/>
							))}
						</SpeedDial>
					</Box>
				}

				<Typography variant="h6" textAlign='center' mb={2} onClick={() => { setEnabledButton(true) }}>BOLETA DE AJUSTE DE EMPEÑO</Typography>

				{verificarDatosCompletos() ?
					<Alert variant="outlined" severity="warning" sx={{ marginBottom: '10px' }} >Boleta de Ajuste de Empeño Incompleta.</Alert>
					: <Alert variant="outlined" severity="success" sx={{ marginBottom: '10px' }}>¡Boleta de Ajuste de Empeño Completada!</Alert>
				}

				{/* DATOS DEL PROSPECTO */}
				<Card elevation={8} sx={propsCard}>
					<CardHeader
						avatar={
							<Avatar>
								<AccountBoxOutlinedIcon />
							</Avatar>
						}
						title="Datos del Prospecto"
					/>
					<CardContent>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Fecha"
										name="user_fecha"
										fullWidth
										variant="outlined"
										type="date"
										value={formData.user_fecha}
										onChange={handleChange}
										required
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Nombres"
										name="user_nombre"
										fullWidth
										variant="outlined"
										value={formData.user_nombre}
										onChange={handleChange}
										required
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Apellido Paterno"
										name="user_apellido_paterno"
										fullWidth
										variant="outlined"
										value={formData.user_apellido_paterno}
										onChange={handleChange}
										required
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Apellido Materno"
										name="user_apellido_materno"
										fullWidth
										variant="outlined"
										value={formData.user_apellido_materno}
										onChange={handleChange}
										required
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Edad"
										name="user_edad"
										fullWidth
										variant="outlined"
										type="number"
										value={formData.user_edad}
										onChange={handleChange}
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Nacionalidad"
										name="user_nacionalidad"
										fullWidth
										variant="outlined"
										value={formData.user_nacionalidad}
										onChange={handleChange}
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="CURP"
										name="user_curp"
										fullWidth
										variant="outlined"
										value={formData.user_curp}
										onChange={handleChange}
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="RFC"
										name="user_rfc"
										fullWidth
										variant="outlined"
										value={formData.user_rfc}
										onChange={handleChange}
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Email"
										name="user_email"
										fullWidth
										variant="outlined"
										type="email"
										value={formData.user_email}
										onChange={handleChange}
										required
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Teléfono"
										name="user_telefono"
										fullWidth
										variant="outlined"
										type="tel"
										value={formData.user_telefono}
										onChange={handleChange}
										required
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Calle"
										name="user_direccion"
										fullWidth
										variant="outlined"
										value={formData.user_direccion}
										onChange={handleChange}
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Número"
										name="user_numero"
										fullWidth
										variant="outlined"
										value={formData.user_numero}
										onChange={handleChange}
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Colonia"
										name="user_colonia"
										fullWidth
										variant="outlined"
										value={formData.user_colonia}
										onChange={handleChange}
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Código Postal"
										name="user_cp"
										fullWidth
										variant="outlined"
										value={formData.user_cp}
										onChange={handleChange}
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Municipio"
										name="user_municipio"
										fullWidth
										variant="outlined"
										value={formData.user_municipio}
										onChange={handleChange}
										size='small'
										color='secondary'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										select
										label="Estado"
										name="user_estado"
										fullWidth
										variant="outlined"
										value={formData.user_estado}
										onChange={handleChange}
										required
										color='secondary'
										size='small'
									>
										{estados.map((estado) => (
											<MenuItem key={estado.code} value={estado.code}>
												{estado.value}
											</MenuItem>
										))}
									</TextField>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>

				{/* DATOS DEL VEHICULO */}
				<Card elevation={8} sx={propsCard}>
					<CardHeader
						avatar={
							<Avatar>
								<DirectionsCarOutlinedIcon />
							</Avatar>
						}
						title="Datos del Vehículo"
					/>
					<CardContent>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										select
										label="Marca"
										name="car_marca"
										fullWidth
										variant="outlined"
										value={formData.car_marca}
										onChange={handleChange}
										required
										color='secondary'
										size='small'
									>
										{cars.map((car) => (
											<MenuItem key={car} value={car}>
												{car}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="VIN"
										name="car_vin"
										fullWidth
										variant="outlined"
										value={formData.car_vin}
										onChange={handleChange}
										color='secondary'
										size='small'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										select
										label="Año"
										name="car_modelo"
										fullWidth
										variant="outlined"
										value={formData.car_modelo}
										onChange={handleChange}
										required
										color='secondary'
										size='small'
									>
										{years.reverse().map((anio) => (
											<MenuItem key={anio} value={anio}>
												{anio}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Motor"
										name="car_motor"
										fullWidth
										variant="outlined"
										value={formData.car_motor}
										onChange={handleChange}
										color='secondary'
										size='small'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Tipo"
										name="car_tipo"
										fullWidth
										variant="outlined"
										value={formData.car_tipo}
										onChange={handleChange}
										required
										color='secondary'
										size='small'
									/>
									<FormHelperText sx={{ color: 'red' }}>
										Modelo - Versión
									</FormHelperText>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Kilometraje"
										name="car_kilometraje"
										fullWidth
										variant="outlined"
										type="number"
										value={formData.car_kilometraje}
										onChange={handleChange}
										required
										color='secondary'
										size='small'
										inputProps={{
											min: 0,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Placa"
										name="car_placa"
										fullWidth
										variant="outlined"
										value={formData.car_placa}
										onChange={handleChange}
										color='secondary'
										size='small'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Color"
										name="car_color"
										fullWidth
										variant="outlined"
										value={formData.car_color}
										onChange={handleChange}
										color='secondary'
										size='small'
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										select
										label="Transmisión"
										name="car_transmision"
										fullWidth
										variant="outlined"
										value={formData.car_transmision}
										onChange={handleChange}
										required
										color='secondary'
										size='small'
									>
										<MenuItem value="automatica">Automática</MenuItem>
										<MenuItem value="manual">Manual</MenuItem>
									</TextField>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										select
										label="Plataforma"
										name="car_plataforma"
										fullWidth
										variant="outlined"
										value={formData.car_plataforma}
										onChange={handleChange}
										color='secondary'
										size='small'
									>
										<MenuItem value="no">No</MenuItem>
										<MenuItem value="si">Si</MenuItem>
									</TextField>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										select
										label="Facturas"
										name="car_facturas"
										fullWidth
										variant="outlined"
										value={formData.car_facturas}
										onChange={handleChange}
										required
										color='secondary'
										size='small'
									>
										<MenuItem value="0">1-5 Facturas y la última es de agencia (No aplica)</MenuItem>
										<MenuItem value="10">2-3 Facturas y la última es de empresa (10%)</MenuItem>
										<MenuItem value="15">4 Facturas y la última es de empresa (15%)</MenuItem>
										<MenuItem value="20">5 Facturas y la última es de empresa (20% - No agencia)</MenuItem>
										<MenuItem value="none">6 Facturas (No se da el crédito)</MenuItem>
									</TextField>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>

				{/* DEDUCCIONES POR KM, MOSTRAR CONTENIDO Y GUARDAR FORMATO */}
				<Card elevation={8} sx={propsCard}>
					<CardHeader
						avatar={
							<Avatar>
								<BuildCircleOutlinedIcon />
							</Avatar>
						}
						title="Acciones"
					/>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12} md={4}>
								<CustomButton
									fullWidth
									startIcon={<PercentIcon />}
									variant="contained"
									color="primary"
									onClick={calcularDeduccionKilometraje}
								>
									Obtener Deducción de Kilometraje
								</CustomButton>
								<Typography variant="h6" display="block" mb={3} gutterBottom mt={2}>
									Deducción de Kilometraje: {deduccionKilometraje}%
								</Typography>
							</Grid>
							<Grid item xs={12} md={4}>
								<CustomButton fullWidth startIcon={<BackupTableIcon />} variant="contained" color="primary" onClick={handleOpenDialog}>
									{tablaVisible ? 'Ocultar Tabla KM' : 'Mostrar Tabla KM'}
								</CustomButton>
								<PasswordDialog open={dialogOpen} onClose={handleCloseDialog} sendDataToParent={toggleTabla} />
							</Grid>
							<Grid item xs={12} md={4}>
								<CustomButton fullWidth startIcon={<SaveOutlinedIcon />} variant="contained" color="primary" onClick={handleSubmit} disabled={!enabledButton}>
									{enabledButton ? 'Guardar Formato' : 'Guardando...'}
								</CustomButton>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				{/* MOSTRAR/OCULTAR TABLAS DE CONTENIDO */}
				{tablaVisible &&
					<>
						<TableContainer component={Paper} sx={{ padding: '10px', maxWidth: '70rem', margin: '0 auto', marginBottom: '2rem' }}>
							<Typography variant="h6" textAlign='center' gutterBottom>
								Tabla de Kilometraje
							</Typography>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Año</TableCell>
										<TableCell>Rango</TableCell>
										<TableCell>Ajuste</TableCell>
										<TableCell>Rango</TableCell>
										<TableCell>Ajuste</TableCell>
										<TableCell>Rango</TableCell>
										<TableCell>Ajuste</TableCell>
										<TableCell>Rango</TableCell>
										<TableCell>Ajuste</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{dataMileage.map((fila, index) => (

										<TableRow key={index}>
											<TableCell sx={{ bgcolor: fila.year === parseInt(formData.car_modelo) ? '#00b050' : '#fff' }}>{fila.year}</TableCell>

											<TableCell sx={{ bgcolor: (fila.year === parseInt(formData.car_modelo)) && (parseInt(formData.car_kilometraje) >= fila.rango.start) && (parseInt(formData.car_kilometraje) <= fila.rango.end) ? '#ffc000' : '#fff' }}>
												{fila.rango.start} - {fila.rango.end}
											</TableCell>

											<TableCell sx={{ bgcolor: (fila.year === parseInt(formData.car_modelo)) && (parseInt(formData.car_kilometraje) >= fila.rango.start) && (parseInt(formData.car_kilometraje) <= fila.rango.end) ? '#ffc000' : '#fff' }}>
												{fila.ajuste.start}% - {fila.ajuste.end}%
											</TableCell>

											<TableCell sx={{ bgcolor: (fila.year === parseInt(formData.car_modelo)) && (parseInt(formData.car_kilometraje) >= fila.rango2.start) && (parseInt(formData.car_kilometraje) <= fila.rango2.end) ? '#ffc000' : '#fff' }}>
												{fila.rango2.start} - {fila.rango2.end}
											</TableCell>

											<TableCell sx={{ bgcolor: (fila.year === parseInt(formData.car_modelo)) && (parseInt(formData.car_kilometraje) >= fila.rango2.start) && (parseInt(formData.car_kilometraje) <= fila.rango2.end) ? '#ffc000' : '#fff' }}>
												{fila.ajuste2.start}% - {fila.ajuste2.end}%
											</TableCell>

											<TableCell sx={{ bgcolor: (fila.year === parseInt(formData.car_modelo)) && (parseInt(formData.car_kilometraje) >= fila.rango3.start) && (parseInt(formData.car_kilometraje) <= fila.rango3.end) ? '#ffc000' : '#fff' }}>
												{fila.rango3.start} - {fila.rango3.end}
											</TableCell>

											<TableCell sx={{ bgcolor: (fila.year === parseInt(formData.car_modelo)) && (parseInt(formData.car_kilometraje) >= fila.rango3.start) && (parseInt(formData.car_kilometraje) <= fila.rango3.end) ? '#ffc000' : '#fff' }}>
												{fila.ajuste3.start}% - {fila.ajuste3.end}%
											</TableCell>

											<TableCell sx={{ bgcolor: (fila.year === parseInt(formData.car_modelo)) && (parseInt(formData.car_kilometraje) >= fila.rango4.start) && (parseInt(formData.car_kilometraje) <= fila.rango4.end) ? '#ffc000' : '#fff' }}>
												{fila.rango4.start} - {fila.rango4.end}
											</TableCell>

											<TableCell sx={{ bgcolor: (fila.year === parseInt(formData.car_modelo)) && (parseInt(formData.car_kilometraje) >= fila.rango4.start) && (parseInt(formData.car_kilometraje) <= fila.rango4.end) ? '#ffc000' : '#fff' }}>
												{fila.ajuste4.start}% - {fila.ajuste4.end}%
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<TableContainer component={Paper} sx={{ padding: '10px', maxWidth: '70rem', margin: '0 auto', marginBottom: '2rem' }}>
							<Typography variant="h6" textAlign='center' gutterBottom>
								Valor Base Avaluó De Marcas De Vehículos Con Baja Demanda
							</Typography>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Marcas</TableCell>
										<TableCell>Modelos</TableCell>
										<TableCell>Factor Porcentaje De Prestamo</TableCell>
										<TableCell>Vehículos T/A</TableCell>
										<TableCell>Vehículos T/M</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{lowDemandVehicles.map((fila, index) => (
										<TableRow key={index}>
											<TableCell>
												{fila.brand}
											</TableCell>

											<TableCell>
												{fila.model}
											</TableCell>

											<TableCell>
												{fila.loan_percentage_factor}%
											</TableCell>

											<TableCell>
												{fila.vehicles_ta}%
											</TableCell>

											<TableCell>
												{fila.vehicles_tm}%
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</>
				}

				{/* DEDUCCIONES Y AJUSTES DE PRESTAMO */}
				<Grid container spacing={5}>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Card elevation={8}>
							<CardHeader
								avatar={
									<Avatar>
										<CurrencyExchangeIcon />
									</Avatar>
								}
								title="Deducciones"
							/>
							<CardContent>
								<form onSubmit={calcularDeducciones}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Kilometraje"
												name="deduccion_deduccion_kilometraje"
												variant="outlined"
												type='number'
												fullWidth
												size='small'
												color='secondary'
												onChange={handleChange}
												value={deduccionKilometraje}
												InputProps={{
													endAdornment: <div style={{ marginRight: '10px' }}>%</div>,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Tipo de Vehículo"
												name="deduccion_tipo_vehiculo"
												variant="outlined"
												type='number'
												fullWidth
												size='small'
												color='secondary'
												onChange={handleChange}
												value={deduccionTipoVehiculo}
												InputProps={{
													endAdornment: <div style={{ marginRight: '10px' }}>%</div>,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Mecánica"
												name="deduccion_mecanica"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.deduccion_mecanica}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Carrocería"
												name="deduccion_carroceria"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.deduccion_carroceria}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Suspensión"
												name="deduccion_suspension"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.deduccion_suspension}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Neumáticos"
												name="deduccion_neumaticos"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.deduccion_neumaticos}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Cristales"
												name="deduccion_cristales"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.deduccion_cristales}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Tenencias"
												name="deduccion_tenencias"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.deduccion_tenencias}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Infracciones"
												name="deduccion_infracciones"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.deduccion_infracciones}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="C/Propietario"
												name="deduccion_propietario"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.deduccion_propietario}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="V/Extemporánea"
												name="deduccion_extemporanea"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.deduccion_extemporanea}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Total Descuentos"
												name="deduccion_totalDescuentos"
												variant="outlined"
												type="number"
												fullWidth
												size='small'
												color='secondary'
												onChange={handleChange}
												value={totalDescuentos}
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<CustomButton startIcon={<FormatListNumberedRtlOutlinedIcon />} variant="contained" color="primary" type="submit">
												Calcular
											</CustomButton>
										</Grid>
									</Grid>
								</form>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Card elevation={8}>
							<CardHeader
								avatar={
									<Avatar>
										<PriceCheckIcon />
									</Avatar>
								}
								title="Ajuste Prestamo"
							/>
							<CardContent>
								<form onSubmit={calcularAjustePrestamo}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12}>
											<TextField
												select
												label="Tipo de Vehículo"
												name="ajuste_tipo_vehiculo"
												fullWidth
												variant="outlined"
												value={formData.ajuste_tipo_vehiculo}
												onChange={handleChange}
												required
												color='secondary'
												size='small'
											>
												<MenuItem key={'none'} value={'none'}>
													No Aplica
												</MenuItem>
												{lowDemandVehicles.map((tipo, index) => (
													<MenuItem key={index} value={tipo.brand}>
														<Typography style={{ fontWeight: 'bold' }}>{tipo.brand}:</Typography> {tipo.model}
													</MenuItem>
												))}
											</TextField>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Precio GAM"
												name="ajuste_precio_guia_autometrica"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.ajuste_precio_guia_autometrica}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Valor de Avaluó"
												name="ajuste_valor_avaluo"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={valorAvaluo}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Ajuste de Avaluó por KM"
												name="ajuste_avaluo_km"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={avaluoKilometraje}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Ajuste de Avaluó por Tipo de Vehículo"
												name="ajuste_avaluo_tipo_vehiculo"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={avaluoTipoVehiculo}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Valor Avaluó Ajustado"
												name="ajuste_avaluo_ajustado"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={avaluoAjustado}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="D/ Por Multas"
												name="ajuste_multas"
												variant="outlined"
												type="number"
												fullWidth
												size='small'
												color='secondary'
												onChange={handleChange}
												value={descuentoMultas}
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="D/ Por Tramites"
												name="ajuste_tramites"
												variant="outlined"
												type="number"
												fullWidth
												size='small'
												color='secondary'
												onChange={handleChange}
												value={descuentoTramites}
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Daños Estéticos y Mecánicos"
												name="ajuste_averia_estetica_mecanica"
												variant="outlined"
												type="number"
												fullWidth
												size='small'
												color='secondary'
												value={descuentoAverias}
												onChange={handleChange}
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Ajuste Facturas"
												name="ajuste_facturas"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.ajuste_facturas}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
													readOnly: true,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="D/ SpinGo"
												name="ajuste_descuento_spingo"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.ajuste_descuento_spingo}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={12}>
											<TextField
												label="Monto a Prestar"
												name="ajuste_monto_prestar"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={montoPrestar}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
												sx={{ bgcolor: 'rgba(85, 174, 89, 0.5)' }}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Margen a Favor del Cliente"
												name="ajuste_margen_favor"
												variant="outlined"
												fullWidth
												// required
												value={margenFavorCliente}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												label="Monto Solicitado por el Cliente"
												name="ajuste_monto_solicitado_cliente"
												variant="outlined"
												type="number"
												fullWidth
												required
												value={formData.ajuste_monto_solicitado_cliente}
												onChange={handleChange}
												size='small'
												color='secondary'
												InputProps={{
													startAdornment: <div style={{ marginRight: '10px' }}>$</div>,
												}}
												inputProps={{
													min: 0,
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<CustomButton startIcon={<FormatListNumberedRtlOutlinedIcon />} variant="contained" color="primary" type="submit">
												Calcular
											</CustomButton>
										</Grid>
									</Grid>
								</form>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

				{/* RENDERIZAR COMPONENTES HIJOS */}
				{(renderComponentChild === 'list') && <ListPawnAdjustmentSlips open={openComponentChild} onClose={resetComponentChild} />}
				{(renderComponentChild === 'register-access') && <RegisterAccess open={openComponentChild} onClose={resetComponentChild} />}

			</Container>
		</div>
	)
}
