import React, { useEffect, useState } from 'react';

// * IMPORTS HELPERS
import { Global } from '../../../../helpers/Global';
import { useAlert } from '../../../../hooks/useAlert';

// * IMPORTS COMPONENTES
import { UploadFiles } from './helpers/UploadFiles';
import { Notes } from './helpers/Notes';
import { AssignPawnAdjustmentSlips } from './AssignPawnAdjustmentSlips';

// * IMPORTS COMPONENTES RELATIVE TIME DATES
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es-mx';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

// * IMPORT MATERIAL UI - COMPONENTS
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';

// * IMPORT MATERIAL UI - ACCORDION
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// * IMPORT MATERIAL UI - SWITCH
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

// * IMPORT MATERIAL UI - DIALOG
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// * IMPORT MATERIAL UI - ICONS
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import ScreenRotationOutlinedIcon from '@mui/icons-material/ScreenRotationOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import CarRentalOutlinedIcon from '@mui/icons-material/CarRentalOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ToysOutlinedIcon from '@mui/icons-material/ToysOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

export const Pawn = ({ dataClient }) => {

	const [updateContent, setUpdateContent] = useState(false);

	const refreshContent = () => {
		setUpdateContent(true);
	}

	// Hook a ejecutar al iniciar el componente
	useEffect(() => {

		// Asignar title a la apps
		document.title = 'Proceso de Empeño';

		const getProcessPawnClient = async () => {

			// Preparar peticion a API
			const token = localStorage.getItem("token");

			const data = new FormData();
			data.append('idClient', dataClient.id);

			try {

				let request = await fetch(`${Global.url_api}api/auth/get-process-pawn`, {
					method: 'POST',
					headers: {
						"Authorization": `Bearer  ${token.replace(/['"]+/g, '')}`
					},
					body: data,
					redirect: 'follow'
				});

				let response = await request.json();
				return response;

			} catch (error) {
				console.log(error);
				alert(`error client: ${error}`);
			}
		};

		getProcessPawnClient().then(result => {

			if (result.code === "201" && result.status === "success") {

				console.log("@PROCESS_PAWN", result);

				setInformation({
					last_editor: result.data.last_editor,
					created_at: result.data.created_at,
					update_at: result.data.update_at,
					duration: "1 dia"
				});

				setPanels(JSON.parse(result.data.content_panel));
				setSubPanels(JSON.parse(result.data.content_subpanel));
				setSubSubPanels(JSON.parse(result.data.content_subsubpanel));

				// Asignar el id del proceso de empeño
				setIdProcessPawn(result.data.id);

				// Asignar si ya tiene una boleta de empeño asociada
				setIdPawnAdjustmentSlip(result.data.id_pawn_adjustment_slip);

				// Notas
				setNotes({
					length: result.notes.length,
					notes: result.notes,
				});

				// Reiniciar el estado para la actualizacion de contenido
				setUpdateContent(false);
			}
		});


	}, [dataClient, updateContent])

	// * HOOKS
	// Accordion
	const [expanded, setExpanded] = useState(false);
	// Data edit/time process
	const [information, setInformation] = useState({
		last_editor: "",
		created_at: "",
		update_at: "",
		duration: ""
	});
	// Notes
	const [notes, setNotes] = useState({
		length: 0,
		notes: [],
	});
	// Pawn Adjustment Slip
	const [idProcessPawn, setIdProcessPawn] = useState(null);
	const [idPawnAdjustmentSlip, setIdPawnAdjustmentSlip] = useState(null);
	// Switchs
	const [panels, setPanels] = useState({
		panel1: false,
		panel2: false,
		panel3: false,
		panel4: false,
		panel5: false,
		panel6: false,
		panel7: false,
		panel8: false,
		panel9: false,
		panel10: false,
		panel11: false,
		panel12: false,
		panel13: false,
		panel14: false,
		panel15: false,
		panel16: false,
		panel17: false,
		panel18: false,
		panel19: false,
		panel20: false,
	});
	const [subPanels, setSubPanels] = useState({
		panel1a: false,
		panel1b: false,

		panel2a: false,
		panel2b: false,
		panel2c: false,

		panel3a: false,
		panel3b: false,
		panel3c: false,
		panel3d: false,
		panel3e: false,
		panel3f: false,

		panel5a: false,

		panel6a: false,
		panel6b: false,
		panel6c: false,
		panel6d: false,
		panel6e: false,

		panel7a: false,
		panel7b: false,
		panel7c: false,
		panel7d: false,
		panel7e: false,
		panel7f: false,
		panel7g: false,
		panel7h: false,

		panel8a: false,

		panel9a: false,
		panel9b: false,
		panel9c: false,
		panel9d: false,

		panel10a: false,
		panel10b: false,
		panel10c: false,
		panel10d: false,
		panel10e: false,
		panel10f: false,
		panel10g: false,
		panel10h: false,

		panel11a: false,
		panel11b: false,
		panel11c: false,
		panel11d: false,

		panel12a: false,
		panel12b: false,
		panel12c: false,
		panel12d: false,

		panel13a: false,
		panel13b: false,

		panel14a: false,
		panel14b: false,
		panel14c: false,
		panel14d: false,
		panel14e: false,
		panel14f: false,

		panel15a: false,
		panel15b: false,
		panel15c: false,
		panel15d: false,
		panel15e: false,

		panel16a: false,
		panel16b: false,
		panel16c: false,
		panel16d: false,
		panel16e: false,

		panel17a: false,
		panel17b: false,
		panel17c: false,
		panel17d: false,

		panel18a: false,
		panel18b: false,
		panel18c: false,
		panel18d: false,

		panel19a: false,
		panel19b: false,
		panel19c: false,
		panel19d: false,
		panel19e: false,

		panel20a: false,
		panel20b: false,
		panel20c: false,
		panel20d: false,
	});
	const [subSubPanels, setSubSubPanels] = useState({

		panel3b1: false,
		panel3b2: false,
		panel3b3: false,
		panel3b4: false,
		panel3b5: false,
		panel3b6: false,
		panel3c1: false,
		panel3c2: false,
		panel3c3: false,
		panel3c4: false,
		panel3d1: false,
		panel3d2: false,
		panel3e1: false,
		panel3e2: false,
		panel3f1: false,
		panel3f2: false,

		panel5a1: false,
		panel5a2: false,
		panel5a3: false,
		panel5a4: false,
		panel5a5: false,
		panel5a6: false,

		panel9b1: false,
		panel9b2: false,
		panel9b3: false,
		panel9b4: false,
		panel9c1: false,
		panel9c2: false,
		panel9c3: false,
		panel9c4: false,
		panel9c5: false,
		panel9c6: false,
		panel9c7: false,
		panel9c8: false,
		panel9d1: false,
		panel9d2: false,
		panel9d3: false,
		panel9d4: false,
		panel9d5: false,
		panel9d6: false,
		panel9d7: false,
		panel9d8: false,
		panel9d9: false,

		panel13b1: false,
		panel13b2: false,
		panel13b3: false,
		panel13b4: false,
		panel13b5: false,
	});
	// General state upload/view files
	const [dialog, setDialog] = useState({
		process: '',
		panel: '',
		file: '',
		action: ''
	});
	// Options props sx - actions upload/view files
	const optionsActions = {
		marginLeft: '10px',
		cursor: 'pointer'
	}
	// Validation inputs
	const [isValidate, setIsValidate] = useState(false);
	// Modal
	const [open, setOpen] = useState(false);
	// Buttons
	const [disabledButton, setDisabledButton] = useState(false);
	// Progress
	const [isLoading, setIsLoading] = useState(false);
	// Alerts Message
	const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

	const openAccordion = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const changePanel = (event) => {
		setPanels({
			...panels,
			[event.target.name]: event.target.checked,
		});
	};

	const changeSubPanel = (event) => {
		setSubPanels({
			...subPanels,
			[event.target.name]: event.target.checked,
		});
	};

	const changeSubSubPanel = (event) => {
		setSubSubPanels({
			...subSubPanels,
			[event.target.name]: event.target.checked,
		});
	};

	// * FUNCTIONS
	const submitProgress = async (event) => {

		event.preventDefault();

		// Desactivar boton de "Actualizar"
		setDisabledButton(true);

		// Activar loader
		setIsLoading(true);

		// Conseguir el token de usuario
		const token = localStorage.getItem("token");

		// Conseguir el nombre del editor
		const editor = JSON.parse(localStorage.getItem("user"));

		let data = new FormData();
		data.append('idUser', dataClient.id);
		data.append('lastEditor', `${editor.name} ${editor.middle_name} ${editor.last_name}`);
		data.append('content_panel', JSON.stringify(panels));
		data.append('content_subpanel', JSON.stringify(subPanels));
		data.append('content_subsubpanel', JSON.stringify(subSubPanels));

		try {

			let request = await fetch(`${Global.url_api}api/auth/upsert-process-pawn`, {
				method: 'POST',
				headers: {
					"Authorization": `Bearer  ${token}`
				},
				body: data,
				redirect: 'follow'
			});

			let { code, message, status } = await request.json();

			if (code === "201" && status === "success") {
				showStatusMessage('¡Proceso de empeño actualizado correctamente!', 'success');

				let data = new FormData();
				data.append('idClient', dataClient.id);

				// Actualizar estado de informacion
				let request = await fetch(`${Global.url_api}api/auth/get-process-pawn`, {
					method: 'POST',
					headers: {
						"Authorization": `Bearer  ${token.replace(/['"]+/g, '')}`
					},
					body: data,
					redirect: 'follow'
				});

				let response = await request.json();

				if (response.code === "201" && response.status === "success") {
					setInformation({
						last_editor: response.data.last_editor,
						created_at: response.data.created_at,
						update_at: response.data.update_at,
						duration: "1 dia"
					});
				}

			} else {
				showStatusMessage(`code: ${code} - message: ${message}`, 'warning');
			}

		} catch (error) {
			console.log(error);
			showStatusMessage(`error client: ${error}`, 'error');
		}

		// Desactivar loader
		setIsLoading(false);

		// Activar boton de "Actualizar"
		setDisabledButton(false);
	}

	const addNote = async (event) => {

		event.preventDefault();

		setIsValidate(false);

		if (event.target.note.value === '') {
			setIsValidate(true);
			return false;
		}

		// Desactivar boton de "Actualizar"
		setDisabledButton(true);

		// Activar loader
		setIsLoading(true);

		// Conseguir el token de usuario
		const token = localStorage.getItem("token");

		// Conseguir el nombre del editor
		const editor = JSON.parse(localStorage.getItem("user"));

		let data = {
			idUser: dataClient.id,
			lastEditor: `${editor.name} ${editor.middle_name} ${editor.last_name}`,
			module: 'backoffice',
			submodule: 'processes-pawn',
			note: event.target.note.value,
		}

		try {
			let request = await fetch(`${Global.url_api}api/note/add-note`, {
				method: 'POST',
				headers: {
					"Authorization": `Bearer  ${token}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data),
				redirect: 'follow'
			});

			let { code, message, status } = await request.json();

			if (code === "201" && status === "success") {
				showStatusMessage('¡Nota agregada correctamente!', 'success');

				let data = new FormData();
				data.append('idClient', dataClient.id);

				// Actualizar estado de notas
				let request = await fetch(`${Global.url_api}api/auth/get-process-pawn`, {
					method: 'POST',
					headers: {
						"Authorization": `Bearer  ${token.replace(/['"]+/g, '')}`
					},
					body: data,
					redirect: 'follow'
				});

				let response = await request.json();

				if (response.code === "201" && response.status === "success") {
					// Asignar las notas al estado
					setNotes({
						length: response.notes.length,
						notes: response.notes,
					});
				}

			} else {
				showStatusMessage(`message: ${message}`, 'warning');
			}

		} catch (error) {
			console.log(error);
			showStatusMessage(`error client: ${error}`, 'error');
		}

		// Desactivar loader
		setIsLoading(false);

		// Activar boton de "Actualizar"
		setDisabledButton(false);

	}

	const viewNotes = () => {

		// Abrir modal
		setOpen(true);

		// Asignar valores del archivo a subir
		setDialog({
			process: 'endeavor',
			panel: '',
			file: '',
			action: 'notes'
		});
	}

	const uploadDocument = (event, panel, file) => {

		// Abrir modal
		setOpen(true);

		// Asignar valores para abrir modal y del archivo a subir
		setDialog({
			process: 'endeavor',
			panel: panel,
			file: file,
			action: 'upload'
		});
	}

	const viewDocument = (event, panel, file) => {
		// Abrir modal
		setOpen(true);

		// Asignar valores para abrir el modal y del archivo a ver
		setDialog({
			process: 'endeavor',
			panel: panel,
			file: file,
			action: 'view'
		});
	}

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Dialog
				fullWidth={true}
				maxWidth='md'
				open={open}
				onClose={handleClose}
			>
				<DialogTitle></DialogTitle>
				<DialogContent sx={{ backgroundColor: "#eee" }}>
					{(dialog.action === 'upload') &&
						<UploadFiles process={dialog.process} panel={dialog.panel} file={dialog.file} />
					}

					{(dialog.action === 'view') &&
						<UploadFiles process={dialog.process} panel={dialog.panel} file={dialog.file} />
					}

					{(dialog.action === 'notes') &&
						<Notes notes={notes} />
					}


				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cerrar</Button>
				</DialogActions>
			</Dialog>

			<Container maxWidth="lg">

				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Typography variant="h5" sx={{ mt: 5, mb: 5 }} gutterBottom>
							Proceso de Empeño
						</Typography>
					</Grid>
					<Grid item xs={12} md={6}>
						<AssignPawnAdjustmentSlips idProcessPawn={idProcessPawn} idPawnAdjustmentSlip={idPawnAdjustmentSlip} refreshContent={refreshContent} />
					</Grid>
				</Grid>

				<Grid container spacing={1}>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={2}
						>
							<Paper
								sx={{
									p: 2,
									margin: 'auto',
									marginBottom: '1em',
									maxWidth: '90%',
									minHeight: '140px',
									width: '90%',
									flexGrow: 1,
									backgroundColor: (theme) =>
										theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
								}}
							>
								<Grid container spacing={2}>
									<Grid item xs={12} sm container>
										<Grid item xs container direction="column" spacing={2}>
											<Grid item xs>
												<Typography gutterBottom variant="subtitle1" component="div">
													<strong>Última persona en editar:</strong>
												</Typography>
												<Typography variant="body2" gutterBottom>
													{information.last_editor}
												</Typography>
											</Grid>
											<Grid item>
												<Typography sx={{ cursor: 'pointer' }} variant="body2">
													<Button onClick={event => submitProgress(event)} variant="outlined" disabled={disabledButton} startIcon={<UpdateOutlinedIcon />} >Actualizar</Button>
												</Typography>
											</Grid>
										</Grid>
										<Grid item>
											<Typography variant="subtitle1" component="div">
												<strong>Inicio: </strong>
												<Moment locale="es-mx" format="D MMM YYYY" withTitle>
													{information.created_at}
												</Moment>
											</Typography>
											<Typography variant="subtitle1" component="div">
												<strong>Última actualización: </strong>
												<Moment locale="es-mx" format="D MMM YYYY" withTitle>
													{information.update_at}
												</Moment>
											</Typography>
											<Typography variant="subtitle1" component="div">
												<strong>Duración: </strong>
												<Moment locale="es-mx" duration={information.created_at}
													date={information.update_at}
												/>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={2}
						>
							<Paper
								sx={{
									p: 2,
									margin: 'auto',
									marginBottom: '1em',
									maxWidth: '90%',
									minHeight: '140px',
									width: '90%',
									flexGrow: 1,
									backgroundColor: (theme) =>
										theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
								}}
							>
								<Grid container justifyContent='center'>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Stack spacing={4} direction="row" justifyContent="flex-end" alignItems="center" sx={{ color: 'action.active' }}>
											<Tooltip title="Ver Notas">
												<Badge onClick={viewNotes} className='cursor-pointer' color='error' badgeContent={notes.length}>
													<DescriptionRoundedIcon sx={{ color: '#3fd69e' }} />
												</Badge>
											</Tooltip>
										</Stack>
									</Grid>
									<Grid item sx={{ width: '90%' }}>
										<form onSubmit={addNote}>
											<TextField
												fullWidth
												size="small"
												id="outlined-multiline-flexible"
												label="Notas"
												multiline
												maxRows={4}
												name='note'
												error={isValidate}
												helperText={isValidate ? 'Agrega una nota' : null}
											/>
											<Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
												<Grid item xs={12}>
													<Button type='submit' variant="outlined" disabled={disabledButton} startIcon={<EditNoteOutlinedIcon />} >Agregar</Button>
												</Grid>
											</Grid>
										</form>
									</Grid>
								</Grid>
							</Paper>
						</Stack>
					</Grid>
				</Grid>

				<Box mt={2} mb={4}>
					{isLoading &&
						<Box mt={3} sx={{ width: '100%' }}>
							<LinearProgress />
						</Box>
					}
				</Box>

				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: '#f3f6f9' }}>
						<FormControl component="fieldset" variant="standard" sx={{ width: '100%' }}>
							<FormGroup>

								<Accordion expanded={expanded === 'panel1'} onChange={openAccordion('panel1')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1bh-content"
										id="panel1bh-header"
									>
										<Switch checked={panels.panel1} onChange={changePanel} name="panel1" color="success" size="small" />
										<PhoneForwardedOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											1. Contactar al lead
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel1a} onChange={changeSubPanel} name="panel1a" color="success" size="small" />
											a. Obtener información hubspot
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel1b} onChange={changeSubPanel} name="panel1b" color="success" size="small" />
											b. Realizar llamada, envió de mensaje WA, mensaje de texto y/o enviar email
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel2'} onChange={openAccordion('panel2')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel2bh-content"
										id="panel2bh-header"
									>
										<Switch checked={panels.panel2} onChange={changePanel} name="panel2" color="success" size="small" />
										<ConnectWithoutContactOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>2. Contacto con el cliente y envió de cotización</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel2a} onChange={changeSubPanel} name="panel2a" color="success" size="small" />
											a. Obtener información del auto
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel2b} onChange={changeSubPanel} name="panel2b" color="success" size="small" />
											b. Envió de cotización
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel2c} onChange={changeSubPanel} name="panel2c" color="success" size="small" />
											c. Llamada para explicar proceso del préstamo
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel3'} onChange={openAccordion('panel3')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel3bh-content"
										id="panel3bh-header"
									>
										<Switch checked={panels.panel3} onChange={changePanel} name="panel3" color="success" size="small" />
										<FilePresentOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											3. Solicitar información
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel3a} onChange={changeSubPanel} name="panel3a" color="success" size="small" />
											a. Factura/refactura (acreditación de propiedad)
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel3', 'factura_refactura')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel3', 'factura_refactura')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel3b} onChange={changeSubPanel} name="panel3b" color="success" size="small" />
											b. Comprobante de domicilio a nombre del cliente
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel3', 'comprobante_domicilio')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel3', 'comprobante_domicilio')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3b1} onChange={changeSubSubPanel} name="panel3b1" color="success" size="small" />
											i. Luz
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3b2} onChange={changeSubSubPanel} name="panel3b2" color="success" size="small" />
											ii. Agua
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3b3} onChange={changeSubSubPanel} name="panel3b3" color="success" size="small" />
											iii. Predial
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3b4} onChange={changeSubSubPanel} name="panel3b4" color="success" size="small" />
											iv. Teléfono (excepto de telefonía móvil)
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3b5} onChange={changeSubSubPanel} name="panel3b5" color="success" size="small" />
											v. Internet
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3b6} onChange={changeSubSubPanel} name="panel3b6" color="success" size="small" />
											vi. Tres últimos estados de cuenta del domicilio actual
										</Typography>

										<Typography ml={5}>
											<Switch checked={subPanels.panel3c} onChange={changeSubPanel} name="panel3c" color="success" size="small" />
											c. Comprobante de domicilio a nombre de terceros
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel3', 'comprobante_domicilio_terceros')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel3', 'comprobante_domicilio_terceros')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3c1} onChange={changeSubSubPanel} name="panel3c1" color="success" size="small" />
											i. Familiar (acta de nacimiento, acta de matrimonio)
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3c2} onChange={changeSubSubPanel} name="panel3c2" color="success" size="small" />
											ii. Rentado (contrato de arrendamiento)
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3c3} onChange={changeSubSubPanel} name="panel3c3" color="success" size="small" />
											iii. Recibo de nomina CFDI
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3c4} onChange={changeSubSubPanel} name="panel3c4" color="success" size="small" />
											iv. Otros
										</Typography>

										<Typography ml={5}>
											<Switch checked={subPanels.panel3d} onChange={changeSubPanel} name="panel3d" color="success" size="small" />
											d. Tarjeta circulación a nombre del cliente
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel3', 'tarjeta_circulacion')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel3', 'tarjeta_circulacion')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3d1} onChange={changeSubSubPanel} name="panel3d1" color="success" size="small" />
											i. Vigente
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3d2} onChange={changeSubSubPanel} name="panel3d2" color="success" size="small" />
											ii. Pago del refrendo actualizado
										</Typography>

										<Typography ml={5}>
											<Switch checked={subPanels.panel3e} onChange={changeSubPanel} name="panel3e" color="success" size="small" />
											e. Tarjeta de circulación de tercero
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel3', 'tarjeta_circulacion_terceros')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel3', 'tarjeta_circulacion_terceros')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3e1} onChange={changeSubSubPanel} name="panel3e1" color="success" size="small" />
											i. Identificación de dueño anterior
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3e2} onChange={changeSubSubPanel} name="panel3e2" color="success" size="small" />
											ii. Contrato de compra venta o carta responsiva
										</Typography>

										<Typography ml={5}>
											<Switch checked={subPanels.panel3f} onChange={changeSubPanel} name="panel3f" color="success" size="small" />
											f. Identificación oficial
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel3', 'identificacion')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel3', 'identificacion')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3f1} onChange={changeSubSubPanel} name="panel3f1" color="success" size="small" />
											i. INE
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel3f2} onChange={changeSubSubPanel} name="panel3f2" color="success" size="small" />
											ii. Pasaporte
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel4'} onChange={openAccordion('panel4')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel4bh-content"
										id="panel4bh-header"
									>
										<Switch checked={panels.panel4} onChange={changePanel} name="panel4" color="success" size="small" />
										<ScreenRotationOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											4. Turnar documentación digital a mesa de control
										</Typography>
									</AccordionSummary>
									<AccordionDetails></AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel5'} onChange={openAccordion('panel5')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel5bh-content"
										id="panel5bh-header"
									>
										<Switch checked={panels.panel5} onChange={changePanel} name="panel5" color="success" size="small" />
										<EventOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											5. Revisión y validación legal de la documentación
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel5a} onChange={changeSubPanel} name="panel5a" color="success" size="small" />
											a. Agendar visitas
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel5a1} onChange={changeSubSubPanel} name="panel5a1" color="success" size="small" />
											i. Validar facturas en el SAT
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel5', 'factura_sat')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel5', 'factura_sat')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel5a2} onChange={changeSubSubPanel} name="panel5a2" color="success" size="small" />
											ii. Validar vigencia de INE
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel5', 'vigencia_ine')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel5', 'vigencia_ine')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel5a3} onChange={changeSubSubPanel} name="panel5a3" color="success" size="small" />
											iii. REPUVE
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel5', 'repuve')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel5', 'repuve')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel5a4} onChange={changeSubSubPanel} name="panel5a4" color="success" size="small" />
											iv. RAPI
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel5', 'rappi')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel5', 'rappi')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel5a5} onChange={changeSubSubPanel} name="panel5a5" color="success" size="small" />
											v. Trans Union
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel5', 'trans_union')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel5', 'trans_union')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel5a6} onChange={changeSubSubPanel} name="panel5a6" color="success" size="small" />
											vi. Buho legal
											{/* Subir documento */}
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel5', 'buho_legal')} fontSize='small' color='secondary' sx={optionsActions} />
											{/* Ver documento */}
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel5', 'buho_legal')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel6'} onChange={openAccordion('panel6')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel6bh-content"
										id="panel6bh-header"
									>
										<Switch checked={panels.panel6} onChange={changePanel} name="panel6" color="success" size="small" />
										<HomeWorkOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											6. Realizar visita domiciliara
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel6a} onChange={changeSubPanel} name="panel6a" color="success" size="small" />
											a. Solicitud de empeño
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel6', 'solicitud_empenio')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel6', 'solicitud_empenio')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel6b} onChange={changeSubPanel} name="panel6b" color="success" size="small" />
											b. Envió de información a mesa de control
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel6', 'envio_informacion')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel6', 'envio_informacion')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel6c} onChange={changeSubPanel} name="panel6c" color="success" size="small" />
											c. Diagnostico de visita domiciliara
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel6', 'diagnostico_visita')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel6', 'diagnostico_visita')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel6d} onChange={changeSubPanel} name="panel6d" color="success" size="small" />
											d. Viable para préstamo
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel6', 'viable_prestamo')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel6', 'viable_prestamo')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel6e} onChange={changeSubPanel} name="panel6e" color="success" size="small" />
											e. Declinar proceso
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel7'} onChange={openAccordion('panel7')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel7bh-content"
										id="panel7bh-header"
									>
										<Switch checked={panels.panel7} onChange={changePanel} name="panel7" color="success" size="small" />
										<PriceCheckOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											7. Análisis y viabilidad de crédito
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel7a} onChange={changeSubPanel} name="panel7a" color="success" size="small" />
											a. Enviar facturas a agencias
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel7b} onChange={changeSubPanel} name="panel7b" color="success" size="small" />
											b. Consultar NSS e IMSS
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel7c} onChange={changeSubPanel} name="panel7c" color="success" size="small" />
											c. Validar vigencia de tarjeta de circulación
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel7', 'vigencia_tc')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel7', 'vigencia_tc')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel7d} onChange={changeSubPanel} name="panel7d" color="success" size="small" />
											d. Pago de tenencias
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel7', 'pago_tenencias')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel7', 'pago_tenencias')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel7e} onChange={changeSubPanel} name="panel7e" color="success" size="small" />
											e. Histórico laboral
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel7', 'historico_laboral')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel7', 'historico_laboral')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel7f} onChange={changeSubPanel} name="panel7f" color="success" size="small" />
											f. Infracciones
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel7', 'infracciones')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel7', 'infracciones')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel7g} onChange={changeSubPanel} name="panel7g" color="success" size="small" />
											g. AMIS (Seguro automóvil)
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel7h} onChange={changeSubPanel} name="panel7h" color="success" size="small" />
											h. Visto bueno de mesa de control
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel8'} onChange={openAccordion('panel8')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel8bh-content"
										id="panel8bh-header"
									>
										<Switch checked={panels.panel8} onChange={changePanel} name="panel8" color="success" size="small" />
										<EventAvailableOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											8. Agendar revisión física-mecánica
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											a. Calendario (restricción de horario)
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel9'} onChange={openAccordion('panel9')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel9bh-content"
										id="panel9bh-header"
									>
										<Switch checked={panels.panel9} onChange={changePanel} name="panel9" color="success" size="small" />
										<ConstructionOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											9. Realizar revisión física-mecánica
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel9a} onChange={changeSubPanel} name="panel9a" color="success" size="small" />
											a. Explicar proceso de la revisión al cliente
										</Typography>

										<Typography ml={5}>
											<Switch checked={subPanels.panel9b} onChange={changeSubPanel} name="panel9b" color="success" size="small" />
											b. Realizar prueba de manejo del vehículo
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9b1} onChange={changeSubSubPanel} name="panel9b1" color="success" size="small" />
											i. Verificar motor
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9b2} onChange={changeSubSubPanel} name="panel9b2" color="success" size="small" />
											ii. Verificar dirección
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9b3} onChange={changeSubSubPanel} name="panel9b3" color="success" size="small" />
											iii. Verificar suspensión
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9b4} onChange={changeSubSubPanel} name="panel9b4" color="success" size="small" />
											iv. Verificar frenos
										</Typography>

										<Typography ml={5}>
											<Switch checked={subPanels.panel9c} onChange={changeSubPanel} name="panel9c" color="success" size="small" />
											c. Diagnostico (scanner)
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9c1} onChange={changeSubSubPanel} name="panel9c1" color="success" size="small" />
											i. Identificación vehicular VIN
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9c2} onChange={changeSubSubPanel} name="panel9c2" color="success" size="small" />
											ii. Prueba de voltaje OBD2
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9c3} onChange={changeSubSubPanel} name="panel9c3" color="success" size="small" />
											iii. Scanner de la computadora
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9c4} onChange={changeSubSubPanel} name="panel9c4" color="success" size="small" />
											iv. Interpretar códigos de error
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9c5} onChange={changeSubSubPanel} name="panel9c5" color="success" size="small" />
											v. Validar kilometraje
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9c6} onChange={changeSubSubPanel} name="panel9c6" color="success" size="small" />
											vi. Validar VIN
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9c7} onChange={changeSubSubPanel} name="panel9c7" color="success" size="small" />
											vii. Validar versión y modelo
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9c8} onChange={changeSubSubPanel} name="panel9c8" color="success" size="small" />
											viii. Generar y subir el informe de diagnostico
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel9', 'informe_diagnostico')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel9', 'informe_diagnostico')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>

										<Typography ml={5}>
											<Switch checked={subPanels.panel9d} onChange={changeSubPanel} name="panel9d" color="success" size="small" />
											d. Llenado de ckecklist
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9d1} onChange={changeSubSubPanel} name="panel9d1" color="success" size="small" />
											i. Revisión de motor
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9d2} onChange={changeSubSubPanel} name="panel9d2" color="success" size="small" />
											ii. Sistema eléctrico
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9d3} onChange={changeSubSubPanel} name="panel9d3" color="success" size="small" />
											iii. Sistema mecánico
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9d4} onChange={changeSubSubPanel} name="panel9d4" color="success" size="small" />
											iv. Carrocería
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9d5} onChange={changeSubSubPanel} name="panel9d5" color="success" size="small" />
											v. Neumáticos
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9d6} onChange={changeSubSubPanel} name="panel9d6" color="success" size="small" />
											vi. Tapicería
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9d7} onChange={changeSubSubPanel} name="panel9d7" color="success" size="small" />
											vii. Cristales
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9d8} onChange={changeSubSubPanel} name="panel9d8" color="success" size="small" />
											viii. Conclusión y comentarios
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel9d9} onChange={changeSubSubPanel} name="panel9d9" color="success" size="small" />
											ix. Subir checklist
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel9', 'checklist')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel9', 'checklist')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel10'} onChange={openAccordion('panel10')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel10bh-content"
										id="panel10bh-header"
									>
										<Switch checked={panels.panel10} onChange={changePanel} name="panel10" color="success" size="small" />
										<CreditScoreOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											10. Definición de la viabilidad y ajustes del monto de crédito
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel10a} onChange={changeSubPanel} name="panel10a" color="success" size="small" />
											a. Ajuste por scanner
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel10b} onChange={changeSubPanel} name="panel10b" color="success" size="small" />
											b. Ajuste por revisión física
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel10c} onChange={changeSubPanel} name="panel10c" color="success" size="small" />
											c. Ajuste por revisión motor
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel10d} onChange={changeSubPanel} name="panel10d" color="success" size="small" />
											d. Ajuste por kilometraje
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel10e} onChange={changeSubPanel} name="panel10e" color="success" size="small" />
											e. Ajuste por tenencias/multas e infracciones
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel10f} onChange={changeSubPanel} name="panel10f" color="success" size="small" />
											f. Ajuste por tramites
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel10g} onChange={changeSubPanel} name="panel10g" color="success" size="small" />
											g. Ajustes por factura de aseguradoras o reporte de robo
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel10h} onChange={changeSubPanel} name="panel10h" color="success" size="small" />
											h. Formato de ajustes
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel10', 'formato_ajustes')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel10', 'formato_ajustes')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel11'} onChange={openAccordion('panel11')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel11bh-content"
										id="panel11bh-header"
									>
										<Switch checked={panels.panel11} onChange={changePanel} name="panel11" color="success" size="small" />
										<MonetizationOnOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											11. Informar al cliente del monto de crédito
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel11a} onChange={changeSubPanel} name="panel11a" color="success" size="small" />
											a. Elaborar nueva cotización
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel11b} onChange={changeSubPanel} name="panel11b" color="success" size="small" />
											b. Enviar nueva cotización al cliente
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel11', 'cotizacion_cliente')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel11', 'cotizacion_cliente')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel11c} onChange={changeSubPanel} name="panel11c" color="success" size="small" />
											c. Aceptación o rechazo de la cotización
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel11', 'estatus_cotizacion')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel11', 'estatus_cotizacion')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel11d} onChange={changeSubPanel} name="panel11d" color="success" size="small" />
											d. Solicitar estado de cuenta bancaria
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel11', 'cuenta_bancaria')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel11', 'cuenta_bancaria')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel12'} onChange={openAccordion('panel12')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel12bh-content"
										id="panel12bh-header"
									>
										<Switch checked={panels.panel12} onChange={changePanel} name="panel12" color="success" size="small" />
										<EditCalendarOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											12. Agendar cita para firma de contrato
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel12a} onChange={changeSubPanel} name="panel12a" color="success" size="small" />
											a. Calendario (horario restringido)
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel12b} onChange={changeSubPanel} name="panel12b" color="success" size="small" />
											b. Elaboración de contrato y documentos para firma
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel12c} onChange={changeSubPanel} name="panel12c" color="success" size="small" />
											c. Se agenda colocación de GPS con proveedor
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel12d} onChange={changeSubPanel} name="panel12d" color="success" size="small" />
											d. Alta de cuenta bancaria del cliente en el sistema de Spingo
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel13'} onChange={openAccordion('panel13')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel13bh-content"
										id="panel13bh-header"
									>
										<Switch checked={panels.panel13} onChange={changePanel} name="panel13" color="success" size="small" />
										<EditNoteOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											13. Firma de contratos y documentos
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel13a} onChange={changeSubPanel} name="panel13a" color="success" size="small" />
											a. Explicar al cliente e iniciar proceso de firmas
										</Typography>

										<Typography ml={5}>
											<Switch checked={subPanels.panel13b} onChange={changeSubPanel} name="panel13b" color="success" size="small" />
											b. Firma de contratos
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel13', 'firma_contratos')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel13', 'firma_contratos')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel13b1} onChange={changeSubSubPanel} name="panel13b1" color="success" size="small" />
											i. Nombre completo de puño y letra del cliente
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel13b2} onChange={changeSubSubPanel} name="panel13b2" color="success" size="small" />
											ii. Firma autógrafa del cliente cotejada contra la firma del INE
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel13b3} onChange={changeSubSubPanel} name="panel13b3" color="success" size="small" />
											iii. Huella digital del cliente
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel13b4} onChange={changeSubSubPanel} name="panel13b4" color="success" size="small" />
											iv. En caso de inconsistencias en firma, el cliente deberá ratificar en puño y letra que es su firma autógrafa
										</Typography>
										<Typography ml={10}>
											<Switch checked={subSubPanels.panel13b5} onChange={changeSubSubPanel} name="panel13b5" color="success" size="small" />
											v. Tanto en la firma del contrato como de documentos se deberá sacar foto y subir como evidencia de dicho proceso
										</Typography>

									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel14'} onChange={openAccordion('panel14')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel14bh-content"
										id="panel14bh-header"
									>
										<Switch checked={panels.panel14} onChange={changePanel} name="panel14" color="success" size="small" />
										<RoomOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											14. Instalación de GPS
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel14a} onChange={changeSubPanel} name="panel14a" color="success" size="small" />
											a. Entrega de unidad a técnico bajo la supervisión de personal de Spingo
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel14b} onChange={changeSubPanel} name="panel14b" color="success" size="small" />
											b. Instalación de equipo GPS fijo a la computadora
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel14c} onChange={changeSubPanel} name="panel14c" color="success" size="small" />
											c. Instalación de equipo GPS portátil en carrocería
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel14d} onChange={changeSubPanel} name="panel14d" color="success" size="small" />
											d. Proveedor verifica que los equipos instalados están reportando en su plataforma
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel14e} onChange={changeSubPanel} name="panel14e" color="success" size="small" />
											e. Personal de Spingo ratifica que los equipos GPS estén reportando correctamente y que la instalación fue correcta
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel14f} onChange={changeSubPanel} name="panel14f" color="success" size="small" />
											f. Subir evidencia de GPS reportando
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel14', 'gps_reportando')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel14', 'gps_reportando')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel15'} onChange={openAccordion('panel15')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel15bh-content"
										id="panel15bh-header"
									>
										<Switch checked={panels.panel15} onChange={changePanel} name="panel15" color="success" size="small" />
										<CarRentalOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											15. Recepción de documentos que acreditan la propiedad del vehículo
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel15a} onChange={changeSubPanel} name="panel15a" color="success" size="small" />
											a. Recepción de factura original, refacturas y/o endosos
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel15b} onChange={changeSubPanel} name="panel15b" color="success" size="small" />
											b. Endoso del cliente a favor de Spingo
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel15c} onChange={changeSubPanel} name="panel15c" color="success" size="small" />
											c. Duplicado de llave
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel15d} onChange={changeSubPanel} name="panel15d" color="success" size="small" />
											d. Tenencias vehiculares
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel15e} onChange={changeSubPanel} name="panel15e" color="success" size="small" />
											e. Documentación en custodia en bóveda
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel15', 'documentacion_boveda')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel15', 'documentacion_boveda')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel16'} onChange={openAccordion('panel16')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel16bh-content"
										id="panel16bh-header"
									>
										<Switch checked={panels.panel16} onChange={changePanel} name="panel16" color="success" size="small" />
										<CurrencyExchangeOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											16. Transferencia bancaria a la cuenta del cliente
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel16a} onChange={changeSubPanel} name="panel16a" color="success" size="small" />
											a. Contar con evidencia de que el GPS reporta
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel16b} onChange={changeSubPanel} name="panel16b" color="success" size="small" />
											b. Contar con evidencia de contrato y documentos firmados
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel16c} onChange={changeSubPanel} name="panel16c" color="success" size="small" />
											c. Mesa de control y comercial solicitan a tesorería por WhatsApp la transferencia del préstamo
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel16d} onChange={changeSubPanel} name="panel16d" color="success" size="small" />
											d. Tesorería realiza transferencia a la cuenta bancaria del cliente
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel16e} onChange={changeSubPanel} name="panel16e" color="success" size="small" />
											e. Tesorería envía evidencia a mesa de control y comercial de la transferencia realizada
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel16', 'transferencia_realizada')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel16', 'transferencia_realizada')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel17'} onChange={openAccordion('panel17')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel17bh-content"
										id="panel17bh-header"
									>
										<Switch checked={panels.panel17} onChange={changePanel} name="panel17" color="success" size="small" />
										<PublishedWithChangesOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											17. Validación de la transferencia en la cuenta del cliente
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel17a} onChange={changeSubPanel} name="panel17a" color="success" size="small" />
											a. Comercial indica al cliente que la transferencia fue realizada a su cuenta
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel17b} onChange={changeSubPanel} name="panel17b" color="success" size="small" />
											b. Cliente valida en su app bancaria que el deposito esta en su cuenta
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel17c} onChange={changeSubPanel} name="panel17c" color="success" size="small" />
											c. Cliente envía evidencia del deposito en su cuenta al área comercial
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel17', 'evidencia_cliente')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel17', 'evidencia_cliente')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel17d} onChange={changeSubPanel} name="panel17d" color="success" size="small" />
											d. Área comercial envía evidencia a tesorería de la transferencia en la cuenta del cliente
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel18'} onChange={openAccordion('panel18')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel18bh-content"
										id="panel18bh-header"
									>
										<Switch checked={panels.panel18} onChange={changePanel} name="panel18" color="success" size="small" />
										<PaymentsOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											18. Pago de gastos operativos por parte del cliente
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel18a} onChange={changeSubPanel} name="panel18a" color="success" size="small" />
											a. Área comercial solicita transferencia a la cuenta de Spingo de los gastos operativos
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel18b} onChange={changeSubPanel} name="panel18b" color="success" size="small" />
											b. Área comercial solicita evidencia de la transferencia realizada
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel18c} onChange={changeSubPanel} name="panel18c" color="success" size="small" />
											c. Área comercial envía a tesorería la evidencia de pago de los gastos operativos
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel18', 'evidencia_gastos')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel18', 'evidencia_gastos')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel18d} onChange={changeSubPanel} name="panel18d" color="success" size="small" />
											d. Tesorería valida en la cuenta de Spingo el deposito de dichos gastos
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel19'} onChange={openAccordion('panel19')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel19bh-content"
										id="panel19bh-header"
									>
										<Switch checked={panels.panel19} onChange={changePanel} name="panel19" color="success" size="small" />
										<ToysOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											19. Entrega del vehículo al cliente
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel19a} onChange={changeSubPanel} name="panel19a" color="success" size="small" />
											a. Área comercial entrega copia de los documentos y contratos firmados
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel19b} onChange={changeSubPanel} name="panel19b" color="success" size="small" />
											b. Mesa de control entrega duplicado de llaves
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel19c} onChange={changeSubPanel} name="panel19c" color="success" size="small" />
											c. Mesa de control entrega vehículo en sitio
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel19d} onChange={changeSubPanel} name="panel19d" color="success" size="small" />
											d. Cliente recibe vehículo y verifica que este funcione perfectamente
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel19e} onChange={changeSubPanel} name="panel19e" color="success" size="small" />
											e. Cliente se retira
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded={expanded === 'panel20'} onChange={openAccordion('panel20')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel20bh-content"
										id="panel20bh-header"
									>
										<Switch checked={panels.panel20} onChange={changePanel} name="panel20" color="success" size="small" />
										<CarCrashOutlinedIcon fontSize='small' sx={{ marginLeft: '10px', color: '#39d59b' }} />
										<Typography sx={{ width: '100%', flexShrink: 0, marginLeft: '10px' }}>
											20. Recepción de vehículo por extracción
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography ml={5}>
											<Switch checked={subPanels.panel20a} onChange={changeSubPanel} name="panel20a" color="success" size="small" />
											a. Mesa de control recibe auto
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel20b} onChange={changeSubPanel} name="panel20b" color="success" size="small" />
											b. Realiza layout de las condiciones del vehículo
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel20', 'condiciones_vehiculo')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel20', 'condiciones_vehiculo')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel20c} onChange={changeSubPanel} name="panel20c" color="success" size="small" />
											c. Cliente firma carta de entrega voluntaria
											<BackupOutlinedIcon onClick={event => uploadDocument(event, 'panel20', 'carta_entrega')} fontSize='small' color='secondary' sx={optionsActions} />
											<VisibilityOutlinedIcon onClick={event => viewDocument(event, 'panel20', 'carta_entrega')} fontSize='small' color='primary' sx={optionsActions} />
										</Typography>
										<Typography ml={5}>
											<Switch checked={subPanels.panel20d} onChange={changeSubPanel} name="panel20d" color="success" size="small" />
											d. Mesa de control resguarda auto en pensión
										</Typography>
									</AccordionDetails>
								</Accordion>

							</FormGroup>
						</FormControl>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}