import React, { useState, useEffect } from 'react';

// * IMPORTS HELPERS
import { Global } from '../../../helpers/Global';
import { useAlert } from '../../../hooks/useAlert';
import { UploadOnlyDocument } from './UploadOnlyDocument';

// * MEDIA CUSTOM
import imgDefault from '../../../assets/img/general/default.png';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

// * IMPORTS MATERIAl UI - DIALOG
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

// * IMPORTS MATERIAL UI - ICONS
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';

// * MATERIAL UI - SELECT
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

// * MATERIAL UI - ICONBUTTON
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

// * IMPORT MEDIA CUSTOM
import INE from '../../../assets/img/iconos/INE.png';
import Domicilio from '../../../assets/img/iconos/Domicilio.png';
import Tarjeta from '../../../assets/img/iconos/Tarjeta.png';
import Factura from '../../../assets/img/iconos/Factura.png';


export const Documents = (props) => {

	// Props component
	const { dataClient } = props;
	// console.log('@DATA_CLIENT', dataClient);

	// Documents
	const [checkedINE, setCheckedINE] = useState(0);
	const [checkedProofAddress, setCheckedProofAddress] = useState(0);
	const [checkedCirculationCard, setCheckedCirculationCard] = useState(0);
	const [checkedBill, setCheckedBill] = useState(0);
	// Select view document
	const [viewDocument, setViewDocument] = useState('default');
	const [viewDocumentReverse, setViewDocumentReverse] = useState(null);
	const [routeDocument, setRouteDocument] = useState();
	// Upload only document
	const [selectDocument, setSelectDocument] = useState(null);
	// Buttons
	const [disabledButton, setDisabledButton] = useState(false);
	// Progress
	const [progress, setProgress] = useState(false);
	// Modal - Dialog
	const [openDialog, setOpenDialog] = useState(false);
	// Alerts Message
	const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

	useEffect(() => {
		// if (dataClient.validacion_ine === 1) setCheckedINE(true);
		// if (dataClient.validacion_comprobante === 1) setCheckedProofAddress(true);
		// if (dataClient.validacion_tarjeta_circulacion === 1) setCheckedCirculationCard(true);
		// if (dataClient.validacion_factura === 1) setCheckedBill(true);
		setCheckedINE(dataClient.validacion_ine);
		setCheckedProofAddress(dataClient.validacion_comprobante);
		setCheckedCirculationCard(dataClient.validacion_tarjeta_circulacion);
		setCheckedBill(dataClient.validacion_factura);
	}, [dataClient])


	const changeValueDocument = (event, typeDocument) => {

		if (typeDocument === 'INE') {
			setCheckedINE(event.target.value);
		} else if (typeDocument === 'ProofAddress') {
			setCheckedProofAddress(event.target.value);
		} else if (typeDocument === 'CirculationCard') {
			setCheckedCirculationCard(event.target.value);
		} else if (typeDocument === 'Bill') {
			setCheckedBill(event.target.value);
		} else {
			return false;
		}

	};

	const updateDocuments = async (event) => {

		event.preventDefault();
		console.log('ine', checkedINE);
		console.log('domicilio', checkedProofAddress);
		console.log('tarjeta', checkedCirculationCard);
		console.log('factura', checkedBill);
		console.log('');

		const token = localStorage.getItem("token");

		let raw = JSON.stringify({
			"idUser": dataClient.id,
			"ine": checkedINE,
			"proofAddress": checkedProofAddress,
			"circulationCard": checkedCirculationCard,
			"bill": checkedBill,
		});


		let requestOptions = {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer  ${token}`
			},
			body: raw,
			redirect: 'follow'
		};

		try {
			let request = await fetch(`${Global.url_api}api/auth/update-validation-documents`, requestOptions);
			let response = await request.json();

			console.log(response);

			showStatusMessage('Documentos actualizados correctamente', 'success');
		} catch (error) {
			console.log(error);
			showStatusMessage(`Error Server ${error}`, 'error');
		}

	}

	const selectViewDocument = (event, typeDocument) => {

		// Abrir modal para mostrar el documento seleccionado
		clickOpenDialog();

		// Como no todos los documentos tienen reverso, reiniciamos su valor para evitar cargarlo si no aplica el reverso
		setViewDocumentReverse(null);

		if (typeDocument === 'INE') {
			setViewDocument(dataClient.img_ine_frente);
			setViewDocumentReverse(dataClient.img_ine_reverso);
			setRouteDocument('ine');
		} else if (typeDocument === 'ProofAddress') {
			setViewDocument(dataClient.img_comprobante);
			setRouteDocument('comprobante-domicilio');
		} else if (typeDocument === 'CirculationCard') {
			setViewDocument(dataClient.img_tarjeta_circulacion);
			setViewDocumentReverse(dataClient.img_tarjeta_circulacion_reverso);
			setRouteDocument('tarjeta-circulacion');
		} else if (typeDocument === 'Bill') {
			setViewDocument(dataClient.img_factura);
			setViewDocumentReverse(dataClient.img_factura_reverso);
			setRouteDocument('factura');
		} else {
			return false;
		}
	}

	const formatValuetoString = (value) => {
		let mensaje = '';
		switch (value) {
			case 1:
				mensaje = 'Validado';
				break;
			case 2:
				mensaje = 'Guardado · Pendiente de validar';
				break;
			case 3:
				mensaje = 'Rechazado';
				break;
			case 0:
				mensaje = 'Pendiente de subir';
				break;
			default:
				mensaje = 'Revisar valor';
				break;
		}
		return mensaje;
	}

	const downloadDocuments = async (event) => {

		// Desactivar boton de "Descargar"
		setDisabledButton(true);

		// Activar progress
		setProgress(true);

		let { img_ine_frente, img_ine_reverso, img_comprobante, img_tarjeta_circulacion, img_tarjeta_circulacion_reverso, img_factura, img_factura_reverso } = dataClient;

		const link = document.createElement('a');

		if (img_ine_frente !== null && img_ine_frente !== "") {
			link.href = `${Global.url_api}api/download-documents-onboardings/ine/${img_ine_frente}`;
			link.download = img_ine_frente;
			link.click();
		}
		setTimeout(() => {
			if (img_ine_reverso !== null && img_ine_reverso !== "") {
				link.href = `${Global.url_api}api/download-documents-onboardings/ine/${img_ine_reverso}`;
				link.download = img_ine_reverso;
				link.click();
			}
			setTimeout(() => {
				if (img_comprobante !== null && img_comprobante !== "") {
					link.href = `${Global.url_api}api/download-documents-onboardings/comprobante-domicilio/${img_comprobante}`;
					link.download = img_comprobante;
					link.click();
				}
				setTimeout(() => {
					if (img_tarjeta_circulacion !== null && img_tarjeta_circulacion !== "") {
						link.href = `${Global.url_api}api/download-documents-onboardings/tarjeta-circulacion/${img_tarjeta_circulacion}`;
						link.download = img_tarjeta_circulacion;
						link.click();
					}
					setTimeout(() => {
						if (img_tarjeta_circulacion_reverso !== null && img_tarjeta_circulacion_reverso !== "") {
							link.href = `${Global.url_api}api/download-documents-onboardings/tarjeta-circulacion/${img_tarjeta_circulacion_reverso}`;
							link.download = img_tarjeta_circulacion_reverso;
							link.click();
						}
						setTimeout(() => {
							if (img_factura !== null && img_factura !== "") {
								link.href = `${Global.url_api}api/download-documents-onboardings/factura/${img_factura}`;
								link.download = img_factura;
								link.click();
							}
							setTimeout(() => {
								if (img_factura_reverso !== null && img_factura_reverso !== "") {
									link.href = `${Global.url_api}api/download-documents-onboardings/factura/${img_factura_reverso}`;
									link.download = img_factura_reverso;
									link.click();
								}
								// Activar boton de "Descargar"
								setDisabledButton(false);

								// Desactivar progress
								setProgress(false);
							}, 1000);
						}, 1000);
					}, 1000);
				}, 1000);
			}, 1000);
		}, 1000);
	}

	// * DIALOG
	const clickOpenDialog = () => {
		setOpenDialog(true);
	};

	const closeDialog = () => {
		setOpenDialog(false);
	};

	return (
		<div>
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Container maxWidth="lg">

				<Grid container spacing={2}>

					<Typography variant="h5" mb={3} sx={{ mt: 5 }} gutterBottom>
						Documentos
					</Typography>

					<Grid container item component="form" onSubmit={updateDocuments} xs={12} sm={12} md={12} lg={12} xl={12} direction="row" alignItems="end" justifyContent="end" >

						{/* IDENTIFICACION */}
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								component="img"
								sx={{
									maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block', float: "left"
								}}
								alt='icono-spingo-identificacion'
								src={INE}
							/>
							<Typography sx={{ float: "left", ml: "15px" }} variant="h6" gutterBottom>
								Identificación
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ textAlign: 'center', mb: 2 }}>
							{/* <FormControlLabel id='ine' onClick={event => changeValueDocument(event, 'INE')} checked={checkedINE} control={<Switch />} label="Validado" /> */}
							<FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
								<InputLabel id="identificacion-select-standard-label">Validación</InputLabel>
								<Select
									labelId="identificacion-select-standard-label"
									id="identificacion-select-standard"
									onChange={event => changeValueDocument(event, 'INE')}
									label="Identificacion"
									defaultValue=""
								>
									<MenuItem value={checkedINE}>
										<em>{formatValuetoString(checkedINE)}</em>
									</MenuItem>
									<MenuItem value={1}>Validado</MenuItem>
									<MenuItem value={2}>Guardado · Pendiente de validar</MenuItem>
									<MenuItem value={3}>Rechazado</MenuItem>
									<MenuItem value={0}>Pendiente de subir</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ textAlign: 'center', mb: 2 }}>
							<Tooltip title="Ver Documento">
								<IconButton onClick={event => selectViewDocument(event, 'INE')} size="large" color="success" aria-label="view-documet-identification">
									<VisibilityOutlinedIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Subir Documento">
								<IconButton onClick={() => { setSelectDocument('identification') }} size="large" color="info" aria-label="upload-documet-identification">
									<CloudUploadOutlinedIcon />
								</IconButton>
							</Tooltip>
						</Grid>

						{/* DOMICILIO */}
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								component="img"
								sx={{
									maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block', float: "left"
								}}
								alt='icono-spingo-comprobante'
								src={Domicilio}
							/>
							<Typography sx={{ float: "left", ml: "15px" }} variant="h6" gutterBottom>
								Comprobante de Domicilio
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ textAlign: 'center', mb: 2 }}>
							{/* <FormControlLabel onClick={event => changeValueDocument(event, 'ProofAddress')} checked={checkedProofAddress} control={<Switch />} label="Validado" /> */}
							<FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
								<InputLabel id="domicilio-select-standard-label">Validación</InputLabel>
								<Select
									labelId="domicilio-select-standard-label"
									id="domicilio-select-standard"
									onChange={event => changeValueDocument(event, 'ProofAddress')}
									label="domicilio"
									defaultValue=""
								>
									<MenuItem value={checkedProofAddress}>
										<em>{formatValuetoString(checkedProofAddress)}</em>
									</MenuItem>
									<MenuItem value={1}>Validado</MenuItem>
									<MenuItem value={2}>Guardado · Pendiente de validar</MenuItem>
									<MenuItem value={3}>Rechazado</MenuItem>
									<MenuItem value={0}>Pendiente de subir</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ textAlign: 'center', mb: 2 }}>
							<Tooltip title="Ver Documento">
								<IconButton onClick={event => selectViewDocument(event, 'ProofAddress')} size="large" color="success" aria-label="view-documet-proffaddress">
									<VisibilityOutlinedIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Subir Documento">
								<IconButton onClick={() => { setSelectDocument('proof-address') }} size="large" color="info" aria-label="upload-documet-proofaddress">
									<CloudUploadOutlinedIcon />
								</IconButton>
							</Tooltip>
						</Grid>

						{/* TARJETA */}
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								component="img"
								sx={{
									maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block', float: "left"
								}}
								alt='icono-spingo-tarjeta'
								src={Tarjeta}
							/>
							<Typography sx={{ float: "left", ml: "15px" }} variant="h6" gutterBottom>
								Tarjeta de Circulación
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ textAlign: 'center', mb: 2 }}>
							{/* <FormControlLabel onClick={event => changeValueDocument(event, 'CirculationCard')} checked={checkedCirculationCard} control={<Switch />} label="Validado" /> */}
							<FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
								<InputLabel id="tarjeta-select-standard-label">Validación</InputLabel>
								<Select
									labelId="tarjeta-select-standard-label"
									id="tarjeta-select-standard"
									onChange={event => changeValueDocument(event, 'CirculationCard')}
									label="tarjeta"
									defaultValue=""
								>
									<MenuItem value={checkedCirculationCard}>
										<em>{formatValuetoString(checkedCirculationCard)}</em>
									</MenuItem>
									<MenuItem value={1}>Validado</MenuItem>
									<MenuItem value={2}>Guardado · Pendiente de validar</MenuItem>
									<MenuItem value={3}>Rechazado</MenuItem>
									<MenuItem value={0}>Pendiente de subir</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ textAlign: 'center', mb: 2 }}>
							<Tooltip title="Ver Documento">
								<IconButton onClick={event => selectViewDocument(event, 'CirculationCard')} size="large" color="success" aria-label="view-documet-circulationcard">
									<VisibilityOutlinedIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Subir Documento">
								<IconButton onClick={() => { setSelectDocument('circulation-card') }} size="large" color="info" aria-label="upload-documet-circulationcard">
									<CloudUploadOutlinedIcon />
								</IconButton>
							</Tooltip>
						</Grid>

						{/* FACTURA */}
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								component="img"
								sx={{
									maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block', float: "left"
								}}
								alt='icono-spingo-factura'
								src={Factura}
							/>
							<Typography sx={{ float: "left", ml: "15px" }} variant="h6" gutterBottom>
								Factura
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ textAlign: 'center', mb: 2 }}>
							{/* <FormControlLabel onClick={event => changeValueDocument(event, 'Bill')} checked={checkedBill} control={<Switch />} label="Validado" /> */}
							<FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 120 }}>
								<InputLabel id="factura-select-standard-label">Validación</InputLabel>
								<Select
									labelId="factura-select-standard-label"
									id="factura-select-standard"
									onChange={event => changeValueDocument(event, 'Bill')}
									label="factura"
									defaultValue=""
								>
									<MenuItem value={checkedBill}>
										<em>{formatValuetoString(checkedBill)}</em>
									</MenuItem>
									<MenuItem value={1}>Validado</MenuItem>
									<MenuItem value={2}>Guardado · Pendiente de validar</MenuItem>
									<MenuItem value={3}>Rechazado</MenuItem>
									<MenuItem value={0}>Pendiente de subir</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ textAlign: 'center', mb: 2 }}>
							<Tooltip title="Ver Documento">
								<IconButton onClick={event => selectViewDocument(event, 'Bill')} size="large" color="success" aria-label="view-documet-bill">
									<VisibilityOutlinedIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Subir Documento">
								<IconButton onClick={() => { setSelectDocument('bill') }} size="large" color="info" aria-label="upload-documet-bill">
									<CloudUploadOutlinedIcon />
								</IconButton>
							</Tooltip>
						</Grid>

						{/* ACCIONES */}
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
							<Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={2}>
								<Tooltip title="Actualizar">
									<IconButton disabled={disabledButton} type="submit" size="large" color="secondary" aria-label="update-documets">
										<UpdateOutlinedIcon />
									</IconButton>
								</Tooltip>
								<Tooltip title="Descargar">
									<IconButton disabled={disabledButton} onClick={event => downloadDocuments(event)} color="primary" size="large" aria-label="download-documents">
										<DownloadForOfflineOutlinedIcon />
									</IconButton>
								</Tooltip>
							</Stack>
						</Grid>
					</Grid>

					{/* BARRA DE PROGRESO PARA DESCARGAR DOCUMENTOS */}
					{progress &&
						<Box sx={{ width: '100%' }}>
							<LinearProgress />
						</Box>
					}

					{/* SUBIR DOCUMENTO INDIVUDUAL */}
					{
						(selectDocument !== null) &&
						<UploadOnlyDocument selectDocument={selectDocument} idUser={dataClient.id} />
					}

				</Grid>

			</Container>


			{/* VER DOCUMENTOS EN MODAL */}
			<Dialog
				fullWidth={true}
				maxWidth={'xl'}
				// fullScreen
				open={openDialog}
				onClose={closeDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Documento guardado
				</DialogTitle>
				<DialogContent>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						component="img"
						sx={{
							maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
						}}
						alt={viewDocument}
						src={viewDocument !== null && viewDocument !== "" ? `${Global.url_api_root}storage/app/documents/${routeDocument}/${viewDocument}` : imgDefault}
					/>

					{/* Verificar si el documento cuenta con reverso */}
					{
						viewDocumentReverse !== null &&
						<>

							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								component="img"
								sx={{
									maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
								}}
								alt={viewDocumentReverse}
								src={viewDocumentReverse !== null && viewDocumentReverse !== "" ? `${Global.url_api_root}storage/app/documents/${routeDocument}/${viewDocumentReverse}` : imgDefault}
							/>

						</>
					}
				</DialogContent>

				<DialogActions>
					<Button onClick={closeDialog}>Cerrar</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
