import React, { useEffect, useState } from 'react';

// * IMPORTS HELPERS
import { Global } from '../../../../helpers/Global';
// import { useAlert } from '../../../hooks/useAlert';

// * IMPORT MEDIA CUSTOM
import Domicilio from '../../../../assets/img/iconos/Domicilio.png';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// * IMPORT MATERIAL UI
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

// * IMPORTS MATERIAL UI - ICONS
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

// * IMPORTS MATERIAL UI - ALERTS
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';

// * IMPORTS REACT FILEPOND
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const ProofAddress = ({ idUser }) => {
    useEffect(() => {
        console.log(`ProofAddress`);
    }, [])

    const [proofAddressFront, setProofAddressFront] = useState([]);

    // Buttons
    const [disabledButton, setDisabledButton] = useState(false);
    // Alerts Message
    const [messages, setMessages] = useState({
        "validate_proofaddress": "",
        "save_proofaddress": "",
    });
    const [openAlert, setOpenAlert] = useState(true);
    // Progress
    const [progress, setProgress] = useState(false);

    // *  FUNCTIONS
    const uploadDocument = async (event) => {

        event.preventDefault();

        // Activar progress
        setProgress(true);

        // Reiniciar mensajes
        setMessages({
            "validate_proofaddress": "",
            "save_proofaddress": "",
        });

        let data = new FormData();
        data.append('idUser', idUser);

        // Conseguir el token de usuario
        const token = localStorage.getItem("token");

        try {
            // Validar campos de archivos
            if (proofAddressFront.length === 0) {
                // showStatusMessage(`Documentos incompletos.`, 'warning');
                alert('Documentos incompletos.');
                return false;
            }

            if (proofAddressFront[0].file.type !== 'image/png' && proofAddressFront[0].file.type !== 'image/jpeg' && proofAddressFront[0].file.type !== 'image/jpg') {
                // showStatusMessage(`El frente del comprobante de domicilio no es del tipo: png, jpeg, jpg.`, 'warning');
                alert('El frente del comprobante de domicilio no es del tipo: png, jpeg, jpg.');
                return false;
            }

            // Desactivar boton
            setDisabledButton(true);

            // upload proof address
            data.append('frente', proofAddressFront[0].file, proofAddressFront[0].file.name);
            // save proof address
            data.append('file0', proofAddressFront[0].file, proofAddressFront[0].file.name);

            // VALIDAR COMPROBANTE DE DOMICILIO
            let requestProofAddress = await fetch(`${Global.url_api}api/validate-proof-address`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json"
                },
                body: data,
                redirect: 'follow'
            });
            let responseProofAddress = JSON.parse(await requestProofAddress.json());

            console.log('@VALIDATE-PROOF-ADDRESS', responseProofAddress);

            if (responseProofAddress === false) {
                // showStatusMessage(`El servicio para validar y guardar su documento no está disponible por el momento, intente más tarde.`, 'error');
                alert(`El servicio para validar y guardar su documento no está disponible por el momento, intente más tarde.`);
                return false;
            } else {

                if (responseProofAddress.status === 'OK') {

                    setMessages(prev => ({ ...prev, ...{ validate_proofaddress: `Comprobante de domicilio validado correctamente.` } }));
                    data.append('validationProofAddress', true);

                } else if (responseProofAddress.estatus === 'ERROR' && responseProofAddress.mensaje) {

                    setMessages(prev => ({ ...prev, ...{ validate_proofaddress: `El comprobante de domicilio no paso la validación automática y será validado manualmente.` } }));
                    data.append('validationProofAddress', false);
                }

                // Guardar comprobante de domicilio
                let requestSaveProofAddress = await fetch(`${Global.url_api}api/upload-proof-address`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer  ${token.replace(/['"]+/g, '')}`
                    },
                    body: data,
                    redirect: 'follow'
                });
                let responseSaveProofAddress = await requestSaveProofAddress.json();

                console.log('@SAVE-IDENTIFICATION', responseSaveProofAddress);

                if (responseSaveProofAddress.code === '201') {
                    setMessages(prev => ({ ...prev, ...{ save_proofaddress: `Comprobante de domicilio guardado correctamente.` } }));

                } else {
                    setMessages(prev => ({ ...prev, ...{ save_proofaddress: `Error al guardar comprobante de domicilio.` } }));
                }

            }

            // Reiniciar estados
            setProofAddressFront([]);

        } catch (error) {
            console.log(error);
            // showStatusMessage(`Error Server ${error}`, 'error');
            alert(`Error Server ${error}`);
        }

        // Mostrar mensajes
        setOpenAlert(true);
        console.log('@MESSAGES', messages);

        // Activar boton
        setDisabledButton(false);

        // Desactivar progress
        setProgress(false);
    }

    // * CUSTOM BUTTON
    const CustomButton = styled(Button)(() => ({
        color: 'white',
        backgroundColor: '#3340E3',
        '&:hover': {
            backgroundColor: '#4d56cd',
        },
    }));


    return (
        <>
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            component="img"
                            sx={{
                                maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block', float: "left"
                            }}
                            alt='icono-spingo-domicilio'
                            src={Domicilio}
                        />
                        <Typography sx={{ float: "left", ml: "15px" }} variant="h6" gutterBottom>
                            Subir Comprobante de Domicilio
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        {progress &&
                            <Box mb={2} sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        }

                        {messages.validate_proofaddress !== "" &&
                            <Collapse in={openAlert}>
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="info"
                                            size="small"
                                            onClick={() => {
                                                setOpenAlert(false);
                                                setMessages({
                                                    "validate_proofaddress": "",
                                                    "save_proofaddress": "",
                                                });
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    variant="outlined"
                                    severity="info"
                                    sx={{ mb: 2 }}
                                >
                                    {messages.validate_proofaddress}
                                </Alert>
                            </Collapse>
                        }

                        {messages.save_proofaddress !== "" &&
                            <Collapse in={openAlert}>
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="info"
                                            size="small"
                                            onClick={() => {
                                                setOpenAlert(false);
                                                setMessages({
                                                    "validate_proofaddress": "",
                                                    "save_proofaddress": "",
                                                });
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    variant="outlined"
                                    severity="info"
                                    sx={{ mb: 2 }}
                                >
                                    {messages.save_proofaddress}
                                </Alert>
                            </Collapse>
                        }

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" gutterBottom>
                            Frente del comprobante *
                        </Typography>

                        <FilePond
                            id="proofAddressFront"
                            files={proofAddressFront}
                            onupdatefiles={setProofAddressFront}
                            labelIdle='Arrastra y suelta, o <span class="filepond--label-action">abrir archivos</span> <br> Archivo .jpg, .jpeg, .png de máximo 3 mb'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3, mb: 2 }} >
                        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                            <CustomButton variant="contained" onClick={event => uploadDocument(event)} endIcon={<CloudUploadOutlinedIcon />} disabled={disabledButton} >Subir</CustomButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
