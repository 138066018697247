import React, { useState } from 'react';

// * IMPORT HOOKS CUSTOMS
import useAuth from '../../hooks/useAuth';
import { useAlert } from '../../hooks/useAlert';


// * IMPORTS REACT COMPONENTS
import { DocumentIdentificationNewUx } from './DocumentIdentificationNewUx';
import { DocumentProofAddressNewUx } from './DocumentProofAddressNewUx';
import { DocumentCirculationCardNewUx } from './DocumentCirculationCardNewUx';
import { DocumentBillNewUx } from './DocumentBillNewUx';

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';

// * IMPORTS REACT ROUTER
// import { Link } from 'react-router-dom';

// * IMPORT MEDIA CUSTOM
import INE from '../../assets/img/iconos/INE.png';
import IneDisabled from '../../assets/img/iconos/INE-disabled.png';
import Domicilio from '../../assets/img/iconos/Domicilio.png';
import DomicilioDisabled from '../../assets/img/iconos/Domicilio-disabled.png';
import Tarjeta from '../../assets/img/iconos/Tarjeta.png';
import TarjetaDisabled from '../../assets/img/iconos/Tarjeta-disabled.png';
import Factura from '../../assets/img/iconos/Factura.png';
import FacturaDisabled from '../../assets/img/iconos/Factura-disabled.png';

// * IMPORTS MUI -Tel input
import { MuiTelInput } from 'mui-tel-input'

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// * IMPORTS MATERIAL UI - CARDS
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

// * IMPORTS MATERIAl UI - LINEAR PROGRESS
import Box from '@mui/material/Box';

// * IMPORT MATERIAL UI - BACKDROP
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// * IMPORT MATERIAL UI - DIALOG
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// * IMPORT MATERIAL UI - HEADER CARD
import CardHeader from '@mui/material/CardHeader';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';


export const Onboarding = () => {

    // * HOOKS
    const { auth, onboarding, loading } = useAuth();
    // console.log("@AUTH", auth);
    // console.log("@ONBOARDING", onboarding);

    // HEADER CARD
    const [value, setValue] = useState('INE');
    const [iconINE, setIconINE] = useState(INE);
    const [iconDomicilio, setIconDomicilio] = useState(DomicilioDisabled);
    const [iconTarjeta, setIconTarjeta] = useState(TarjetaDisabled);
    const [iconFactura, setIconFactura] = useState(FacturaDisabled);
    // TEL INPUT
    const [phone, setPhone] = useState('+52');
    // Alerts Message
    const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

    const addTelephone = (newPhone) => {
        setPhone(newPhone)
    }

    const saveTelephone = async () => {

        if (phone === "") {
            showStatusMessage('Debes agregar un número de celular.', 'warning');
            return false;
        }   


        const token = localStorage.getItem("token");

        let raw = JSON.stringify({
            "idUser": auth.id,
            "telephone": phone,
        });

        let requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer  ${token}`
            },
            body: raw,
            redirect: 'follow'
        };

        try {
            let request = await fetch(`${Global.url_api}api/onboarding/save-telephone`, requestOptions);
            let response = await request.json();

            console.log(response);

            showStatusMessage('Número celular agregado correctamente.', 'success');
            
            setTimeout(() => {
                handleClose();
            }, 3000);

        } catch (error) {
            console.log(error);
            showStatusMessage(`Error Server ${error}`, 'error');
        }
    }

    // * HEADER CARD
    const selectDocumentRender = () => {
        switch (value) {

            case "INE": return <DocumentIdentificationNewUx />;
            case "Domicilio": return <DocumentProofAddressNewUx />;
            case "Tarjeta": return <DocumentCirculationCardNewUx />;
            case "Factura": return <DocumentBillNewUx />

            default: return <h6>No document match</h6>
        }
    }

    const selectDocument = (event, icon) => {
        setValue(icon);
        switch (icon) {
            case 'INE':
                setIconINE(INE);
                setIconDomicilio(DomicilioDisabled);
                setIconTarjeta(TarjetaDisabled);
                setIconFactura(FacturaDisabled);
                break;
            case 'Domicilio':
                setIconINE(IneDisabled);
                setIconDomicilio(Domicilio);
                setIconTarjeta(TarjetaDisabled);
                setIconFactura(FacturaDisabled);
                break;
            case 'Tarjeta':
                setIconINE(IneDisabled);
                setIconDomicilio(DomicilioDisabled);
                setIconTarjeta(Tarjeta);
                setIconFactura(FacturaDisabled);
                break;
            case 'Factura':
                setIconINE(IneDisabled);
                setIconDomicilio(DomicilioDisabled);
                setIconTarjeta(TarjetaDisabled);
                setIconFactura(Factura);
                break;
            default:
                break;
        }
    }


    // * DIALOG
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    if (loading || Object.keys(onboarding).length === 0) {

        return (
            <Container maxWidth="md" sx={{ mt: 5 }} >
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            </Container>
        );

    } else {
        return (
            <div>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                    <Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
                        {messageSnackbar}
                    </Alert>
                </Snackbar>

                {/* VALIDAMOS SI YA TIENEN REGISTRADO SU NUMERO CELULAR */}
                {
                    (auth.celular === null || auth.celular === "") &&
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Por favor, agregue su número de celular empezando por su clave lada.</DialogTitle>
                        <DialogContent>
                            <MuiTelInput value={phone} onChange={addTelephone} fullWidth />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancelar</Button>
                            <Button onClick={saveTelephone}>Agregar</Button>
                        </DialogActions>
                    </Dialog>
                }

                <Container maxWidth="xl" sx={{ mt: 5 }} >

                    <Grid container spacing={5}>
                        {/* MENU */}
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Typography variant="h6" mb={3} gutterBottom>
                                ¡Tu préstamo Spingo a unos pasos!
                            </Typography>

                            <Typography variant="body2" mb={3} gutterBottom>
                                Lo único que necesitas hacer es subir tus documentos.
                            </Typography>

                            {/* DOCUMENTOS DE SOLICITUD DE CREDITO */}
                            <Grid>
                                <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={value} >
                                    <Grid item xs={12}>
                                        <Card sx={{ maxWidth: 435, mb: 1, cursor: 'pointer' }} onClick={(event) => selectDocument(event, 'INE')}>
                                            <CardHeader
                                                avatar={
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        component="img"
                                                        sx={{
                                                            maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
                                                        }}
                                                        alt='icono-spingo-ine'
                                                        src={iconINE}
                                                    />
                                                }
                                                action={
                                                    <FormControlLabel value="INE" control={<Radio />} />
                                                }
                                                title="Identificación oficial"
                                                subheader={
                                                    // INE validado
                                                    onboarding.data.validacion_ine === 1 ? (
                                                        <Typography variant="body2" mt={1} sx={{ color: "#1B9F6D" }} gutterBottom>
                                                            Validado
                                                        </Typography>
                                                        // INE pendiente de validar
                                                    ) : onboarding.data.validacion_ine === 2 ? (
                                                        <Typography variant="body2" mt={1} sx={{ color: "#FF8159" }} gutterBottom>
                                                            Guardado · Pendiente de validar
                                                        </Typography>
                                                    ) : (
                                                        // Documento no agregado
                                                        <Typography variant="body2" mt={1} sx={{ color: "#FF5996" }} gutterBottom>
                                                            Pendiente de subir
                                                        </Typography>
                                                    )
                                                }
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card sx={{ maxWidth: 435, mb: 1, cursor: 'pointer' }} onClick={(event) => selectDocument(event, 'Domicilio')}>
                                            <CardHeader
                                                avatar={
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        component="img"
                                                        sx={{
                                                            maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
                                                        }}
                                                        alt='icono-spingo-domicilio'
                                                        src={iconDomicilio}
                                                    />
                                                }
                                                action={
                                                    <FormControlLabel value="Domicilio" control={<Radio />} />
                                                }
                                                title="Comprobante de domicilio"
                                                subheader={
                                                    // Comprobante de Domicilio validado
                                                    onboarding.data.validacion_comprobante === 1 ? (
                                                        <Typography variant="body2" mt={1} sx={{ color: "#1B9F6D" }} gutterBottom>
                                                            Validado
                                                        </Typography>
                                                        // Comprobante de Domicilio  pendiente de validar
                                                    ) : onboarding.data.validacion_comprobante === 2 ? (
                                                        <Typography variant="body2" mt={1} sx={{ color: "#FF8159" }} gutterBottom>
                                                            Guardado · Pendiente de validar
                                                        </Typography>
                                                    ) : (
                                                        // Documento no agregado
                                                        <Typography variant="body2" mt={1} sx={{ color: "#FF5996" }} gutterBottom>
                                                            Pendiente de subir
                                                        </Typography>
                                                    )
                                                }
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card sx={{ maxWidth: 435, mb: 1, cursor: 'pointer' }} onClick={(event) => selectDocument(event, 'Tarjeta')}>
                                            <CardHeader
                                                avatar={
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        component="img"
                                                        sx={{
                                                            maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
                                                        }}
                                                        alt='icono-spingo-tarjeta'
                                                        src={iconTarjeta}
                                                    />
                                                }
                                                action={
                                                    <FormControlLabel value="Tarjeta" control={<Radio />} />
                                                }
                                                title="Tarjeta de circulación"
                                                subheader={
                                                    // Tarjeta Circulacion validada
                                                    onboarding.data.validacion_tarjeta_circulacion === 1 ? (
                                                        <Typography variant="body2" mt={1} sx={{ color: "#1B9F6D" }} gutterBottom>
                                                            Validado
                                                        </Typography>
                                                        // Tarjeta Circulacion pendiente de validar
                                                    ) : onboarding.data.validacion_tarjeta_circulacion === 2 ? (
                                                        <Typography variant="body2" mt={1} sx={{ color: "#FF8159" }} gutterBottom>
                                                            Guardado · Pendiente de validar
                                                        </Typography>
                                                    ) : (
                                                        // Documento no agregado
                                                        <Typography variant="body2" mt={1} sx={{ color: "#FF5996" }} gutterBottom>
                                                            Pendiente de subir
                                                        </Typography>
                                                    )
                                                }
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card sx={{ maxWidth: 435, mb: 1, cursor: 'pointer' }} onClick={(event) => selectDocument(event, 'Factura')}>
                                            <CardHeader
                                                avatar={
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        component="img"
                                                        sx={{
                                                            maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
                                                        }}
                                                        alt='icono-spingo-factura'
                                                        src={iconFactura}
                                                    />
                                                }
                                                action={
                                                    <FormControlLabel value="Factura" control={<Radio />} />
                                                }
                                                title="Factura de tu auto"
                                                subheader={
                                                    // Factura validada
                                                    onboarding.data.validacion_factura === 1 ? (
                                                        <Typography variant="body2" mt={1} sx={{ color: "#1B9F6D" }} gutterBottom>
                                                            Validado
                                                        </Typography>
                                                        // Factura pendiente de validar
                                                    ) : onboarding.data.validacion_factura === 2 ? (
                                                        <Typography variant="body2" mt={1} sx={{ color: "#FF8159" }} gutterBottom>
                                                            Guardado · Pendiente de validar
                                                        </Typography>
                                                    ) : (
                                                        // Documento no agregado
                                                        <Typography variant="body2" mt={1} sx={{ color: "#FF5996" }} gutterBottom>
                                                            Pendiente de subir
                                                        </Typography>
                                                    )
                                                }
                                            />
                                        </Card>
                                    </Grid>
                                </RadioGroup>
                            </Grid>

                            <Typography variant="caption" display="block" mt={3} gutterBottom>
                                Una vez que los recibamos, nuestro equipo de expertos se encargará de revisarlos y responderte a la brevedad.
                            </Typography>
                        </Grid>

                        {/* CONTENIDO A RENDERIZAR DINAMICAMENTE */}
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            {selectDocumentRender()}
                        </Grid>
                    </Grid>

                </Container>

            </div>
        );

    }

}
