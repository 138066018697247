import React from 'react';

// * IMPORTS MATERIAl UI
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

// * IMPORTS COMPONENTES RELATIVE TIME DATES
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es-mx';


export const Notes = ({ notes }) => {
    
    return (
        <>
            <Grid container spacing={2} mt={3}>
                {
                    (notes.length !== 0) ?
                        notes.notes.map(({ id, note, last_editor, created_at }) =>
                        (
                            <Grid item xs={12} sm={12} md={6} key={id}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="overline">
                                            {note}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Autor: {last_editor}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            Fecha:&nbsp;
                                            <Moment locale="es-mx" format="D MMM YYYY" withTitle>
                                                {created_at}
                                            </Moment>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                        )
                        )
                        : 
                            <Typography variant="overline">
                                Aún no hay notas registradas.
                            </Typography>
                }
            </Grid>
        </>
    )
}
