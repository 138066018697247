import React, { useState, useEffect } from 'react';
import { useForm } from '../../hooks/useForm';
import useAuth from '../../hooks/useAuth';

// * IMPORTS ROUTING 
// import { NavLink } from 'react-router-dom';

// * IMPORT MEDIA CUSTOM
import logoSpingo from '../../assets/img/general/Logo_ 2.png';
import INE from '../../assets/img/iconos/INE.png';
import Domicilio from '../../assets/img/iconos/Domicilio.png';
import Tarjeta from '../../assets/img/iconos/Tarjeta.png';
import Factura from '../../assets/img/iconos/Factura.png';

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';

// * IMPORTS UTIL FROM API GOOGLE
import jwt_decode from "jwt-decode";

// * IMPORTS MATERIAL UI - LOGIN
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// * IMPORT MATERIAL UI ALERT TOAST
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';


const theme = createTheme();

export const Login = () => {

	// * HOOKS
	// Auth
	const { setAuth } = useAuth();
	// Register User
	const { form, changed } = useForm({});
	// Snackbar
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [messageSnackbar, setMessageSnackbar] = useState("");
	const [severitySnackbar, setSeveritySnackbar] = useState("");

	// * GOOGLE
	useEffect(() => {
		/* global google */
		google.accounts.id.initialize({
			client_id: `${Global.client_id_google}`,
			callback: handleCallbackResponseGoogle
		})

		google.accounts.id.renderButton(
			document.getElementById("signInDiv"),
			{ theme: "outline", size: "large" }
		);

		google.accounts.id.prompt();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleCallbackResponseGoogle = async (response) => {

		if (response.hasOwnProperty('credential')) {

			let userObj = jwt_decode(response.credential);

			// Prepara request api para hacer registro o login con Google
			let requestOptionsGoogle = {
				method: 'POST',
				body: JSON.stringify(userObj),
				headers: {
					"Content-Type": "application/json"
				},
				redirect: 'follow'
			};

			// Peticion al backend
			let request = await fetch(`${Global.url_api}api/auth/google-register-login`, requestOptionsGoogle);

			let data = await request.json();

			console.log(data);

			// Peticion al backend para obtener los datos del usuario
			const requestMeGoogle = await fetch(`${Global.url_api}api/auth/me`, {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer  ${data.access_token.replace(/['"]+/g, '')}`
				},
				body: {},
				redirect: 'follow'
			});

			const dataMe = await requestMeGoogle.json();

			// Persistir los datos en el navegador
			localStorage.setItem("token", data.access_token);
			localStorage.setItem("user", JSON.stringify(dataMe));

			// Setear los datos en el auth de AuthProvider
			setAuth(dataMe);

			// Redireccion a GUI Onboarding
			setTimeout(() => {
				window.location.reload();
			}, 1500);
		} else {
			console.log('error login google');
			alert('Error al iniciar sesión con Google, favor de intentar más tarde.');
		}

	}

	// * FUNCTION LOGIN
	const loginUser = async (event) => {
		// Prevenir actualizar pantalla
		event.preventDefault();

		// Recoger datos del formulario
		let userToLogin = form;

		// Validacion de datos
		let { email, password } = userToLogin;

		if (!email || !password) {
			showStatusMessage('¡Todos los campos son obligatorios!', 'warning');
			return false;
		}

		// Prepara request api
		let requestOptions = {
			method: 'POST',
			body: JSON.stringify(userToLogin),
			headers: {
				"Content-Type": "application/json"
			},
			redirect: 'follow'
		};

		// Peticion al backend
		const request = await fetch(`${Global.url_api}api/auth/login`, requestOptions);

		const data = await request.json();

		// Validar respuestas del backend
		if (data.access_token && data.access_token !== '') {

			// Prepara request api
			let requestOptions = {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer  ${data.access_token.replace(/['"]+/g, '')}`
				},
				body: JSON.stringify(userToLogin),
				redirect: 'follow'
			};

			// Peticion al backend para obtener los datos del usuario
			const requestMe = await fetch(`${Global.url_api}api/auth/me`, requestOptions);

			const dataMe = await requestMe.json();

			// Persistir los datos en el navegador
			localStorage.setItem("token", data.access_token);
			localStorage.setItem("user", JSON.stringify(dataMe));

			showStatusMessage('¡Usuario identificado correctamente!', 'success');

			// Setear los datos en el auth de AuthProvider
			setAuth(dataMe);

			// Redireccion a GUI Onboarding
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
		else if (data.error && data.error === 'Unauthorized') {
			showStatusMessage('¡Usuario no identificado!', 'error');
			return false;
		} else {
			showStatusMessage('¡Error inesperado!', 'error');
		}

	};

	// * CUSTOM BUTTON
	const CustomButton = styled(Button)(() => ({
		color: 'white',
		backgroundColor: '#3340E3',
		'&:hover': {
			backgroundColor: '#4d56cd',
		},
	}));

	// * FUNCTIONS SNACKBAR
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const showStatusMessage = (message, type) => {
		setOpenSnackbar(true);
		setMessageSnackbar(message);
		setSeveritySnackbar(type);
	};

	const closeSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};


	return (
		<ThemeProvider theme={theme}>

			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Container component="main" maxWidth="lg" sx={{ marginTop: 8 }}>

				<Grid container spacing={3}>

					<Grid item xs={12} md={6} lg={6}>
						<Box
							display="flex"
							justifyContent="start"
							alignItems="start"
							component="img"
							sx={{
								maxWidth: "40%", maxHeight: "calc(100vh - 64px)", marginBottom: 2
							}}
							alt='logo-spingo'
							src={logoSpingo}
						/>

						<Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
							Compártenos tus documentos y solicita tu prestamo
						</Typography>

						<Typography variant="subtitle1" sx={{ mb: 2 }}>
							Solo necesitas
						</Typography>

						<Grid container spacing={3} sx={{ marginBottom: 7 }}>
							<Grid item xs={1} md={1} lg={1}>
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									component="img"
									sx={{
										maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
									}}
									alt='icono-spingo-ine'
									src={INE}
								/>
							</Grid>
							<Grid item xs={11} md={11} lg={11}>
								<Typography variant="subtitle2" gutterBottom>
									Identificación oficial
								</Typography>
								<Typography variant="body2" gutterBottom>
									INE por ambos lados para validar tu identidad.
								</Typography>
							</Grid>
						</Grid>

						<Grid container spacing={3} sx={{ marginBottom: 7 }}>
							<Grid item xs={1} md={1} lg={1}>
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									component="img"
									sx={{
										maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
									}}
									alt='icono-spingo-domicilio'
									src={Domicilio}
								/>
							</Grid>
							<Grid item xs={11} md={11} lg={11}>
								<Typography variant="subtitle2" gutterBottom>
									Comprobante de domicilio
								</Typography>
								<Typography variant="body2" gutterBottom>
									Para verificar tu dirección necesitamos un comprobante a tu nombre.
								</Typography>
							</Grid>
						</Grid>

						<Grid container spacing={3} sx={{ marginBottom: 7 }}>
							<Grid item xs={1} md={1} lg={1}>
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									component="img"
									sx={{
										maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
									}}
									alt='icono-spingo-tarjeta'
									src={Tarjeta}
								/>
							</Grid>
							<Grid item xs={11} md={11} lg={11}>
								<Typography variant="subtitle2" gutterBottom>
									Tarjeta de circulación
								</Typography>
								<Typography variant="body2" gutterBottom>
									Documento por ambos lados o solo frente si es de CDMX.
								</Typography>
							</Grid>
						</Grid>

						<Grid container spacing={3} sx={{ marginBottom: 7 }}>
							<Grid item xs={1} md={1} lg={1}>
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									component="img"
									sx={{
										maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block'
									}}
									alt='icono-spingo-factura'
									src={Factura}
								/>
							</Grid>
							<Grid item xs={11} md={11} lg={11}>
								<Typography variant="subtitle2" gutterBottom>
									Factura de tu auto
								</Typography>
								<Typography variant="body2" gutterBottom>
									Factura original o copias de refacturaciones si es el caso.
								</Typography>
							</Grid>
						</Grid>

						<Typography variant="body2" gutterBottom>
							Más información
						</Typography>

					</Grid>

					<Grid item xs={12} md={6} lg={6}>
						<CssBaseline />
						<Box className="register-login-card" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', padding: '55px', borderRadius: '10px' }} >

							<Typography component="h1" variant="h5" sx={{ mb: 2 }}>
								¡Empieza ahora!
							</Typography>

							<Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
								Ingresa la dirección de correo electrónico que deseas usar para abrir una cuenta o iniciar sesión.
							</Typography>

							{/* REGISTER WITH API GOOGLE */}
							<div id="signInDiv"></div>

							<Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
								O utiliza tu correo
							</Typography>

							<Box component="form" onSubmit={loginUser} noValidate sx={{ mt: 1 }}>

								<TextField color='secondary' size='small' onChange={changed} error={!form.email ? true : false} helperText={!form.email ? "Campo obligatorio" : false} margin="normal" required fullWidth id="email" label="Correo Electrónico" name="email" autoComplete="email" autoFocus />

								<TextField color='secondary' size='small' onChange={changed} error={!form.password ? true : false} helperText={!form.password ? "Campo obligatorio" : false} margin="normal" required fullWidth name="password" label="Contraseña" type="password" id="password" autoComplete="current-password" />

								<CustomButton type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 7 }}>Continuar</CustomButton>
							</Box>


							<Box sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								mb: 3
							}}>
								<Grid container>
									<Grid item>
										<Link variant="body2" onClick={(event) => {
											alert("Recuperar contraseña");
										}}> ¿Has olvidado tu contraseña? </Link>
									</Grid>
								</Grid>
							</Box>

							<Grid container>
								<Grid item xs={12}>
									<Typography variant="caption" display="block" gutterBottom mt={2}>
										Al continuar aceptas nuestros Términos y Condiciones y que has leído y entendido nuestro Aviso de Privacidad.
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>

			</Container>

		</ThemeProvider>
	)
}