import React, { useState } from 'react';

// * IMPORTS HELPERS
import { Global } from '../../../helpers/Global';
import { useAlert } from '../../../hooks/useAlert';

// * IMPORT MEDIA CUSTOM
import INE from '../../../assets/img/iconos/INE.png';
import Domicilio from '../../../assets/img/iconos/Domicilio.png';

// * IMPORT MATERIAL UI
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';

// * IMPORTS MATERIAL UI - ICONS
import CachedIcon from '@mui/icons-material/Cached';

// * IMPORTS MATERIAL UI - ALERTS
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';

// * IMPORTS REACT FILEPOND
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const SaveUpdateDocuments = (props) => {

    const { idUser } = props;
    console.log('@ID-USER SAVE UPDATE DOCUMENTS', idUser);

    // * HOOKS
    const [identificationFront, setidentificationFront] = useState([]);
    const [identificationReverse, setidentificationReverse] = useState([]);
    const [proofAddressFront, setProofAddressFront] = useState([]);
    // Buttons
    const [disabledButton, setDisabledButton] = useState(false);
    // Alerts Message
    const [messages, setMessages] = useState({
        "validate_identification": "",
        "save_identification": "",
        "validate_proofaddress": "",
        "save_proofaddress": ""
    });
    const [openAlert, setOpenAlert] = React.useState(true);
    const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

    // *  FUNCTIONS
    const saveOrUpdateDocuments = async (event) => {

        event.preventDefault();

        // Reiniciar mensajes
        setMessages({
            "validate_identification": "",
            "save_identification": "",
            "validate_proofaddress": "",
            "save_proofaddress": ""
        });

        // Validar id del usaurio al que se asociaran los documentos
        if (idUser === null) {
            showStatusMessage(`Aún no se ha asociado el ID de ningún prospecto.`, 'info');
            return false;
        }

        // Validar campos de archivos
        if (identificationFront.length === 0 || identificationReverse.length === 0 || proofAddressFront.length === 0) {
            showStatusMessage(`Documentos incompletos.`, 'warning');
            return false;
        }

        if (identificationFront[0].file.type !== 'image/png' && identificationFront[0].file.type !== 'image/jpeg' && identificationFront[0].file.type !== 'image/jpg') {
            showStatusMessage(`El frente de la identificación no es del tipo: png, jpeg, jpg.`, 'warning');
            return false;
        }

        if (identificationReverse[0].file.type !== 'image/png' && identificationReverse[0].file.type !== 'image/jpeg' && identificationReverse[0].file.type !== 'image/jpg') {
            showStatusMessage(`El reverso de la identificación no es del tipo: png, jpeg, jpg.`, 'warning');
            return false;
        }

        if (proofAddressFront[0].file.type !== 'image/png' && proofAddressFront[0].file.type !== 'image/jpeg' && proofAddressFront[0].file.type !== 'image/jpg') {
            showStatusMessage(`El frente del comprobante de domicilio no es del tipo: png, jpeg, jpg.`, 'warning');
            return false;
        }

        // Desactivar boton
        setDisabledButton(true);

        let data = new FormData();
        data.append('idUser', idUser);
        // validate identification
        data.append('frente', identificationFront[0].file, identificationFront[0].file.name);
        data.append('reverso', identificationReverse[0].file, identificationReverse[0].file.name);
        // save identification
        data.append('file0', identificationFront[0].file, identificationFront[0].file.name);
        data.append('file1', identificationReverse[0].file, identificationReverse[0].file.name);

        // Conseguir el token de usuario
        const token = localStorage.getItem("token");

        try {

            // * IDENTIFICACION

            // Validar identificacion
            let requestIdentification = await fetch(`${Global.url_api}api/validate-ine-ocr`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json"
                },
                body: data,
                redirect: 'follow'
            });
            let responseIdentification = JSON.parse(await requestIdentification.json());

            console.log('@VALIDATE-IDENTIFICATION', responseIdentification);

            if (responseIdentification === false) {
                showStatusMessage(`El servicio para validar y guardar su documento no está disponible por el momento, intente más tarde.`, 'error');
                return false;
            }

            if (responseIdentification.validacionMRZ) {

                setMessages(prev => ({ ...prev, ...{ validate_identification: `Identificación ${responseIdentification.tipo} validada correctamente.` } }));

                data.append('validationINE', true);

                // Guardar identificacion
                let requestSaveIdentification = await fetch(`${Global.url_api}api/upload-ine`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer  ${token}`
                    },
                    body: data,
                    redirect: 'follow'
                });

                let responseSaveIdentification = await requestSaveIdentification.json();

                console.log('@SAVE-IDENTIFICATION', responseSaveIdentification);

                if (responseSaveIdentification.code === '201') {
                    setMessages(prev => ({ ...prev, ...{ save_identification: `Identificación guardada correctamente.` } }));

                } else {
                    setMessages(prev => ({ ...prev, ...{ save_identification: `Error al guardar identificación.` } }));
                }

            } else {
                setMessages(prev => ({ ...prev, ...{ validate_identification: `La identificación no se logro validar y no se ha guardado.` } }));
            }

            // * COMPROBANTE DE DOMICILIO

            // Validar Comprobante de Domicilio
            data.append('frente', proofAddressFront[0].file, proofAddressFront[0].file.name);
            // save proof address
            data.append('file0', proofAddressFront[0].file, proofAddressFront[0].file.name);

            // VALIDAR COMPROBANTE DE DOMICILIO
            let requestProofAddress = await fetch(`${Global.url_api}api/validate-proof-address`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json"
                },
                body: data,
                redirect: 'follow'
            });
            let responseProofAddress = JSON.parse(await requestProofAddress.json());

            console.log('@VALIDATE-PROOF-ADDRESS', responseProofAddress);

            if (responseProofAddress === false) {

                showStatusMessage(`El servicio para validar y guardar su documento no está disponible por el momento, intente más tarde.`, 'error');
                return false;
            } else {

                if (responseProofAddress.status === 'OK') {

                    setMessages(prev => ({ ...prev, ...{ validate_proofaddress: `Comprobante de domicilio validado correctamente.` } }));
                    data.append('validationProofAddress', true);

                } else if (responseProofAddress.estatus === 'ERROR' && responseProofAddress.mensaje) {

                    setMessages(prev => ({ ...prev, ...{ validate_proofaddress: `El comprobante de domicilio no paso la validación automática y será validado manualmente.` } }));
                    data.append('validationProofAddress', false);
                }

                // Guardar comprobante de domicilio
                let requestSaveProofAddress = await fetch(`${Global.url_api}api/upload-proof-address`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer  ${token.replace(/['"]+/g, '')}`
                    },
                    body: data,
                    redirect: 'follow'
                });
                let responseSaveProofAddress = await requestSaveProofAddress.json();

                console.log('@SAVE-IDENTIFICATION', responseSaveProofAddress);

                if (responseSaveProofAddress.code === '201') {
                    setMessages(prev => ({ ...prev, ...{ save_proofaddress: `Comprobante de domicilio guardado correctamente.` } }));

                } else {
                    setMessages(prev => ({ ...prev, ...{ save_proofaddress: `Error al guardar comprobante de domicilio.` } }));
                }

            }

            console.log('@MESSAGES', messages);
            // Mostrar mensajes
            setOpenAlert(true);

        } catch (error) {
            console.log(error);
            showStatusMessage(`Error Server ${error}`, 'error');
        }

        // Activar boton
        setDisabledButton(false);
    }

    // * CUSTOM BUTTON
    const CustomButton = styled(Button)(() => ({
        color: 'white',
        backgroundColor: '#3340E3',
        '&:hover': {
            backgroundColor: '#4d56cd',
        },
    }));

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

            <Grid container spacing={2}>
                <Grid item mt={5} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" gutterBottom>
                        Subir documentos
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    {messages.validate_identification !== "" &&
                        <Collapse in={openAlert}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="info"
                                        size="small"
                                        onClick={() => {
                                            setOpenAlert(false);
                                            setMessages({
                                                "validate_identification": "",
                                                "save_identification": "",
                                                "validate_proofaddress": "",
                                                "save_proofaddress": ""
                                            });
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                variant="outlined"
                                severity="info"
                                sx={{ mb: 2 }}
                            >
                                {messages.validate_identification}
                            </Alert>
                        </Collapse>
                    }

                    {messages.save_identification !== "" &&
                        <Collapse in={openAlert}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="info"
                                        size="small"
                                        onClick={() => {
                                            setOpenAlert(false);
                                            setMessages({
                                                "validate_identification": "",
                                                "save_identification": "",
                                                "validate_proofaddress": "",
                                                "save_proofaddress": ""
                                            });
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                variant="outlined"
                                severity="info"
                                sx={{ mb: 2 }}
                            >
                                {messages.save_identification}
                            </Alert>
                        </Collapse>
                    }

                    {messages.validate_proofaddress !== "" &&
                        <Collapse in={openAlert}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="info"
                                        size="small"
                                        onClick={() => {
                                            setOpenAlert(false);
                                            setMessages({
                                                "validate_identification": "",
                                                "save_identification": "",
                                                "validate_proofaddress": "",
                                                "save_proofaddress": ""
                                            });
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                variant="outlined"
                                severity="info"
                                sx={{ mb: 2 }}
                            >
                                {messages.validate_proofaddress}
                            </Alert>
                        </Collapse>
                    }

                    {messages.save_proofaddress !== "" &&
                        <Collapse in={openAlert}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="info"
                                        size="small"
                                        onClick={() => {
                                            setOpenAlert(false);
                                            setMessages({
                                                "validate_identification": "",
                                                "save_identification": "",
                                                "validate_proofaddress": "",
                                                "save_proofaddress": ""
                                            });
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                variant="outlined"
                                severity="info"
                                sx={{ mb: 2 }}
                            >
                                {messages.save_proofaddress}
                            </Alert>
                        </Collapse>
                    }

                </Grid>

                {/* IDENTIFICATION */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        component="img"
                        sx={{
                            maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block', float: "left"
                        }}
                        alt='icono-spingo-identificacion'
                        src={INE}
                    />
                    <Typography sx={{ float: "left", ml: "15px" }} variant="h6" gutterBottom>
                        Identificación
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography variant="body1" gutterBottom>
                        Frente de la identificación *
                    </Typography>

                    <FilePond
                        id="identificationFront"
                        files={identificationFront}
                        onupdatefiles={setidentificationFront}
                        labelIdle='Arrastra y suelta, o <span class="filepond--label-action">abrir archivos</span> <br> Archivo .jpg, .jpeg, .png de máximo 3 mb'
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography variant="body1" gutterBottom>
                        Reverso de la identificación *
                    </Typography>

                    <FilePond
                        id="identificationReverse"
                        files={identificationReverse}
                        onupdatefiles={setidentificationReverse}
                        labelIdle='Arrastra y suelta, o <span class="filepond--label-action">abrir archivos</span> <br> Archivo .jpg, .jpeg, .png de máximo 3 mb'
                    />
                </Grid>

                {/* COMPROBANTE DE DOMICILIO */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2} sx={{ textAlign: 'center' }}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        component="img"
                        sx={{
                            maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block', float: "left"
                        }}
                        alt='icono-spingo-domicilio'
                        src={Domicilio}
                    />
                    <Typography sx={{ float: "left", ml: "15px" }} variant="h6" gutterBottom>
                        Comprobante de Domicilio
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography variant="body1" gutterBottom>
                        Frente del comprobante *
                    </Typography>

                    <FilePond
                        id="proofAddressFront"
                        files={proofAddressFront}
                        onupdatefiles={setProofAddressFront}
                        labelIdle='Arrastra y suelta, o <span class="filepond--label-action">abrir archivos</span> <br> Archivo .jpg, .jpeg, .png de máximo 3 mb'
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3, mb: 2 }} >
                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                        <CustomButton onClick={event => saveOrUpdateDocuments(event)} variant="contained" endIcon={<CachedIcon />} disabled={disabledButton} >Subir</CustomButton>
                    </Stack>
                </Grid>
            </Grid>

        </div>
    )
}

