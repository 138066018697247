import React, { useState } from 'react';

// * IMPORTS HELPERS
// import { Global } from '../../../helpers/Global';
import { useAlert } from '../../../hooks/useAlert';

// * IMPORTS COMPONENTS
import { SaveUpdateDocuments } from './SaveUpdateDocuments';

// * IMPORT MATERIAL UI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Grid from '@mui/material/Grid';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';

// * IMPORTS MATERIAL UI - ICONS
import CachedIcon from '@mui/icons-material/Cached';

// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';


export const UpdateDocuments = (props) => {

    const { users } = props;

    // * HOOKS
    const [idUser, setIdUser] = useState(null);
    const [user, setUser] = useState({});

    // Buttons
    const [disabledButton, setDisabledButton] = useState(false);
    // Alerts Message
    const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

    // * FUNCTIONS
    const searchUser = (event) => {

        event.preventDefault();

        setDisabledButton(true);

        let id = event.target.idUser.value;
        let search = users.find((user) => user.id === parseInt(id));

        if (search !== undefined) {
            setUser(search);
            setIdUser(search.id);
        } else {
            setUser({});
            setIdUser(null);
            showStatusMessage('No se encontró ningún usuario con ese ID.', 'info');
        }
        setDisabledButton(false);
    }

    // * CUSTOM BUTTON
    const CustomButton = styled(Button)(() => ({
        color: 'white',
        backgroundColor: '#3340E3',
        '&:hover': {
            backgroundColor: '#4d56cd',
        },
    }));

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

            <Grid container component="form" onSubmit={searchUser} spacing={2} autoComplete="off">

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" gutterBottom>
                        Filtrar Prospecto
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="idUser"
                        label="ID prospecto"
                        fullWidth
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {user.hasOwnProperty('id') ?
                        <>
                            <Typography variant="subtitle1" gutterBottom>
                                Se actualizarán los documentos del prospecto: {user.name} {user.middle_name} {user.last_name}
                            </Typography>
                        </>
                        :
                        <>
                            <Typography variant="subtitle1" gutterBottom>
                                No se encontró ningún usuario con ese ID.
                            </Typography>
                        </>
                    }
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3, mb: 2 }} >
                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                        <CustomButton type="submit" variant="contained" endIcon={<CachedIcon />} disabled={disabledButton} >Buscar</CustomButton>
                    </Stack>
                </Grid>
            </Grid>

            <SaveUpdateDocuments idUser={idUser} />
        </div>
    )
}
