import { useState } from 'react';

// * IMPORTS MATERIAl UI
import Typography from '@mui/material/Typography';

export const useResponsiveMobile = () => {

    const screenPx = 800;

    // * HOOKS
    const [isMobile, setIsMobile] = useState(false);

    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < screenPx) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    const printMessage = (message) => {
        if (isMobile) {
            return <>
                <Typography sx={{ fontSize: '10px' }} variant="caption" display="block" gutterBottom>
                    {message}
                </Typography>
            </>
        } else {
            return <>
                <Typography sx={{ fontSize: '14px' }} variant="caption" display="block" gutterBottom>
                    {message}
                </Typography>
            </>
        }
    }

    return {
        isMobile,
        handleResize,
        printMessage
    }
}
