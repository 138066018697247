import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';

import { Header } from './Header';
import { Footer } from '../private/Footer';

// * IMPORTS MATERIAl UI - GRID
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

// * IMPORT MATERIAL UI - BACKDROP
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const PrivateLayout = () => {

    const { auth, loading } = useAuth();


    if (loading) {
        return (

            <Container maxWidth="md" sx={{ mt: 5 }} >
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            </Container>
        );

    } else {
        return (
            <>
                {/* LAYOUT */}
                <Header />

                {/* CONTENIDO PRINCIPAL */}
                <div className='main-content'>
                    {auth.id ?
                        <Outlet />
                        :
                        <Navigate to='/login' />
                    }
                </div>

                <Footer />
            </>
        );
    }
}
