import React, { useEffect, useState } from 'react'

import { Global } from '../../../../../helpers/Global';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

import MUIDataTable from "mui-datatables";

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es-mx';

export const Editors = ({ open, onClose, idPawnAdjustmentSlip }) => {

    const [editors, setEditors] = useState([]);

    const getEditors = async (idPawnAdjustmentSlip) => {
        // Conseguir el token de usuario
        const token = localStorage.getItem("token");

        let request = await fetch(`${Global.url_api}api/auth/get-editors-pawn-adjustment-slips`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer  ${token}`,
                "Content-Type": "application/json"
            },
            redirect: 'follow',
            body: JSON.stringify({ id_pawn_adjustment_slip: idPawnAdjustmentSlip })
        });

        let response = await request.json();
        return response;
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await getEditors(idPawnAdjustmentSlip); // Asegúrate de pasar idPawnAdjustmentSlip como argumento si es necesario
            setEditors(result.data);
        };

        fetchData();
    }, [idPawnAdjustmentSlip]);

    const columns = [
        {
            name: "name",
            label: "Nombre"
        },
        {
            name: "created_at",
            options: {
                customBodyRender: (value) => {
                    return (
                        <Moment locale="es-mx" format="D MMM YYYY" withTitle>
                            {value}
                        </Moment>
                    );
                }
            },
            label: "Fecha"
        }
    ];

    const options = {
        selectableRowsHideCheckboxes: true,
        filterType: 'textField',
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros coincidentes.",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
                next: "Siguiente pagina",
                previous: "Pagina anterior",
                rowsPerPage: "Filas por pagina:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Tabla de filtros",
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'lg'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
                <Typography variant="h6" gutterBottom>
                    {idPawnAdjustmentSlip}
                </Typography>

                <MUIDataTable
                    data={editors}
                    columns={columns}
                    options={options}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>Cerrar</Button>
            </DialogActions>
        </Dialog>
    )
}
