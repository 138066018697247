import React, { useEffect, useState } from 'react';

// * IMPORTS HELPERS
import { Global } from '../../../../helpers/Global';
// import { useAlert } from '../../../hooks/useAlert';

// * IMPORT MEDIA CUSTOM
import Factura from '../../../../assets/img/iconos/Factura.png';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// * IMPORT MATERIAL UI
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

// * IMPORTS MATERIAL UI - ICONS
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

// * IMPORTS MATERIAL UI - ALERTS
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';

// * IMPORTS REACT FILEPOND
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const Bill = ({ idUser }) => {
    useEffect(() => {
        console.log(`Bill`);
    }, [])

    const [billFront, setBillFront] = useState([]);
    const [billReverse, setBillReverse] = useState([]);

    // Buttons
    const [disabledButton, setDisabledButton] = useState(false);
    // Alerts Message
    const [messages, setMessages] = useState({
        "validate_bill": "",
        "save_bill": ""
    });
    const [openAlert, setOpenAlert] = useState(true);
    // Progress
    const [progress, setProgress] = useState(false);

    // *  FUNCTIONS
    const uploadDocument = async (event, document) => {

        event.preventDefault();

        // Activar progress
        setProgress(true);

        // Reiniciar mensajes
        setMessages({
            "validate_bill": "",
            "save_bill": ""
        });

        let data = new FormData();
        data.append('idUser', idUser);

        // Conseguir el token de usuario
        const token = localStorage.getItem("token");

        try {

            // Validar campos de archivos
            if (billFront.length === 0 || billReverse.length === 0) {
                // showStatusMessage(`Documentos incompletos.`, 'warning');
                alert('Documentos incompletos.');
                return false;
            }

            if (billFront[0].file.type !== 'image/png' && billFront[0].file.type !== 'image/jpeg' && billFront[0].file.type !== 'image/jpg') {
                // showStatusMessage(`El frente de la factura no es del tipo: png, jpeg, jpg.`, 'warning');
                alert('El frente de la factura no es del tipo: png, jpeg, jpg.');
                return false;
            }

            if (billReverse[0].file.type !== 'image/png' && billReverse[0].file.type !== 'image/jpeg' && billReverse[0].file.type !== 'image/jpg') {
                // showStatusMessage(`El reverso de la factura no es del tipo: png, jpeg, jpg.`, 'warning');
                alert('El reverso de la factura no es del tipo: png, jpeg, jpg.');
                return false;
            }

            // save circulation card
            data.append('file0', billFront[0].file, billFront[0].file.name);
            data.append('file1', billReverse[0].file, billReverse[0].file.name);
            data.append('validationBill', false);

            // Desactivar boton
            setDisabledButton(true);

            // Guardar identificacion
            let requestSaveBill = await fetch(`${Global.url_api}api/upload-bill`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer  ${token}`
                },
                body: data,
                redirect: 'follow'
            });

            let responseSaveBill = await requestSaveBill.json();

            console.log('@SAVE-Bill', responseSaveBill);

            if (responseSaveBill.code === '201') {
                setMessages(prev => ({ ...prev, ...{ save_bill: `Factura de Auto guardada correctamente.` } }));

            } else {
                setMessages(prev => ({ ...prev, ...{ save_bill: `Error al guardar Factura de Auto.` } }));
            }

            // Reiniciar estados
            setBillFront([]);
            setBillReverse([]);

        } catch (error) {
            console.log(error);
            // showStatusMessage(`Error Server ${error}`, 'error');
            alert(`Error Server ${error}`);
        }

        // Mostrar mensajes
        setOpenAlert(true);
        console.log('@MESSAGES', messages);

        // Activar boton
        setDisabledButton(false);

        // Desactivar progress
        setProgress(false);
    }

    // * CUSTOM BUTTON
    const CustomButton = styled(Button)(() => ({
        color: 'white',
        backgroundColor: '#3340E3',
        '&:hover': {
            backgroundColor: '#4d56cd',
        },
    }));

    return (
        <>
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            component="img"
                            sx={{
                                maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block', float: "left"
                            }}
                            alt='icono-spingo-factura'
                            src={Factura}
                        />
                        <Typography sx={{ float: "left", ml: "15px" }} variant="h6" gutterBottom>
                            Subir Factura del auto
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        {progress &&
                            <Box mb={2} sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        }

                        {messages.save_bill !== "" &&
                            <Collapse in={openAlert}>
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="info"
                                            size="small"
                                            onClick={() => {
                                                setOpenAlert(false);
                                                setMessages({
                                                    "validate_identification": "",
                                                    "save_identification": "",
                                                    "validate_proofaddress": "",
                                                    "save_proofaddress": "",
                                                    "validate_circulationcard": "",
                                                    "save_circulationcard": "",
                                                    "validate_bill": "",
                                                    "save_bill": ""
                                                });
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    variant="outlined"
                                    severity="info"
                                    sx={{ mb: 2 }}
                                >
                                    {messages.save_bill}
                                </Alert>
                            </Collapse>
                        }
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="body1" gutterBottom>
                            Frente de la factura del auto *
                        </Typography>

                        <FilePond
                            id="billFront"
                            files={billFront}
                            onupdatefiles={setBillFront}
                            labelIdle='Arrastra y suelta, o <span class="filepond--label-action">abrir archivos</span> <br> Archivo .jpg, .jpeg, .png de máximo 3 mb'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="body1" gutterBottom>
                            Reverso de la factura del auto*
                        </Typography>

                        <FilePond
                            id="billReverse"
                            files={billReverse}
                            onupdatefiles={setBillReverse}
                            labelIdle='Arrastra y suelta, o <span class="filepond--label-action">abrir archivos</span> <br> Archivo .jpg, .jpeg, .png de máximo 3 mb'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3, mb: 2 }} >
                        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                            <CustomButton variant="contained" onClick={event => uploadDocument(event)} endIcon={<CloudUploadOutlinedIcon />} disabled={disabledButton} >Subir</CustomButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
