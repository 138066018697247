import React, { useEffect, useState } from 'react';

import { Global } from '../../../../../helpers/Global';

import { Grid, Container, Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Alert } from '@mui/material';

import { PawnAdjustmentSlip } from '../../pawn-adjustment-slip/PawnAdjustmentSlip';

import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const AssignedAdjustmentSlip = ({ open, onClose, idProcessPawn, idPawnAdjustmentSlip }) => {

    const [pawnAdjustmentSlip, setPawnAdjustmentSlip] = useState(undefined);

    const getPawnAdjustmentSlipClient = async (idPawnAdjustmentSlip) => {
        // Conseguir el token de usuario
        const token = localStorage.getItem("token");

        let request = await fetch(`${Global.url_api}api/auth/get-pawn-adjustment-slip-client`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer  ${token}`,
                "Content-Type": "application/json"
            },
            redirect: 'follow',
            body: JSON.stringify({ id: idPawnAdjustmentSlip })
        });

        let response = await request.json();
        return response;
    }

    useEffect(() => {
        if (idPawnAdjustmentSlip !== null && idPawnAdjustmentSlip !== '') {

            console.log('@idProcessPawn:', idProcessPawn);
            console.log('@idPawnAdjustmentSlip:', idPawnAdjustmentSlip);

            // Consultar Boleta de Ajuste de Empeño
            const fetchData = async () => {
                const result = await getPawnAdjustmentSlipClient(idPawnAdjustmentSlip); // Asegúrate de pasar vin como argumento si es necesario
                setPawnAdjustmentSlip(result.data)
            };

            fetchData();
        }
    }, [idPawnAdjustmentSlip, idProcessPawn])

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', bgcolor: '#3340e3' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Mostrar Boleta de Ajuste de Empeño
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="lg" sx={{ marginTop: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        {(idPawnAdjustmentSlip !== null && idPawnAdjustmentSlip !== '')
                            ? <PawnAdjustmentSlip pawnAdjustmentSlip={pawnAdjustmentSlip} />
                            : <Alert severity="info">Todavía no se ha asignado una boleta de ajuste de empeño a este proceso de empeño.</Alert>
                        }
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    )
}
