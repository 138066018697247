import React from 'react';

import { useParams } from 'react-router-dom';

import { DocumentBill } from './DocumentBill';
import { DocumentCirculationCard } from './DocumentCirculationCard';
// import { DocumentIdOcr } from './DocumentIdOcr';
import { DocumentProofAddress } from './DocumentProofAddress';
import { DocumentIdOcr2 } from './DocumentIdOcr2';
import { DocumentIdentification } from './DocumentIdentification';
import { DocumentId } from './DocumentId';

export const SelectDocument = () => {
    // * Get document type from url
    const {document} = useParams();
 
    if (document === 'id') {
        return (
            <>
                <DocumentId />
            </>
        ) 
    } 
    else if (document === 'id-ocr') {
        return (
            <>
                <DocumentIdOcr2 />
            </>
        ) 
    }
    else if (document === 'identification') {
        return (
            <>
                <DocumentIdentification />
            </>
        ) 
    }  
    else if (document === 'proofaddress') {
        return (
            <>
                <DocumentProofAddress />
            </>
        ) 
    }  
    else if (document === 'circulationcard') {
        return (
            <>
                <DocumentCirculationCard />
            </>
        ) 
    } 
    else if (document === 'bill') {
        return (
            <>
                <DocumentBill />
            </>
        ) 
    } 
}
