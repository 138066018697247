import React, { useRef, useState } from 'react';

// * IMPORTS HELPERS
import { Global } from '../../../helpers/Global';
import { useAlert } from '../../../hooks/useAlert';

// * IMPORT MATERIAL UI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Grid from '@mui/material/Grid';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';

// * IMPORTS MATERIAL UI - ICONS
import CachedIcon from '@mui/icons-material/Cached';

// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';

export const NewUser = () => {

    // * HOOKS
    // const [idUser, setIdUser] = useState(null);
    // Buttons
    const [disabledButton, setDisabledButton] = useState(false);
    // Alerts Message
    const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();
    // Email and telephone random
    const emailRef = useRef();
    const telephoneRef = useRef();

    // *  FUNCTIONS
    const saveUser = async (event) => {

        event.preventDefault();

        let message = {
            "save_user": ""
        };

        let dataForm = event.target;

        let user = {
            "name": dataForm.names.value,
            "middle_name": dataForm.middle_name.value,
            "last_name": dataForm.last_name.value,
            "email": dataForm.mail.value,
            "telephone": dataForm.telephone.value,
            "hubspot_simulator": {
                tentative_loan: null,
                car_year: null,
                car_brand: null,
                car_version: null,
            }
        };

        console.log(user);

        // Validar campos de texto
        let validation = 0;
        Object.entries(user).forEach(([key, value]) => {
            if (value === '') {
                showStatusMessage(`El campo ${key} es obligatorio.`, 'warning');
                validation++;
            }
        });

        if (validation !== 0) return false;

        // Desactivar boton
        setDisabledButton(true);

        let data = new FormData();
        data.append('user', JSON.stringify(user));

        // Conseguir el token de usuario
        const token = localStorage.getItem("token");

        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer  ${token}`
            },
            body: data,
            redirect: 'follow'
        };

        try {
            let request = await fetch(`${Global.url_api}api/auth/add-user-backoffice`, requestOptions);
            let response = await request.json();

            console.log('@ADD-USER', response);

            if (response.code === '201') {

                message.save_user = "Prospecto agregado correctamente.";

                // setIdUser(response.data.idUser);

                showStatusMessage(`${message.save_user}`, 'success');

            }
            else {
                showStatusMessage(`Este email ya esta en uso - error al solicitar recurso - code ${response.code}.`, 'warning');
            }

        } catch (error) {
            console.log(error);
            showStatusMessage(`Error Server ${error}`, 'error');
        }

        // Activar boton
        setDisabledButton(false);
    }

    const emailNumberRandom = () => {
        emailRef.current.value = `random${new Date().valueOf()}@mail.com`;
        telephoneRef.current.value = '+52 55 9978 3615'
    }

    // * CUSTOM BUTTON
    const CustomButton = styled(Button)(() => ({
        color: 'white',
        backgroundColor: '#3340E3',
        '&:hover': {
            backgroundColor: '#4d56cd',
        },
    }));

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

            <Grid container component="form" onSubmit={saveUser} spacing={2} autoComplete="off">

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" gutterBottom>
                        Información general
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="names"
                        label="Nombre"
                        fullWidth
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                        margin="dense"
                        id="middle_name"
                        label="Apellido Paterno"
                        fullWidth
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                        margin="dense"
                        id="last_name"
                        label="Apellido Materno"
                        fullWidth
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                        margin="dense"
                        id="mail"
                        label="Correo Electrónico"
                        type="email"
                        fullWidth
                        variant="standard"
                        inputRef={emailRef}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                        margin="dense"
                        id="telephone"
                        label="Teléfono"
                        fullWidth
                        variant="standard"
                        inputRef={telephoneRef}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3, mb: 2 }} >
                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>

                        <CustomButton variant="contained" endIcon={<CachedIcon />} onClick={emailNumberRandom} >Email y teléfono aleatorio</CustomButton>
                        <CustomButton type="submit" variant="contained" endIcon={<CachedIcon />} disabled={disabledButton} >Agregar</CustomButton>
                    </Stack>
                </Grid>
            </Grid>

            {/* <SaveUpdateDocuments idUser={idUser} /> */}
        </div>
    )
}