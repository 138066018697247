import React, { useEffect, useState } from 'react';

// * IMPORTS HELPERS
import { Global } from '../../../helpers/Global';
import { useAlert } from '../../../hooks/useAlert';


// * IMPORT MEDIA CUSTOM
import INE from '../../../assets/img/iconos/INE.png';
import Domicilio from '../../../assets/img/iconos/Domicilio.png';
// import Tarjeta from '../../../assets/img/iconos/Tarjeta.png';
// import Factura from '../../../assets/img/iconos/Factura.png';

// * IMPORT MATERIAL UI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';

// * IMPORTS MATERIAL UI - ICONS
import CachedIcon from '@mui/icons-material/Cached';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

// * IMPORTS MATERIAL UI - CARDS
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';

export const Information = (props) => {

    const { dataClient, dataClientIdentification, dataClientProofAddress } = props;

    useEffect(() => {
        console.log('@ID--', dataClientIdentification);
        console.log('@PA--', dataClientProofAddress);
    }, [dataClientIdentification, dataClientProofAddress])

    // * HOOKS
    const [nss, setNss] = useState('');
    const [contentNss, setContentNss] = useState(false);
    const [imss, setImss] = useState({});
    const [contentImss, setContentImss] = useState(false);
    const [messageImss, setMessageImss] = useState('');

    // Hook para delegar si se puede consultar el NSS e IMSS en dependencia que se tengan los datos de la INE
    useEffect(() => {
        if (dataClientIdentification && (dataClientIdentification !== undefined) && (dataClientIdentification.estatus !== 'ERROR')) {
            console.log("@INFORMACION_IDENTIFICACION", dataClientIdentification);
            // * Mostrar boton para consultar NSS
            setContentNss(true);
        } else {
            console.log("NO DATA IDENTIFICATION");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getIMSS = async () => {

        if (nss !== '') {
            showStatusMessage(`Ya se ha consultado el número de seguro social.`, 'warning');
            return false;
        }

        // Conseguir el token de usuario
        const token = localStorage.getItem("token");

        let data = new FormData();
        data.append('idUser', dataClient.id);
        data.append('fechaNacimiento', dataClientIdentification.fechaNacimiento);
        data.append('curp', dataClientIdentification.curp);

        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer  ${token}`
            },
            body: data,
            redirect: 'follow'
        };

        try {
            let requestNss = await fetch(`${Global.url_api}api/imss/get-nss`, requestOptions);
            let responseNss = await requestNss.json();

            console.log('@NSS', responseNss);

            if (responseNss.code === '200' && responseNss.status === 'success') {

                setNss(responseNss.data.nss);

                // Ya que tenemos el NSS ahora podemos consultar el IMSS
                let requestImss = await fetch(`${Global.url_api}api/imss/get-imss`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer  ${token}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ nss: responseNss.data.nss, idUser: dataClient.id, curp: dataClientIdentification.curp }),
                    redirect: 'follow'
                });

                let { status, data } = await requestImss.json();

                console.log('@IMSS', data);

                switch (status) {
                    case 'success':
                        setContentImss(true);
                        setImss(data);
                        setMessageImss('A continuación te mostramos el historial laboral del usuario: ');
                        break;
                    case 'pending':
                        setImss(data);
                        setMessageImss('Se ha solicitado el historial laboral del usuario, regrese en 15 minutos para poder visualizarlo.');
                        break;
                    case 'info':
                        setImss(data);
                        setMessageImss(data.message.mensaje);
                        break;
                    case 'error':
                        setMessageImss('Error al solicitar historial laboral IMSS, el servicio de consulta no esta disponible.');
                        break;
                    default:
                        break;
                }

            } else if (responseNss.code === '200' && responseNss.status === 'info' && responseNss.data.hasOwnProperty('message')) {
                setNss(responseNss.data.message);
            } else if (responseNss.code === '200' && responseNss.status === 'pending') {
                setNss('Se ha solicitado el NSS del usuario, regrese en 15 minutos para visualizar la información.');
            } else {
                setNss('Error al solicitar NSS, el servicio de consulta no esta disponible.');
            }
        } catch (error) {
            console.log(error);
            showStatusMessage(`Error Server ${error}`, 'error');
        }
    }

    const editDataClient = async (event) => {

        event.preventDefault();

        let dataForm = event.target;

        let raw = JSON.stringify({
            "idUser": dataClient.id,
            "name": dataForm.name.value,
            "middle_name": dataForm.middle_name.value,
            "last_name": dataForm.last_name.value,
            "email": dataForm.email.value,
            "telephone": dataForm.telephone.value,
        });

        let requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: raw,
            redirect: 'follow'
        };

        let request = await fetch(`${Global.url_api}api/updata-information-user`, requestOptions);
        let response = await request.json();

        console.log(response);

        showStatusMessage('Actualización Exitosa', 'success');
    }

    // * CARDS JOBS
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    // Alerts Message
    const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

    // * CUSTOM BUTTON
    const CustomButton = styled(Button)(() => ({
        color: 'white',
        backgroundColor: '#3340E3',
        '&:hover': {
            backgroundColor: '#4d56cd',
        },
    }));

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

            {/* IMPRIMIR DOCUMENTO */}
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                <Tooltip title="Descargar documentacion en PDF" placement="left-start" >
                    <IconButton onClick={() => { window.print(); }} color="error" size="400px" aria-label="download-documents">
                        <PictureAsPdfOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Grid>


            <Grid container component="form" onSubmit={editDataClient} spacing={2} autoComplete="off">

                {/* MENSAJES INFORMATIVOS */}
                {/* <Alert variant="outlined" severity="warning" sx={{ width: '100%', marginTop: '15px', marginBottom: '15px' }} >El servicio para consulta y validación de NSS e IMSS no está disponible por el momento.</Alert> */}

                {/* INICIO INFORMACION GENERAL DEL USUARIO */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        component="img"
                        sx={{
                            maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 2, display: 'block', float: "left"
                        }}
                        alt='icono-spingo-identificacion'
                        src={INE}
                    />
                    <Typography variant="h6" gutterBottom>
                        Información general
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nombre"
                        fullWidth
                        variant="standard"
                        autoComplete="true"
                        defaultValue={dataClient.name}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                        margin="dense"
                        id="middle_name"
                        label="Apellido Paterno"
                        fullWidth
                        variant="standard"
                        defaultValue={dataClient.middle_name}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                        margin="dense"
                        id="last_name"
                        label="Apellido Materno"
                        fullWidth
                        variant="standard"
                        defaultValue={dataClient.last_name}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                        margin="dense"
                        id="email"
                        label="Correo Electrónico"
                        type="email"
                        fullWidth
                        variant="standard"
                        autoComplete="true"
                        defaultValue={dataClient.email}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                        margin="dense"
                        id="telephone"
                        label="Teléfono"
                        fullWidth
                        variant="standard"
                        defaultValue={dataClient.celular}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3, mb: 2 }} >
                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                        <CustomButton type="submit" variant="contained" endIcon={<CachedIcon />} >Actualizar</CustomButton>
                    </Stack>
                </Grid>
                {/* FIN INFORMACION GENERAL DEL USUARIO */}


                {/* INICIO INFORMACION DE LA IDENTIFICACION */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3 }}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            component="img"
                            sx={{
                                maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 2, display: 'block', float: "left"
                            }}
                            alt='icono-spingo-identificacion'
                            src={INE}
                        />
                        <Typography variant="h6" gutterBottom>
                            Información de la identificación
                        </Typography>
                    </Grid>
                    {/* Si ya valido su Identificación y se guardo la informacion, la mostramos */}
                    {
                        (!dataClientIdentification) &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Su Identificación aún no ha sido cargada y verificada.
                            </Typography>
                        </Grid>
                    }

                    {
                        dataClientIdentification && (dataClientIdentification.estatus !== 'ERROR') ?
                            (
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="Nombre Completo"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={`${dataClientIdentification.nombres} ${dataClientIdentification.primerApellido} ${dataClientIdentification.segundoApellido}`}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="Fecha de Nacimiento"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={dataClientIdentification.fechaNacimiento}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="CURP"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={dataClientIdentification.curp}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="Genero"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={dataClientIdentification.sexo}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="Ciudad"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={dataClientIdentification.ciudad}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="Colonia"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={dataClientIdentification.colonia}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="Calle"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={dataClientIdentification.calle}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="Identificador ciudadano"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={dataClientIdentification.identificadorCiudadano}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="O-C-R"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={dataClientIdentification.ocr}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="Registro"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={dataClientIdentification.registro}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="Emision"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={dataClientIdentification.emision}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextField
                                                margin="dense"
                                                label="Vigencia"
                                                fullWidth
                                                variant="standard"
                                                defaultValue={dataClientIdentification.vigencia}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                            : (
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3 }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Aún no se ha validado la identificación.
                                    </Typography>
                                </Grid>
                            )
                    }

                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Validaciones de la identificación
                            </Typography>
                        </Grid>
                        {
                            (dataClientIdentification && (dataClientIdentification !== undefined) && (dataClientIdentification.validacionMRZ !== undefined) && (dataClientIdentification.estatus !== 'ERROR')) ?
                                (
                                    <>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    margin="dense"
                                                    label="Validacion Emision"
                                                    id="data1"
                                                    fullWidth
                                                    variant="standard"
                                                    defaultValue={dataClientIdentification.validacionMRZ.emision}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    margin="dense"
                                                    label="Validacion Fecha de Nacimiento"
                                                    id="data2"
                                                    fullWidth
                                                    variant="standard"
                                                    defaultValue={dataClientIdentification.validacionMRZ.fechaNacimiento}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    margin="dense"
                                                    label="Validacion Nombre"
                                                    id="data3"
                                                    fullWidth
                                                    variant="standard"
                                                    defaultValue={dataClientIdentification.validacionMRZ.nombre}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    margin="dense"
                                                    label="Validacion Sexo"
                                                    id="data4"
                                                    fullWidth
                                                    variant="standard"
                                                    defaultValue={dataClientIdentification.validacionMRZ.sexo}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    margin="dense"
                                                    label="Validacion Vigencia"
                                                    id="data5"
                                                    fullWidth
                                                    variant="standard"
                                                    defaultValue={dataClientIdentification.validacionMRZ.vigencia}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                                : (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3 }}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Validaciones de identificación no disponibles.
                                        </Typography>
                                    </Grid>
                                )
                        }
                    </Grid>
                </Grid>
                {/* FIN INFORMACION DE LA IDENTIFICACION */}


                {/* INICIO INFORMACION COMPROBANE DE DOMICILIO */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3 }}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            component="img"
                            sx={{
                                maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 2, display: 'block', float: "left"
                            }}
                            alt='icono-spingo-domicilio'
                            src={Domicilio}
                        />
                        <Typography variant="h6" gutterBottom>
                            Información de Comprobante de Domicilio
                        </Typography>
                        {
                            ((dataClientProofAddress !== undefined) && (dataClientProofAddress) !== null) ?
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <TextField
                                            margin="dense"
                                            label="Nombre"
                                            id="proof_address_data1"
                                            fullWidth
                                            variant="standard"
                                            defaultValue={dataClientProofAddress.nombre}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <TextField
                                            margin="dense"
                                            label="Calle"
                                            id="proof_address_data2"
                                            fullWidth
                                            variant="standard"
                                            defaultValue={dataClientProofAddress.calle}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <TextField
                                            margin="dense"
                                            label="Colonia"
                                            id="proof_address_data3"
                                            fullWidth
                                            variant="standard"
                                            defaultValue={dataClientProofAddress.colonia}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <TextField
                                            margin="dense"
                                            label="Código postal"
                                            id="proof_address_data4"
                                            fullWidth
                                            variant="standard"
                                            defaultValue={dataClientProofAddress.cp}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <TextField
                                            margin="dense"
                                            label="Ciudad"
                                            id="proof_address_data5"
                                            fullWidth
                                            variant="standard"
                                            defaultValue={dataClientProofAddress.ciudad}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <TextField
                                            margin="dense"
                                            label="Tipo"
                                            id="proof_address_data6"
                                            fullWidth
                                            variant="standard"
                                            defaultValue={dataClientProofAddress.tipo}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                <Typography variant="body2" gutterBottom>
                                    Sin información o pendiente de validar.
                                </Typography>
                        }
                    </Grid>
                </Grid>
                {/* FIN INFORMACION COMPROBANE DE DOMICILIO */}


                {/* INICIO INFORMACION COINCIDENCIAS ENTRE COMPROBANTE DE DOMICILIO */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Coincidencias de documentos
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mt: 3 }}>
                        {
                            ((dataClientIdentification !== undefined) && (dataClientProofAddress !== undefined) && (dataClientIdentification !== null) && (dataClientProofAddress !== null)) ?

                                `${dataClientIdentification.nombres} ${dataClientIdentification.primerApellido} ${dataClientIdentification.segundoApellido}` === dataClientProofAddress.nombre ?
                                    <Alert variant="outlined" severity="success">El nombre de la identificación es similar al del comprobante de domicilio.</Alert>
                                    :
                                    <Alert variant="outlined" severity="warning">El nombre de la identificación no es similar al del comprobante de domicilio.</Alert>
                                :
                                <Alert variant="outlined" severity="info">Sin información.</Alert>
                        }
                    </Grid>
                </Grid>
                {/* FIN INFORMACION COINCIDENCIAS ENTRE COMPROBANTE DE DOMICILIO */}


                {/* INICIO IMSS */}
                {
                    contentNss &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Instituto Mexicano del Seguro Social (IMSS)
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    margin="dense"
                                    id="nss"
                                    label="Número de Seguro Social"
                                    fullWidth
                                    variant="outlined"
                                    value={nss}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {messageImss}
                                </Typography>

                                <Grid container spacing={2} sx={{ bgcolor: '#e7ebf0' }} mt={3}>

                                    {contentImss && imss.hasOwnProperty('job_history_imss') &&
                                        <Grid container spacing={2} sx={{ padding: 3 }}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextField
                                                    sx={{ width: '50%' }}
                                                    id="imss_nombre"
                                                    label="Nombre"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <GroupAddOutlinedIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                    value={imss.job_history_imss.nombre}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                                <TextField
                                                    id="imss_semanas_cotizadas"
                                                    label="Semanas Cotizadas"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <GroupAddOutlinedIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                    value={imss.job_history_imss.semanasCotizadas.semanasCotizadas}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                                <TextField
                                                    id="imss_semanas_descontadas"
                                                    label="Semanas Descontadas"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <GroupRemoveOutlinedIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                    value={imss.job_history_imss.semanasCotizadas.semanasDescontadas}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                                <TextField
                                                    id="imss_semanas_reintegradas"
                                                    label="Semanas Reintegradas"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <GroupOutlinedIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                    value={imss.job_history_imss.semanasCotizadas.semanasReintegradas}
                                                />
                                            </Grid>
                                        </Grid>
                                    }

                                    {contentImss && imss.hasOwnProperty('job_history_imss') &&
                                        imss.job_history_imss.historialLaboral.map((job, index) => {

                                            return (
                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={index}>
                                                    <Card sx={{ maxWidth: 445, marginBottom: 3 }}>
                                                        <CardHeader
                                                            avatar={
                                                                <IconButton aria-label="job">
                                                                    <WorkOutlineOutlinedIcon />
                                                                </IconButton>
                                                            }
                                                            title={job.nombrePatron}
                                                            subheader={`Salario Base Cotizacion: ${job.salarioBaseCotizacion}`}
                                                        />
                                                        <CardActions disableSpacing>
                                                            <ExpandMore
                                                                expand={expanded}
                                                                onClick={handleExpandClick}
                                                                aria-expanded={expanded}
                                                                aria-label="show more"
                                                            >
                                                                <ExpandMoreIcon />
                                                            </ExpandMore>
                                                        </CardActions>
                                                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                                                            <CardContent>
                                                                <Typography variant="body2" mt={1} gutterBottom>
                                                                    <strong>Entidad Federativa:</strong> &nbsp;{job.entidadFederativa}
                                                                </Typography>
                                                                <Typography variant="body2" mt={1} gutterBottom>
                                                                    <strong>Fecha Alta:</strong> &nbsp;{job.fechaAlta}
                                                                </Typography>
                                                                <Typography variant="body2" mt={1} gutterBottom>
                                                                    <strong>Fecha Baja:</strong> &nbsp;{job.fechaBaja}
                                                                </Typography>
                                                                <Typography variant="body2" mt={1} gutterBottom>
                                                                    <strong>Nombre Patron:</strong> &nbsp;{job.nombrePatron}
                                                                </Typography>
                                                                <Typography variant="body2" mt={1} gutterBottom>
                                                                    <strong>Registro Patronal:</strong> &nbsp;{job.registroPatronal}
                                                                </Typography>
                                                                <Typography variant="body2" mt={1} gutterBottom>
                                                                    <strong>Salario Base Cotizacion:</strong> &nbsp;{job.salarioBaseCotizacion}
                                                                </Typography>
                                                            </CardContent>
                                                        </Collapse>
                                                    </Card>
                                                </Grid>
                                            );
                                        })

                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3, mb: 2 }} >
                                <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                                    <CustomButton onClick={getIMSS} variant="contained" endIcon={<LocalHospitalIcon />} >Consultar IMSS y NSS</CustomButton>
                                </Stack>
                            </Grid>

                        </Grid>
                    </Grid>
                }
                {/* FIN IMSS */}

            </Grid>
        </div>
    )
}
// if (dataClientIdentification && (dataClientIdentification !== undefined)) {
//     console.log(dataClientIdentification);
//     console.log('datos xD');
// }